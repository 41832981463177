import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../../globalContex";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import ContractsDrawer from "./ContractsDrawer/index.jsx";
import "./subdrawer.scss";



const BondContracts = () => {
    const { bankerEmail, collapse, setShowSubDraw, contractsData,
        setContractsData
    } = useContext(GlobalContex);
    const [allContracts, setAllContracts] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [step, setStep] = useState("")

    useEffect(() => {
        setDataLoading(true);
        axios
            .get(
                `https://comms.globalxchange.io/coin/iced/contract/get?banker_email=${bankerEmail}`
            )
            .then((res) => {
                setDataLoading(false);
                if (res.data.status) {
                    setAllContracts(res.data.icedContracts);
                    console.log("contractss", res.data);
                }
            });
    }, [bankerEmail]);

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className="listDataGrid"
                        style={{
                            width: "100%",
                            gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr",
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={30}
                                height={30}
                                style={{
                                    borderRadius: "50%",
                                    width: "30px",
                                }}
                            />
                            <div style={{ paddingLeft: "15px" }}>
                                <Skeleton width={100} />
                                <Skeleton width={120} />
                            </div>
                        </div>
                        <div className="alignleft">
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div className="alignleft">
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div className="alignleft">
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div className="alignleft">
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div className="alignleft">
                            <Skeleton width={120} />
                        </div>

                    </div>
                );
            });
    };
    return (
        <div>
            <div style={{ width: "100%" }}>
                <div
                    className="listGrid"
                    style={{ gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr" }}
                >
                    <div className="alignleft">User</div>
                    <div className="alignleft">Investment</div>
                    <div className="alignleft">Days</div>
                    <div className="alignleft">Interest Rate</div>
                    <div className="alignleft">Earnings</div>
                    <div className="alignleft">VOC</div>
                </div>
            </div>
            <div className="thefullscroll">
                {!dataLoading ? (
                    allContracts.length > 0 ? (
                        allContracts.map((theitem) => {
                            return (
                                theitem.contracts.map((item) => {
                                    return (
                                        <div className="listDataGrid" style={{
                                            gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr",
                                        }} onClick={() => (setShowSubDraw(true), setContractsData(item))}>
                                            <div style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <img
                                                    src={item.userData.profile_img ? item.userData.profile_img : defaultImg}
                                                    alt=""
                                                    style={{
                                                        borderRadius: "50%",
                                                        width: "30px",
                                                        height: "30px"
                                                    }}
                                                />
                                                <div style={{ paddingLeft: "15px" }}>
                                                    <div className="title">{item.userData.name}</div>
                                                    <div className="subtitle">{item.userData.email}</div>
                                                </div>
                                            </div>
                                            <div className="alignleft">
                                                <div>
                                                    <div className="title">{item?.investment}</div>
                                                    <div className="subtitle">{item?.asset}</div>
                                                </div>
                                            </div>
                                            <div className="alignleft">
                                                <div>
                                                    <div className="title">{item?.remaining_stats.days} Left</div>
                                                    <div className="subtitle">{item?.completed_stats.days} Completed</div>
                                                </div>
                                            </div>
                                            <div className="alignleft">
                                                <div>
                                                    <div className="title">{item?.interest_rate.toFixed(4)} %</div>
                                                    <div className="subtitle">---</div>
                                                </div>
                                            </div>
                                            <div className="alignleft">
                                                <div>
                                                    <div className="title">{item?.remaining_stats.earnings.toFixed(4)} Left</div>
                                                    <div className="subtitle">{item?.completed_stats.earnings.toFixed(4)} Completed</div>
                                                </div>
                                            </div>
                                            <div className="alignleft">
                                                <div>
                                                    <div className="title">{item?.voc.toFixed(4)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        })
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60vh",
                                fontSize: "20px",
                                fontWeight: "700"
                            }}
                        >
                            No Records Found
                        </div>
                    )
                ) : (
                    loadingSection("listDataGird", "1.5fr 1fr 1fr 1fr 1fr 1fr")
                )}
            </div>
            <ContractsDrawer step={step} setStep={setStep} />
        </div>
    )
}

export default BondContracts