import axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";
import "./filterDraw.scss";

function UserList({
  addingUsers,
  setAddingUsers,
  listId,
  appCode,
  remove,
  subStep,
}) {
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [userDataRemove, setUserDataRemove] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  useEffect(() => {
    console.log(appCode, "jhwdvwed");
    setUsersLoading(true);
    if (appCode !== null && appCode !== undefined) {
      console.log("inside not added");
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/users/not/added?list_id=${listId}&app_code=${appCode}`
        )
        .then(({ data }) => {
          setUserData(data.users);
          setUsersLoading(false);
        });
    } else {
      console.log("inside added");
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/get?list_id=${listId}`
        )
        .then(({ data }) => {
          if (data.status) {
            setUserData(data.data[0].usersData);
            setUsersLoading(false);
          }
        });
    }
  }, []);

  // useEffect(() => {
  //   setUsersLoading(true);
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/get?list_id=${listId}`
  //     )
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setUserData(data.data[0].usersData);
  //         setUsersLoading(false);
  //       }
  //     });
  // }, []);

  const conditionalUserData = () => {
    if (remove) {
      return userDataRemove
        .filter(
          (user) =>
            user?.name?.toLowerCase().includes(search.toLowerCase()) ||
            user?.email?.toLowerCase().includes(search.toLowerCase())
        )
        .map((user) => (
          <div
            className="user"
            key={user.username}
            onClick={() => {
              setAddingUsers((addingUsers) =>
                addingUsers?.map((usr) => usr.username).includes(user?.username)
                  ? addingUsers.filter(
                      (usr) => usr?.username !== user?.username
                    )
                  : [...addingUsers, user]
              );
            }}
          >
            <img
              className="dp"
              style={{ width: "34px", height: "34px" }}
              src={user.profile_img ? user.profile_img : defaultImg}
              alt=""
            />
            <div className="userDetail">
              <div
                className="name"
                style={{ fontSize: "16px", fontWeight: 700 }}
              >
                {user.name}
              </div>
              <div
                className="email"
                style={{ fontSize: "12px", fontWeight: 400 }}
              >
                {user.email}
              </div>
            </div>
            <input
              readOnly
              style={{
                width: "22px",
                height: "22px",
                marginLeft: "auto",
                marginTop: "0px",
                background: addingUsers
                  ?.map((usr) => usr.username)
                  .includes(user?.username)
                  ? "red"
                  : "",
              }}
              type="checkbox"
              checked={addingUsers
                ?.map((usr) => usr.username)
                .includes(user?.username)}
            ></input>
          </div>
        ));
    } else {
      return userData
        .filter(
          (user) =>
            user?.name?.toLowerCase().includes(search.toLowerCase()) ||
            user?.email?.toLowerCase().includes(search.toLowerCase())
        )
        .map((user) => (
          <div
            className="user"
            key={user.username}
            onClick={() => {
              setAddingUsers((addingUsers) =>
                addingUsers?.map((usr) => usr.username).includes(user?.username)
                  ? addingUsers.filter(
                      (usr) => usr?.username !== user?.username
                    )
                  : [...addingUsers, user]
              );
            }}
          >
            <img
              className="dp"
              style={{ width: "34px", height: "34px" }}
              src={user.profile_img ? user.profile_img : defaultImg}
              alt=""
            />
            <div className="userDetail">
              <div
                className="name"
                style={{ fontSize: "16px", fontWeight: 700 }}
              >
                {user.name}
              </div>
              <div
                className="email"
                style={{ fontSize: "12px", fontWeight: 400 }}
              >
                {user.email}
              </div>
            </div>
            <input
              readOnly
              style={{
                width: "22px",
                height: "22px",
                marginLeft: "auto",
                marginTop: "0px",
                background: addingUsers
                  ?.map((usr) => usr.username)
                  .includes(user?.username)
                  ? "red"
                  : "",
              }}
              type="checkbox"
              checked={addingUsers
                ?.map((usr) => usr.username)
                .includes(user?.username)}
            ></input>
          </div>
        ));
    }
  };

  return (
    <>
      <div className="filterTitle">
        Select Users To {remove ? "Remove" : "Add"}
      </div>

      <div className="filterSearch">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Users By Email....|"
        />
      </div>
      <div className="filterCoinCard">
        {usersLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="cardBody" key={i}>
                  <Skeleton className="dp coinImage" circle />
                  {/* <img className="coinImage" src={item.icon} alt="" /> */}
                  <div className="coinDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : userData
              .filter(
                (user) =>
                  user?.name?.toLowerCase().includes(search.toLowerCase()) ||
                  user?.email?.toLowerCase().includes(search.toLowerCase())
              )
              .map((user) => (
                <div
                  className="cardBody"
                  key={user.username}
                  onClick={() => {
                    setAddingUsers((addingUsers) =>
                      addingUsers
                        ?.map((usr) => usr.username)
                        .includes(user?.username)
                        ? addingUsers.filter(
                            (usr) => usr?.username !== user?.username
                          )
                        : [...addingUsers, user]
                    );
                  }}
                >
                  <img
                    className="coinImage"
                    style={{ width: "34px", height: "34px" }}
                    src={user.profile_img ? user.profile_img : defaultImg}
                    alt=""
                  />
                  <div className="coinDetail">
                    <div
                      className="name"
                      style={{ fontSize: "16px", fontWeight: 700 }}
                    >
                      {user.name}
                    </div>
                    <div
                      className="email"
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      {user.email}
                    </div>
                  </div>
                  <input
                    readOnly
                    style={{
                      width: "22px",
                      height: "22px",
                      marginLeft: "auto",
                      marginTop: "0px",
                      background: addingUsers
                        ?.map((usr) => usr.username)
                        .includes(user?.username)
                        ? "red"
                        : "",
                    }}
                    type="checkbox"
                    checked={addingUsers
                      ?.map((usr) => usr.username)
                      .includes(user?.username)}
                  ></input>
                </div>
              ))}

        <div className="space"></div>
      </div>
    </>
  );
}

export default UserList;
