import React, { useEffect } from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";
import TabsLayout from "../../globalComponents/TabsLayout";
import axios from "axios";

import crm from "../../static/images/sidebarIcons/crm.svg";
import dash from "../../static/images/sidebarIcons/dash.svg";
import affiliates from "../../static/images/sidebarIcons/affiliates.svg";
import vaults from "../../static/images/sidebarIcons/vaults.svg";
import terminal from "../../static/images/sidebarIcons/terminal.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";
import loans from "../../static/images/sidebarIcons/socially.svg";
import Lock from "../../static/images/icons/lock.svg";
import defaultImg from "../../static/images/icons/app_placeholder.png";

import offerings from "../../static/images/sidebarIcons/offerings.svg";
import dividends from "../../static/images/sidebarIcons/dividends.svg";
import prospectus from "../../static/images/sidebarIcons/prospectus.svg";
import revenue from "../../static/images/sidebarIcons/revenue.svg";

import AdminModal from "../../globalComponents/AdminModal";
import VaultPage from "../../globalComponents/VaultPage";
import CapitalizedDashboard from "./CapitalizedDashboard";
import CapitalizedCRM from "./CapitalizedCRM";
import Dividends from "./CapitalizedDividends";
import Affiliate from "./CapitalizedAffiliate";
import MobileMenu from "../../globalComponents/MobileMenu";

const Capitalized = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    localMenu,
    globalMenu,
    showMobileMenu,
    selectedShareToken,
    setSelectedShareToken
  } = useContext(GlobalContex);

  const capitalizedMenu = [
    // {
    //   menuName: "Dashboard",
    //   menuIcon: dash,
    //   enabled: false,
    // },
    // {
    //   menuName: "Offerings",
    //   menuIcon: offerings,
    //   enabled: false,
    // },
    {
      menuName: "Dividends",
      menuIcon: dividends,
      enabled: true,
    },
    // {
    //   menuName: "Prospectus",
    //   menuIcon: prospectus,
    //   enabled: false,
    // },
    {
      menuName: "CRM",
      menuIcon: crm,
      enabled: true,
    },
    {
      menuName: "Affilaites",
      menuIcon: affiliates,
      enabled: true,
    },
    // {
    //   menuName: "Revenue",
    //   menuIcon: revenue,
    //   enabled: false,
    // },
    // {
    //   menuName: "Terminal",
    //   menuIcon: terminal,
    //   enabled: false,
    // },
  ];


  useEffect(() => {
    console.log("call1")
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
          "bankerEmailNew"
        )}`
      )
      .then((res) => {
        if (res.data.status) {
          if (!selectedShareToken) {
            setSelectedShareToken(JSON.stringify(res.data.paths[0]))
            localStorage.setItem("selectedShareCoin", JSON.stringify(res.data.paths[0]))
          }else{
            localStorage.getItem("selectedShareCoin")
          }
          // console.log(JSON.parse(localStorage.getItem("selectedShareCoin")).token_profile_data.coinSymbol, "coin-select")
        }
      })
  }, [selectedShareToken])

  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      // case "Dashboard":
      //   return <CapitalizedDashboard />;
      case "Vaults":
        return <VaultPage />;
      case "CRM":
        return <CapitalizedCRM />;
      case "Dividends":
        return <Dividends />;
      case "Affilaites":
        return <Affiliate />
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={capitalizedMenu} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
            {/* <div className="filterRow"></div> */}
            {/* <div style={{ flexGrow: 1 }}>{conditionalPages()}</div> */}
          </div>
        </div>
      </div>

      {showMobileMenu ? (
        <MobileMenu localMenu={capitalizedMenu} />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}

      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default Capitalized;
