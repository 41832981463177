import axios from "axios";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../globalContex";

function WholesaleBondList({
  setWholesaleBond,
  bondAsset,
  bondLength,
  onClose,
}) {
  const { FormatCurrency, FormatNumber, loginData, allCoins, bankerEmail } =
    useContext(GlobalContex);

  const [search, setSearch] = useState("");

  const [wholesaleApps, setWholesaleApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencies, setAllCurrencies] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/data/get?user_email=${bankerEmail}&status=active&days=${bondLength}&coin=${bondAsset}`
      )
      .then(({ data }) => {
        setWholesaleApps(data.bondsListData);
        setAllCurrencies(data.currenciesData);
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <div className="titleOp">Select Connected Wholesale Bond</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Wholesale Bonds....|"
        />
      </div>
      <Scrollbars className="searchList">
        {loading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : wholesaleApps
              .filter(
                (o) =>
                  o?.coinsData[0]?.coin
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
                //   ||
                // wholesaleApps.coinSymbol
                //   ?.toLowerCase()
                //   .includes(search.toLowerCase())
              )
              .map((item) => {
                return (
                  <div
                    onClick={() => {
                      setWholesaleBond(item);
                      onClose();
                    }}
                  >
                    <div className="bond" key={item._id}>
                      <div className="coinDetail">
                        <img
                          className="dp"
                          style={{ width: "22px", height: "22px" }}
                          src={
                            allCoins.find(
                              (o) => o.coinSymbol === item?.coinsData?.[0]?.coin
                            ).coinImage
                          }
                          alt=""
                        />
                        <div
                          className="name"
                          style={{ color: "#050505", fontSize: "16px" }}
                        >
                          {
                            allCoins.find(
                              (o) => o.coinSymbol === item?.coinsData?.[0]?.coin
                            ).coinName
                          }
                        </div>
                      </div>
                      <div className="bondValues">
                        <span
                          className="cost"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatCurrency(
                            item?.coinsData?.[0]?.bondCost,
                            item?.coinsData?.[0]?.coin
                          )}{" "}
                          {item?.coinsData?.[0]?.coin}
                        </span>
                        <span
                          className="intrst"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatNumber(item?.daily_interest_rate, 3)}%/Day
                        </span>
                        <span
                          className="days"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatNumber(item?.days)} Days
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default WholesaleBondList;
