import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";
import arrowDown from "../../../static/images/arrowDown.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAllergies, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
// import CountryList from "./CountryList";
// import AppsList from "./AppsList";
// import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";

import plus from "../../../static/images/globaldrawer/plus.svg";
import closeWhite from "../../../static/images/icons/closeWhite.svg";

// import AppList from "./AppsList";
import LoadingAnimation from "../../LoadingAnimation";
// import PublicationList from "./PublicationList";
import defaultImg from "../../../static/images/icons/defaultImg.svg";
// import AuthorList from "./AuthorList";
// import EnterArticle from "./EnterArticle";
// import CategoryList from "./CategoryList";
// import NavBarList from "./NavBarList";
import plusIcon from "../../../static/images/addNewIcons/plusIcon.svg";
import closeIconRed from "../../../static/images/addNewIcons/closeIconRed.svg";
import blackPlus from "../../../static/images/blackPlus.svg";
import CountryList from "./countryList";
import BrandList from "./brandList";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const NewPortfolio = ({ step, setStep, setMainMenu, loading, setLoading }) => {
    const navigate = useNavigate();

    const {
        loginData,
        bankerEmail,
        refetchAppData,
        setRefetchAppData,
        globalMenuAdd,
        setGlobalMenuAdd,
        tabSelected,
        setTabSelected,
        selectedPublication,
        wideDrawer,
        setWideDrawer,
        selectedApp,
        refetchArticles,
        setSlider,
        setRefetchArticles,
        NumberToText
    } = useContext(GlobalContex);

    //
    const [publication, setPublication] = useState(null);
    const [author, setAuthor] = useState(null);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleExcerpt, setArticleExcerpt] = useState("");
    const [articleBody, setArticleBody] = useState("");

    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [coverPhoto, setCoverPhoto] = useState("");
    const [coverPhotoLoading, setCoverPhotoLoading] = useState("");
    const [selectBtn, setSelectBtn] = useState("");

    const [categories, setCategories] = useState([]);
    const [navBars, setNavBars] = useState([]);
    const [buttonTitle, setButtonTitle] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [altTag, setAltTag] = useState("");
    const [brandName, setBrandName] = useState("")
    const [colorCode, setColorCode] = useState("")
    const [brandDate, setBrandDate] = useState("")

    // Form Variables

    const [company, setCompany] = useState();
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenName, setTokenName] = useState("");
    const [tokenAvailable, setTokenAvailable] = useState(null);

    //Publishers New Publication
    const [publicationName, setPublicationName] = useState("");
    const [app, setApp] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [country, setCountry] = useState(null);
    const [brand, setBrand] = useState("")

    const [visibleSubmit, setVisibleSubmit] = useState(false);

    const [metaTag, setMetaTag] = useState("")
    const [metaArr, setMetaArr] = useState([])

    const [keywords, setKeywords] = useState("")
    const [keywordsArr, setKeywordsArr] = useState([])
    const [customUrl, setCustomUrl] = useState("")
    const [microSteps, setMicroSteps] = useState([])
    const [stepNumber, setStepNumber] = useState(0)
    const [Logo, setLogo] = useState("");
    const [LogoLoading, setLogoLoading] = useState(false);
    const [reqName, setReqName] = useState("")
    const [reqDesc, setReqDesc] = useState("")
    const [reqArray, setReqArray] = useState([])
    const [approachName, setApproachName] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [approachDesc, setApproachDesc] = useState("")
    const [approachArr, setApproachArr] = useState([])
    const [designName, setDesignName] = useState("")
    const [designDesc, setDesignDesc] = useState("")
    const [designLink, setDesignLink] = useState("")
    const [designArr, setDesignArr] = useState([])
    const [testimonialName, setTestimonailName] = useState("")
    const [testimonialPosition, setTestimonialPositon] = useState("")
    const [testimonialQuote, setTestimonailQuote] = useState("")
    const [testimonialArr, setTestimonailArr] = useState([])

    useEffect(() => {
        localStorage.setItem("step", step);
    }, [step]);

    useEffect(() => {
        // console.log("selectedPublication", selectedPublication);
        if (selectedApp.appName === "Publishers") {
            setPublication(selectedPublication);
        }
        // setPublication(selectedPublication);
        if (selectedApp.appName === "Authors") {
            if (!publication?.PublicationDetail[0]?.fxa_app_id) {
                axios
                    .get(
                        `https://publications.apimachine.com/application/publisher/detail/${bankerEmail}`
                    )
                    .then(({ data }) => {

                        setPublication(data?.data[0]?.PublicationDetails[0]);
                        setLoading(false);
                    });
            }
            axios.get(`https://publications.apimachine.com/publisher?email=${bankerEmail}`).then((res) => {
                if (res.data.data.length > 0) {
                    setAuthor(res.data.data[0])
                }
            })
        }
    }, [])

    const handleCheckTokenAvailability = () => {
        if (tokenAvailable === null && tokenSymbol !== "") {
            axios
                .get(
                    `https://comms.globalxchange.io/coin/investment/path/get?token=${tokenSymbol}`
                )
                .then((res) => {
                    if (res.data.status) {
                        setTokenAvailable(false);
                    } else {
                        setTokenAvailable(true);
                    }
                });
        } else {
            setTokenAvailable(null);
            setTokenSymbol("");
        }
    };

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);
        const path_inside_brain = "root/";

        const jwts = await signJwt(fileName, emailDev, secret);
        console.log(jwts, "lkjkswedcf");
        let { data } = await Axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        console.log(data[0].urlName, "data.payload.url")
        setImage(data[0].urlName);
        setLoading(false);
    };

    const authorStep = () => {
        if (selectedApp.appName !== "Authors") {
            setStep("Select Author")
        }
    }

    const removeCategory = (category) => {
        setCategories(categories.filter((item) => item !== category));
    };
    const removeNav = (navBar) => {
        setNavBars(navBars.filter((item) => item !== navBar));
    };

    const addMetaTag = (metaTag) => {
        if (metaTag === "") return
        setMetaArr([...metaArr, metaTag])
        console.log(metaArr, "metaArr")
        setMetaTag("")
    }

    const removeTag = (item) => {
        setMetaArr(metaArr.filter((tag) => tag !== item));
    };

    const addKeyword = (keyword) => {
        if (keyword === "") return
        setKeywordsArr([...keywordsArr, keyword])
        console.log(keywordsArr, "keywordsArr")
        setKeywords("")
    }

    const removeKeyword = (item) => {
        setKeywordsArr(keywordsArr.filter((keyword) => keyword !== item));
    };

    const addMicroStep = () => {
        setMicroSteps([...microSteps, { title: "", description: "" }]);
    }


    const saveData = () => {
        if (!reqName) {
            return toast.warning("Requirement name cannot be empty")
        }
        if (!reqDesc) {
            return toast.warning("requirement description cannot be empty")
        }
        setReqArray([...reqArray, { name: reqName, description: reqDesc }])
        toast.success("Requirement Added")
        setReqName("")
        setReqDesc("")
    }

    const saveApproach = () => {
        if (!approachName) {
            return toast.warning("Approach name cannot be empty")
        }
        if (!subtitle) {
            return toast.warning("Subtitle cannot be empty")
        }
        if (!approachDesc) {
            return toast.warning("Approach description cannot be empty")
        }
        setApproachArr([...approachArr, { name: approachName, subtitle: subtitle, description: approachDesc }])
        toast.success("Approach Added")
        window.scrollTo({
            top: window.scrollY - 100,
            behavior: 'smooth',
        });
        setApproachName("")
        setSubtitle("")
        setApproachDesc("")
    }

    const saveDesign = () => {
        if (!designName) {
            return toast.warning("Design name cannot be empty")
        }
        if (!designLink) {
            return toast.warning("Design Link cannot be empty")
        }
        if (!designDesc) {
            return toast.warning("Design description cannot be empty")
        }
        setDesignArr([...designArr, { name: designName, description: designDesc, link: designLink }])
        toast.success("Approach Added")
        window.scrollTo({
            top: window.scrollY - 100,
            behavior: 'smooth',
        });
        setDesignName("")
        setDesignDesc("")
        setDesignLink("")
    }

    const saveTestimonial = () => {
        if (!testimonialName) {
            return toast.warning("Testimonial name cannot be empty")
        }
        if (!testimonialPosition) {
            return toast.warning("Testimonial Position cannot be empty")
        }
        if (!testimonialQuote) {
            return toast.warning("Testimonial Quote cannot be empty")
        }
        setTestimonailArr([...testimonialArr, { name: testimonialName, position: testimonialPosition, quote: testimonialQuote }])
        toast.success("Testimonial Added")
        window.scrollTo({
            top: window.scrollY - 100,
            behavior: 'smooth',
        });
        setTestimonailName("")
        setTestimonialPositon("")
        setTestimonailQuote("")
    }

    const validate = () => {
        if (
            brandName &&
            brand &&
            country &&
            website &&
            colorCode &&
            brandDate &&
            colouredIcon &&
            Logo &&
            reqArray &&
            approachArr &&
            designArr &&
            testimonialArr
        ) {
            setVisibleSubmit(true);
            AddPortfolio();
        } else {
            toast.warning("All Fields Are Mandatory");
        }
    };

    const AddPortfolio = () => {
        // setLoading(true);
        let body = {
            email: bankerEmail,
            brandName: brandName,
            colourCode: colorCode,
            logo: Logo,
            icon: colouredIcon,
            website: website,
            about: description,
            industry: brand,
            requirements: reqArray,
            approach: approachArr,
            design: designArr,
            date: brandDate,
            testimonial: testimonialArr,
            countryIcon: country?.image,
            countryName: country?.name
        }
        console.log(body, "body")
        axios
            .post(`https://counsel.apimachine.com/api/createPortfolio`, body)
            .then(({ data }) => {
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    toast.success(data.message || "API Error");
                } else setStep("success");
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setRefetchArticles(!refetchArticles);
                setLoading(false);
                setTabSelected("Portfolio");
            });
    };

    const getContent = () => {
        switch (step) {
            case "success":
                setTimeout(() => {
                    // // navigate("/ventures/Brands");
                    // window.location.reload();
                    setSlider(false);
                }, 1000);
                setRefetchAppData(true);
                return (
                    <div className="newConglomerate">
                        <div className="succesView">
                            <div className="labelItm" style={{ textAlign: "center" }}>
                                You Have Successfully Added Portfolio {brandName}.
                            </div>
                        </div>
                    </div>
                );
            //   case "app":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <AppList setApp={setApp} onClose={() => setStep("")} />
            //       </div>
            //     );

            case "country":
                return (
                    <div className="newConglomerate">
                        <CountryList setCountry={setCountry} onClose={() => setStep("")} />
                    </div>
                );

            case "brand":
                return (
                    <div className="newConglomerate">
                        <BrandList setBrand={setBrand} onClose={() => setStep("")} />
                    </div>
                );

            case "Token Expired":
                return (
                    <>
                        <div className="newConglomerate">
                            <div className="succesView">
                                <div className="labelItm" style={{ textAlign: "center" }}>
                                    Token Expired. Login Again.
                                </div>
                            </div>
                        </div>

                        <div className="footerBtns">
                            <div
                            // onClick={(e) => handleBackStep()}
                            >
                                Go Back
                            </div>
                            <div
                                onClick={(e) => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                Logout
                            </div>
                        </div>
                    </>
                );

            //   case "Select Publication":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <PublicationList
            //           setPublication={setPublication}
            //           onClose={() => setStep("")}
            //         />
            //       </div>
            //     );
            //   case "Select Author":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <AuthorList setAuthor={setAuthor} onClose={() => setStep("")} />
            //       </div>
            //     );

            //   case "Enter Article":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <EnterArticle
            //           articleBody={articleBody}
            //           setArticleBody={setArticleBody}
            //           onClose={() => {
            //             setStep("");
            //             setWideDrawer(false);
            //           }}
            //         />
            //       </div>
            //     );
            //   case "Add Category":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <CategoryList
            //           categories={categories}
            //           setCategories={setCategories}
            //           onClose={() => setStep("")}
            //         />
            //       </div>
            //     );
            //   case "Add Navbar":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <NavBarList
            //           navBars={navBars}
            //           setNavBars={setNavBars}
            //           onClose={() => setStep("")}
            //         />
            //       </div>
            //     );

            default:
                return (
                    <>
                        <div className="newConglomerate">
                            <Scrollbars
                                className="scrollForm"
                                renderTrackHorizontal={() => <div />}
                                renderThumbHorizontal={() => <div />}
                                renderTrackVertical={() => <div />}
                                renderThumbVertical={() => <div />}
                            >
                                {/* Select Publication */}
                                <div className="name">What is the name of the brand?</div>
                                <div
                                    className="inputWrap"
                                >
                                    <input
                                        type="text"
                                        className="text"
                                        placeholder="Name..."
                                        value={brandName}
                                        onChange={(e) => setBrandName(e.target.value)}

                                    />
                                </div>

                                {/* Select Author */}
                                <div className="name">What industry does the brand operate in??</div>
                                {brand ? (
                                    <div
                                        className="user"
                                        onClick={() => setStep("brand")}
                                    >
                                        {/* <img
                                            className="dp"
                                            src={
                                                author?.profile_pic ? author?.profile_pic : defaultImg
                                            }
                                            alt=""
                                        /> */}
                                        <div className="name">{brand}</div>
                                    </div>
                                ) : (
                                    <div
                                        className="inputWrap"
                                        onClick={() => setStep("brand")}
                                    >
                                        <input
                                            type="text"
                                            className="text"
                                            placeholder="Click To Select"
                                            readOnly
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            <img src={arrowDown} width="10px" alt="down" />
                                        </div>
                                    </div>
                                )}

                                <div className="name">What country does the brand operate in??</div>
                                {country?.name ? (
                                    <div
                                        className="countryuser"
                                        onClick={() => setStep("country")}
                                    >
                                        <img
                                            className="dp"
                                            src={country?.image}
                                            alt=""
                                        />
                                        <div className="name">{country?.name}</div>
                                    </div>
                                ) : (
                                    <div
                                        className="inputWrap"
                                        onClick={() => setStep("country")}
                                    >
                                        <input
                                            type="text"
                                            className="text"
                                            placeholder="Click To Select"
                                            readOnly
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            <img src={arrowDown} width="10px" alt="down" />
                                        </div>
                                    </div>
                                )}

                                <div className="name">What is the company’s website?</div>
                                <div
                                    className="inputWrap"
                                >
                                    <input
                                        type="text"
                                        className="text"
                                        placeholder="Website link..."
                                        value={website}
                                        onChange={(e) => setWebsite(e.target.value)}

                                    />
                                </div>

                                <div className="name">What is the company’s colour code?</div>
                                <div
                                    className="inputWrap"
                                >
                                    <input
                                        type="text"
                                        className="text"
                                        placeholder="Colour Code..."
                                        value={colorCode}
                                        onChange={(e) => setColorCode(e.target.value)}

                                    />
                                </div>

                                <div className="name">When did you start working with this brand?</div>
                                <div className="inputWrap">
                                    <input
                                        value={brandDate}
                                        onChange={(e) => setBrandDate(e.target.value)}
                                        type="date"
                                        className="text"
                                        placeholder="Select Date"
                                    />
                                </div>



                                {/* Upload Logos */}
                                <div className="name">Upload a icon
                                    {/* <div className={"samebtn " + (selectBtn ? "active" : "")}>Use same</div> */}
                                </div>
                                {/* <br /> */}
                                {/* <div className="name" style={{ display: selectBtn ? "" : "none" }}>(Same Logo for Icon and coverPhoto)</div> */}
                                <div
                                    className="filesUpload"
                                    style={{ justifyContent: "flex-start" }}
                                >
                                    <label className="fileInp icon">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                colouredIconLoading
                                                    ? loadingGif
                                                    : colouredIcon || cloudUploadIcon
                                            }
                                            alt=""
                                            style={{ width: "40px" }}
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(
                                                    e,
                                                    setColouredIcon,
                                                    setColouredIconLoading
                                                );
                                            }}
                                            accept="image/*"
                                        />
                                        <div
                                            className="text"
                                            style={{ fontWeight: "700", fontSize: "12px" }}
                                        >
                                            {/* Colored Icon */}
                                        </div>
                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                    </label>
                                    {/* <label
                                        className="fileInp icon"
                                        style={{
                                            flex: "0 0 50%",
                                            maxWidth: "50%",
                                            marginLeft: "20px",
                                            display: selectBtn ? "none" : "",
                                        }}
                                    >
                                        <img
                                            className={`${Boolean(coverPhoto)}`}
                                            src={
                                                coverPhotoLoading
                                                    ? loadingGif
                                                    : coverPhoto || cloudUploadIcon
                                            }
                                            alt=""
                                            style={{ width: "40px" }}
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                                            }}
                                            accept="image/*"
                                        />
                                        <div
                                            className="text"
                                            style={{ fontWeight: "700", fontSize: "12px" }}
                                        >
                                            Cover Photo
                                        </div>
                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                    </label> */}
                                    {/* <label
      className="fileInp icon"
      style={{ visibility: "hidden" }}
    ></label> */}
                                </div>

                                <div className="name">Upload a logo</div>
                                <div>
                                    <div className="filesUpload"
                                        style={{ justifyContent: "flex-start", height: "145px" }}>
                                        <label
                                            className="fileInp icon"
                                            style={{
                                                flex: "0 0 100%",
                                                maxWidth: "100%",
                                                borderRadius: "15px"
                                            }}
                                        >
                                            <img
                                                className={`${Boolean(coverPhoto)}`}
                                                src={
                                                    LogoLoading
                                                        ? loadingGif
                                                        : Logo || cloudUploadIcon
                                                }
                                                alt=""
                                                style={{ width: "40px" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    uploadImage(e, setLogo, setLogoLoading);
                                                }}
                                                accept="image/*"
                                            />
                                            <div
                                                className="text"
                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                            >
                                                {/* Cover Photo */}
                                            </div>
                                            <div className="hovTxt">
                                                Upload
                                                <br />
                                                New
                                            </div>
                                        </label>
                                    </div>

                                </div>

                                <div className="name">Describe the brand</div>
                                <textarea type="text" class="text-textarea" value = {description} onChange={(e)=>setDescription(e.target.value)} placeholder="About..." rows="5" cols="40" spellcheck="false"></textarea>

                                {reqArray.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <>
                                            <div className="expandBox" key={index}>
                                                <div className="name topmarg">Name the requirement</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.name}
                                                        // onChange={(e) => setReqName(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="name.."
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="name topmarg">Describe the requirement</div>
                                                <textarea type="text"
                                                    value={item.description}
                                                    // onChange={(e) => setReqDesc(e.target.value)}
                                                    class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false" readOnly></textarea>
                                                <div>
                                                    {/* <div className="saveBtn">Save</div> */}
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    )
                                })}

                                <br />
                                <div className="name" style={{ paddingTop: "20px" }}>
                                    Add a requirement
                                </div>
                                <br />
                                <div className="expandBox">
                                    <div className="name topmarg">Name the requirement</div>
                                    <div className="inputWrap">
                                        <input
                                            value={reqName}
                                            onChange={(e) => setReqName(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="name.."
                                        />
                                    </div>
                                    <div className="name topmarg">Describe the requirement</div>
                                    <textarea type="text"
                                        value={reqDesc}
                                        onChange={(e) => setReqDesc(e.target.value)}
                                        class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false"></textarea>
                                    <div>
                                        <div className="saveBtn" style={{ cursor: "pointer" }} onClick={saveData}>Save</div>
                                    </div>
                                </div>
                                <br />
                                {approachArr.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <>
                                            <div className="expandBox" key={index}>
                                                <div className="name topmarg">Name the Approach</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.name}
                                                        // onChange={(e) => setReqName(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="name.."
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="name topmarg">Subtitle</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.subtitle}
                                                        // onChange={(e) => setSubtitle(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="Subtitle.."
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="name topmarg">Describe the requirement</div>
                                                <textarea type="text"
                                                    value={item.description}
                                                    // onChange={(e) => setReqDesc(e.target.value)}
                                                    class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false" readOnly></textarea>
                                                <div>
                                                    {/* <div className="saveBtn">Save</div> */}
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    )
                                })}

                                <br />
                                <div className="name" style={{ paddingTop: "20px" }}>
                                    Add an approach
                                </div>
                                <br />
                                <div className="expandBox">
                                    <div className="name topmarg">Name the approach</div>
                                    <div className="inputWrap">
                                        <input
                                            value={approachName}
                                            onChange={(e) => setApproachName(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="name.."
                                        />
                                    </div>
                                    <div className="name topmarg">Subtitle</div>
                                    <div className="inputWrap">
                                        <input
                                            value={subtitle}
                                            onChange={(e) => setSubtitle(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="Subtitle.."
                                        />
                                    </div>
                                    <div className="name topmarg">Describe the approach</div>
                                    <textarea type="text"
                                        value={approachDesc}
                                        onChange={(e) => setApproachDesc(e.target.value)}
                                        class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false"></textarea>
                                    <div>
                                        <div className="saveBtn" style={{ cursor: "pointer" }} onClick={saveApproach}>Save</div>
                                    </div>
                                </div>
                                <br />
                                {designArr.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <>
                                            <div className="expandBox" key={index}>
                                                <div className="name topmarg">Name of design</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.name}
                                                        // onChange={(e) => setReqName(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="name.."
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="name topmarg">Describe the requirement</div>
                                                <textarea type="text"
                                                    value={item.description}
                                                    // onChange={(e) => setReqDesc(e.target.value)}
                                                    class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false" readOnly></textarea>
                                                <div
                                                    className="filesUpload"
                                                    style={{ justifyContent: "flex-start" }}
                                                >
                                                    <label className="fileInp icon">
                                                        <img
                                                            className={`${Boolean(item.link)}`}
                                                            src={
                                                                item.link
                                                            }
                                                            alt=""
                                                            style={{ width: "40px" }}
                                                        />
                                                        {/* <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                uploadImage(
                                                                    e,
                                                                    setDesignLink,
                                                                    setColouredIconLoading
                                                                );
                                                            }}
                                                            accept="image/*"
                                                        />
                                                        <div
                                                            className="text"
                                                            style={{ fontWeight: "700", fontSize: "12px" }}
                                                        >
                                                        </div>
                                                        <div className="hovTxt">
                                                            Upload
                                                            <br />
                                                            New
                                                        </div> */}
                                                    </label>
                                                </div>
                                                <div>
                                                    {/* <div className="saveBtn">Save</div> */}
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    )
                                })}
                                <div className="name" style={{ paddingTop: "20px" }}>
                                    Add a design
                                </div>
                                <br />
                                <div className="expandBox">
                                    <div className="name topmarg">Name of design</div>
                                    <div className="inputWrap">
                                        <input
                                            value={designName}
                                            onChange={(e) => setDesignName(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="name.."
                                        />
                                    </div>
                                    <div className="name topmarg">Describe the design</div>
                                    <textarea type="text"
                                        value={designDesc}
                                        onChange={(e) => setDesignDesc(e.target.value)}
                                        class="box-textarea" placeholder="Description..." rows="5" cols="40" spellcheck="false"></textarea>
                                    <div className="name">Upload a icon
                                    </div>
                                    <div
                                        className="filesUpload"
                                        style={{ justifyContent: "flex-start" }}
                                    >
                                        <label className="fileInp icon">
                                            <img
                                                className={`${Boolean(designLink)}`}
                                                src={
                                                    colouredIconLoading
                                                        ? loadingGif
                                                        : designLink || cloudUploadIcon
                                                }
                                                alt=""
                                                style={{ width: "40px" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    uploadImage(
                                                        e,
                                                        setDesignLink,
                                                        setColouredIconLoading
                                                    );
                                                }}
                                                accept="image/*"
                                            />
                                            <div
                                                className="text"
                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                            >
                                                {/* Colored Icon */}
                                            </div>
                                            <div className="hovTxt">
                                                Upload
                                                <br />
                                                New
                                            </div>
                                        </label>
                                    </div>
                                    <div>
                                        <div className="saveBtn" style={{ cursor: "pointer" }} onClick={saveDesign}>Save</div>
                                    </div>
                                </div>
                                <br />
                                {testimonialArr.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <>
                                            <div className="expandBox" key={index}>
                                                <div className="name topmarg">Testimonial Name</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.name}
                                                        // onChange={(e) => setReqName(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="name.."
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="name topmarg">Testimonial Position</div>
                                                <div className="inputWrap">
                                                    <input
                                                        value={item.position}
                                                        // onChange={(e) => setSubtitle(e.target.value)}
                                                        type="text"
                                                        className="text"
                                                        placeholder="Position.."
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="name topmarg">Testimonial Quote</div>
                                                <textarea type="text"
                                                    value={item.quote}
                                                    // onChange={(e) => setReqDesc(e.target.value)}
                                                    class="box-textarea" placeholder="quote..." rows="5" cols="40" spellcheck="false" readOnly></textarea>
                                                <div>
                                                    {/* <div className="saveBtn">Save</div> */}
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    )
                                })}

                                <br />
                                <div className="name" style={{ paddingTop: "20px" }}>
                                    Add Testimonial
                                </div>
                                <br />
                                <div className="expandBox">
                                    <div className="name topmarg">Testimonial Name</div>
                                    <div className="inputWrap">
                                        <input
                                            value={testimonialName}
                                            onChange={(e) => setTestimonailName(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="name.."
                                        />
                                    </div>
                                    <div className="name topmarg">Testimonial Position</div>
                                    <div className="inputWrap">
                                        <input
                                            value={testimonialPosition}
                                            onChange={(e) => setTestimonialPositon(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="Position.."
                                        />
                                    </div>
                                    <div className="name topmarg">Testimonial Quote</div>
                                    <textarea type="text"
                                        value={testimonialQuote}
                                        onChange={(e) => setTestimonailQuote(e.target.value)}
                                        class="box-textarea" placeholder="Quote..." rows="5" cols="40" spellcheck="false"></textarea>
                                    <div>
                                        <div className="saveBtn" style={{ cursor: "pointer" }} onClick={saveTestimonial}>Save</div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="space"></div>
                            </Scrollbars>
                        </div >

                        {/* <div className="footerBtns"> */}
                        {/* < div className="NextBtn" >
                            Next Step
                        </div >
                        <div
                                className="newField"
                                style={{ fontWeight: 700 }}
                                onClick={(e) => {
                                    setStep("");
                                    setMainMenu("");
                                }}
                            >
                                Go Back
                            </div> */}
                        <div className="bottomProductBtn bottomData">
                            <div
                                className="updateProductBtn"
                                onClick={() => validate()}
                                style={{
                                    fontWeight: 700,
                                    opacity:
                                        brandName &&
                                            brand &&
                                            country &&
                                            website &&
                                            colorCode &&
                                            brandDate &&
                                            colouredIcon &&
                                            Logo &&
                                            reqArray &&
                                            approachArr &&
                                            designArr &&
                                            testimonialArr
                                            ? 1
                                            : 0.3,
                                }}
                            >
                                Submit Portfolio Item
                            </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{ fontWeight: 700, opacity: visibleSubmit ? 1 : 0.3 }}
              >
                Submit App
              </div>
            </div> */}
                    </>
                );
        }
    };

    return (
        <>
            {loading ? (
                // <div
                //   style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "70vh",
                //   }}
                // >
                //   <div className="loader"></div>
                //   <div style={{ padding: "20px", fontWeight: 600, color: "#18191D" }}>
                //     Creating New Publication ...
                //   </div>
                // </div>
                <div
                    style={{
                        height: window.innerHeight,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "40px",
                            fontWeight: 600,
                            color: "#18191D",
                            fontSize: "20px",
                        }}
                    >
                        Adding Portfolio...
                    </div>
                </div>
            ) : (
                getContent()
            )}
            <ToastContainer />
        </>
    );
};

export default NewPortfolio;
