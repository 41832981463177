import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";

const SoldOfferings = () => {
  const { bankerEmail, collapse, FormatCurrency, FormatNumber } =
    useContext(GlobalContex);
  const [bondSelected, setBondSelected] = useState();
  const [currenciesData, setCurrenciesData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [allSoldOfferings, setAllSoldOfferings] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [allCoins, setAllCoin] = useState([]);

  useEffect(() => {
    setDataLoading(true);

    axios
      .get("https://comms.globalxchange.io/coin/iced/banker/custom/bond/get")
      .then((res) => {
        setAllCoin(res.data.currenciesData);
      });

    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/stats?bond_creator=${bankerEmail}&displayCurrency=USD`
      )
      .then((res) => {
        console.log(res.data, "kewnckwenc");
        setAllSoldOfferings(res.data.bonds);
        setDataLoading(false);
        // setCurrenciesData(res.data.currenciesData);
      });
  }, [bankerEmail, selectedCoin]);

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Asset</div>
        <div>Cost</div>
        <div>Length</div>
        <div>Daily Return</div>
        <div>Total Investment</div>
        <div>Contracts Sold</div>
        <div>Bonds Sold</div>
      </div>
    );
  };

  const contentSection = (bond, gridClass, gridValues) => {
    return (
      <div
        // onClick={(e) => {
        //   setSelectedMcbDashboardApp(item);
        //   setShowSubDraw(true);
        // }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={allCoins?.[bond?.coinsData?.[0]?.coin]?.icon}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
            // className={classNames.icon}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">
              {/* {bond.coinsData[0].coin} */}
              {allCoins?.[bond?.coinsData?.[0]?.coin]?.name} &nbsp;
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    allCoins?.[bond?.coinsData?.[0]?.coin]?.name
                  );
                }}
                src={copyToClipboard}
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
            </div>
            <div className="subtitle">
              {bond?._id.substring(0, 10)}...&nbsp;
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(bond?._id);
                }}
                src={copyToClipboard}
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="title">
            {FormatCurrency(
              bond?.coinsData?.[0]?.bondCost,
              bond?.coinsData?.[0]?.coin
            )}{" "}
            {bond?.coinsData?.[0]?.coin}&nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${FormatCurrency(
                    bond?.coinsData?.[0]?.bondCost,
                    bond?.coinsData?.[0]?.coin
                  )} ${bond?.coinsData?.[0]?.coin}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div className="subtitle">
            ${FormatCurrency(0)}&nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(`$${FormatCurrency(0)}`);
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>

        <div>
          <div className="title">
            {FormatNumber(bond.months, 1)} Months &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${FormatNumber(bond.months, 1)} Months`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div className="subtitle">
            {bond?.days} Days &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(`${bond?.days} Days`);
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.daily_interest_rate, 2)}
            %&nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${FormatNumber(bond?.daily_interest_rate, 2)} %`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div className="subtitle">
            ${FormatCurrency(bond?.coinsData?.[0]?.perDayEarnings)}
            &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `$${FormatNumber(bond?.daily_interest_rate, 2)}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.bond_stats_data.investment, 2)}
            &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${FormatNumber(bond?.bond_stats_data.investment, 2)}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div className="subtitle">
            ${FormatCurrency(bond.bond_stats_data.investment_in_coin)}
            &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `$${FormatCurrency(bond.bond_stats_data.investment_in_coin)}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>
        <div>
          <div className="title">
            {bond.bond_stats_data.txn_count} &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${bond.bond_stats_data.txn_count}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>

        <div>
          <div className="title">
            {bond.bond_stats_data.bonds_sold} &nbsp;&nbsp;
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(
                  `${bond.bond_stats_data.bonds_sold}`
                );
              }}
              src={copyToClipboard}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        allSoldOfferings,
        dataLoading,
        "1.5fr 1fr 1.2fr 1fr 1fr 1fr 1fr", // Desktop view Grid columns
        "250px 300px 200px 200px 200px 200px 200px" // Mobile view Grid columns
      )}
    </>
  );
};

export default SoldOfferings;
