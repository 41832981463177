import React, { useEffect, useState, useContext } from 'react'
import { GlobalContex } from "../../../../globalContex";
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import BankerSubDashboard from "./BankerSubDrawer"
function BankerDashboard() {
    const { bankerEmail, setShowSubDraw } = useContext(GlobalContex);
    const [bankInfo, setbankInfo] = useState([])
    const [bankid, setbankid] = useState("")
    const [dataLoading, setDataLoading] = useState(false);
    const tab = [
        {
            name: "Payment Method"
        },
        {
            name: "Country"
        },
        {
            name: 'Bank'
        }, {
            name: "Account"
        },
        {
            name: "Currency"
        }
    ]

    const datatoggle = (e) => {
        setbankid(e)
        setShowSubDraw(true);
    }

    useEffect(() => {
        setDataLoading(true)
        axios
            .get(

                `https://accountingtool.apimachine.com/getlist-of-institutes`
            )
            .then((res) => {
                console.log("rzxczxc", res?.data?.data)
                setbankInfo(res?.data?.data)
                setDataLoading(false)

            });
    }, [bankerEmail])

    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflowY: "scroll",
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.map((item, i) => {
                                    return contentSection(item, "listDataGrid", desktopDataGrid, i);
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        {/* <AppsSubDrawer allApps={allApps} /> */}
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        {/* <AppsSubDrawer allApps={allApps} /> */}
                    </div>
                </div>
            </>
        );
    };

    const headerSection = (gridClass, gridValues) => {
        return (

            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Name</div>
                <div style={{ textAlign: "left" }}>Country</div>
                <div style={{ textAlign: "left" }}>Type</div>
                <div style={{ textAlign: "left" }}>Currencies</div>
                <div style={{ textAlign: "left" }}>Locations</div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues, i) => {
        console.log("item?.location?.length", item?.locations?.length)
        return (
            <div
                onClick={() => {
                    datatoggle(item.bank_account_id)
                }}

                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={item?.profile_image ? item?.profile_image : defaultImg}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                        <div className="title">{item?.institute_name} &nbsp;</div>
                        <div className="subtitle">{item?._id}</div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>

                        <div >
                            <div className="title">{item?.country_name} &nbsp;</div>
                            <div className="subtitle">{item?.country_code}</div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>

                        <div>
                            <div className="title">{item?.institute_type}</div>

                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >



                    <div className="title">{item.all_supported_currency.length}</div>

                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        overflow: "scroll"
                    }}
                ><div className="title">{item?.locations?.length}</div>
                </div>

            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={200} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>

                    </div>
                );
            });
    };

    return (
        <>
            {conditionalResposiveView(
                bankInfo,
                dataLoading,
                "1.5fr 1fr 1.5fr 1fr 0.3fr", // Desktop view Grid columns
                "250px 200px 300px 200px 50px" // Mobile view Grid columns
            )}

            <BankerSubDashboard

                bankid={bankid}
            />
        </>
    );
};

export default BankerDashboard;
