import React, { useContext, useEffect, useState } from "react";

import back from "../../../../../static/images/icons/back.svg";
import close from "../../../../../static/images/icons/close1.svg";
import editApp from "../../../../../static/images/clipIcons/appData.svg";
import userIcon from "../../../../../static/images/clipIcons/userIcon.svg";
import deleteApp from "../../../../../static/images/clipIcons/delete.svg";
import bondIcon from "../../../../../static/images/clipIcons/bondIcon.svg";
import plusIcon from "../../../../../static/images/clipIcons/plus.svg";
import closeIcon from "../../../../../static/images/clipIcons/no.svg";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";

import connectedBonds from "../../../../../static/images/icons/connectedBonds.svg";
import bondContracts from "../../../../../static/images/icons/bondPurchases.svg";
import bondIssued from "../../../../../static/images/icons/bondIssued.svg";
import interestPayments from "../../../../../static/images/icons/interestPayments.svg";
import bondSettlements from "../../../../../static/images/icons/bondSettlements.svg";
import expiredBonds from "../../../../../static/images/icons/expiredBonds.svg";

import { GlobalContex } from "../../../../../globalContex";
import axios from "axios";

const About = ({ allBonds, currenciesData }) => {
  const { showSubDraw, setShowSubDraw, bondSelected, setBondSelected } =
    useContext(GlobalContex);
  const [step, setStep] = useState(null);
  const [path, setPath] = useState([]);

  const [bondHoldersdata, setBondHoldersdata] = useState(null);
  const [bondHoldersdata1, setBondHoldersdata1] = useState(null);
  const [bondHoldersdata2, setBondHoldersdata2] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/users/data/get?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata(res.data);
        }
      });
  }, [bondSelected]);
  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata1(res.data);
        }
      });
  }, [bondSelected]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/stats?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata2(res.data);
        }
      });
  }, [bondSelected]);

  const offeringDefaultCase = [
    {
      name: "Bond Metadata",
      icon: editApp,
      value: null,
      enabled: false,
    },
    {
      name: "Bond Holders",
      icon: userIcon,
      value: bondHoldersdata ? bondHoldersdata.usersCount : 0,
      enabled: false,
    },
    {
      name: "Connected Bonds",
      icon: connectedBonds,
      value: bondHoldersdata1
        ? bondHoldersdata1.custom_bonds[0]?.connectedBondEnabled === true
          ? "Enabled"
          : "Disabled"
        : "",
      enabled: false,
    },
    {
      name: "Bond Contracts",
      icon: bondContracts,
      value: bondHoldersdata ? bondHoldersdata.total_bondTxnsCount : 0,
      enabled: false,
    },
    {
      name: "Bond Issued",
      icon: bondIssued,
      value: bondHoldersdata ? bondHoldersdata.total_bondsCount : 0,
      enabled: false,
    },
    {
      name: "Interest Payments",
      icon: interestPayments,
      value: bondHoldersdata2
        ? bondHoldersdata2.totalData.total_interest_payments_count
        : "",
      enabled: false,
    },
    {
      name: "Bond Settlements",
      icon: bondSettlements,
      value: bondHoldersdata2 ? bondHoldersdata2.totalData.redeemed_bonds : "",
      enabled: false,
    },
    {
      name: "Expired Bonds",
      icon: expiredBonds,
      value: bondHoldersdata2 ? bondHoldersdata2.totalData.expired_bonds : "",
      enabled: false,
    },
  ];

  useEffect(() => {
    setStep("About");
    setPath(["About"]);
  }, [showSubDraw]);

  const handleBackStep = () => {
    if (path.length > 1) {
      path.splice(-1);
      // console.log(tempPath, "kqjdkjwed");
      setPath([...path]);
      if (path.length > 0) {
        setStep(path[path.length - 1]);
      }
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  useEffect(() => {
    setStep(path[path.length - 1]);
  }, [path]);

  const conditionalSteps = () => {
    switch (step) {
      case "step1":
        return (
          <>
            <div className="sidebarTitle">This is the 1st step </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("step2");
                setPath([...path, "step2"]);
              }}
            >
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 1</div>
            </div>
          </>
        );

      case "step2":
        return (
          <>
            <div className="sidebarTitle">This is the 2nd step </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("step3");
                setPath([...path, "step3"]);
              }}
            >
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 2</div>
            </div>
          </>
        );

      case "step3":
        return (
          <>
            <div className="sidebarTitle">This is the 3rd step </div>
            <div className="sidebarCard">
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 3</div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="sidebarTitle">What Would You Like To See? </div>
            <div
              style={{ overflowY: "scroll", height: window.innerHeight - 380 }}
            >
              {offeringDefaultCase.map((item) => {
                return (
                  <div
                    // className={
                    //   item.enabled ? "sidebarCard" : "sidebarCardDisabled"
                    // }
                    className="sidebarCard"
                    onClick={(e) => {
                      if (item.enabled) {
                        setStep(item.name);
                        setPath([...path, item.name]);
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={item.icon} alt="" style={{ width: "20px" }} />
                      <div style={{ paddingLeft: "10px" }}>{item.name}</div>
                    </div>
                    <div>{item.value}</div>
                  </div>
                );
              })}
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div
        style={{
          padding: "30px",
          height: "90px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={currenciesData?.[bondSelected?.coinsData?.[0]?.coin]?.icon}
                alt=""
                style={{ width: "28px", height: "28px", borderRadius: "100%" }}
              />
              <div style={{ paddingLeft: "10px" }}>
                <div style={{ fontSize: "22px" }}>
                  {currenciesData?.[bondSelected?.coinsData?.[0]?.coin]?.name}
                  &nbsp;Bond
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {path.length > 1 ? (
                <div
                  className="backButton icon-imgs"
                  onClick={(e) => handleBackStep()}
                >
                  <img src={back} alt="" />
                </div>
              ) : (
                ""
              )}
              <div
                className="backButton icon-imgs"
                onClick={(e) => setShowSubDraw(false)}
                style={{
                  marginLeft: "20px",
                }}
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>

          <div
            className="breadcrumb"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {path?.map((item, index) => {
              return (
                <div>
                  {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                  <span
                    onClick={(e) => handleBreadcrumbClick(item)}
                    className={
                      index === path.length - 1 ? "crumbSelected" : "crumbs"
                    }
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {conditionalSteps()}
      </div>
    </>
  );
};

export default About;
