import React, { useContext, useState, useEffect } from "react";
import "./subdrawer.scss"
import axios from "axios";
import editApp from "../../../../static/images/clipIcons/appData.svg";
import close from "../../../../static/images/icons/close1.svg";
import { GlobalContex } from "../../../../globalContex";
import LoadingAnimation from "../../../../globalComponents/LoadingAnimation";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
// import Lock from "../../../../static/images/icons/lock.svg";



const Actions = ({ step, setStep }) => {
    const { showSubDraw, setShowSubDraw, holderData,
        setHolderData, loginData } = useContext(GlobalContex);
    const [withdrawData, setWithdrawData] = useState("")
    let disp
    if (step === "loading" || step === "success") {
        disp = "none"
    } else {
        disp = ""
    }

    // useEffect(() => {
    //     axios
    //         .get(
    //             `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
    //                 "bankerEmailNew"
    //             )}`
    //         )
    //         .then((res) => {
    //             console.log("ShareTokenValues ", res.data.paths)
    //             if (!selectedShareToken) {
    //                 setSelectedShareToken(res.data.paths[0])
    //             }
    //         });
    // }, [localStorage.getItem("bankerEmailNew")]);

    // console.log("selectedshare", selectedShareToken)


    const openloader = () => {
        setStep("loading")
        let body = {
            email: holderData.email,
            token: loginData.idToken,
            app_code: JSON.parse(localStorage.getItem("selectedShareCoin"))?.apps_metadata[0].app_code
        }
        console.log("thedraw-body ", body)
        axios.post("https://comms.globalxchange.io/coin/app/dividend/user/earnings/full/withdraw", body).then(res => {
            if (res.data.status) {
                setStep("success")
                setWithdrawData(res.data)
                // setTimeout(() => {
                //     setShowSubDraw(false)
                //     setStep("default")
                // }, 1000);
            } else if (res.data.message === "jwt expired") {
                setStep("Token Expired");
            } else {
                setStep("default")
            }
        })
    }

    let data = {
        status: true,
        message: "The Withdrawal is in Progress!",
        batch_identifier: "55nzpa2e18m211tm",
        totalWithdrawAmountUSD: 0.07174242383324522,
        totalWithdrawData: {
            USD: 0.0031167516905222152,
            USDT: 0.04325456374203931,
            XRP: 0.0000181743393909,
            ETH: 0.000018566531189499997,
            ABCD: 0.000018566531189499997,
            EGET: 0.000018566531189499997
        }
    }



    const conditionalcrumbs = () => {
        switch (step) {
            case "step1":
                return (
                    <p className="crumb" onClick={() => setStep("default")}>About {"->"} <span className="thebreadcrumb">Dividend Withdrawal</span></p>
                )
            default:
                return (
                    <p className="thebreadcrumb">About</p>
                )
        }
    }



    const conditionalPaths = () => {
        switch (step) {
            case "loading":
                return (
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingAnimation logoAnim sideDraw={true} />
                        <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                textAlign: "center",
                                // marginBottom: "20px",
                                color: "#182542",
                                fontSize: "18px",
                                fontWeight: "700"
                            }}
                        >
                            Withdrawing From All Dividend Vaults
                        </div>
                    </div>
                )
            case "Token Expired":
                return (
                    <div style={{ padding: "30px", height: "90px" }}>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            {/* <img src={lock} alt="" style={{ width: "20px" }} /> */}
                            <div style={{ paddingLeft: "15px" }}>Logout</div>
                        </div>
                    </div>
                );
            case "step1":
                return (
                    <>
                        <div className="defaultcard">
                            <p>How Would You Like To Withdraw Dividends?</p>
                        </div>
                        <div className="boxtext" style={{ opacity: "0.4" }}>
                            <p className="thefonttext">From A Specific Dividend Vault</p>
                        </div>
                        <div className="boxtext" onClick={openloader}>
                            <p className="thefonttext">From All Dividend Vaults</p>
                        </div>
                    </>
                )
            case "success":
                return (
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p className="success-title">Congratulations</p>

                            </div>
                            <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                                <img src={close} alt="" />
                            </div>
                        </div>
                        <p className="success-desc">You Have Successfully Transferred The Following Amounts From {holderData.name}'s {JSON.parse(localStorage.getItem("selectedShareCoin"))?.apps_metadata[0].app_code} Dividend Vaults to {JSON.parse(localStorage.getItem("selectedShareCoin"))?.apps_metadata[0].app_code} Liquid Vaults</p>
                        <div className="thevalue">
                            <p>Value Of Withdrawal:</p>
                            <p className="numvalue">${withdrawData.totalWithdrawAmountUSD.toFixed(4)} USD</p>
                        </div>
                        <div className="thescrolldata">
                            {Object.entries(withdrawData.totalWithdrawData).map((item) => {
                                return (
                                    <>
                                        <div className="tokendata">
                                            <p className="thecoin thefonttext">{item[0]}</p>
                                            <p className="thecoin thefonttext">{item[1].toFixed(10)}</p>
                                        </div>
                                        <br />
                                    </>
                                )
                            })}
                        </div>
                    </div>
                )
            default:
                return (
                    <>
                        <div className="defaultcard">
                            <p>What Would You Like To Do?</p>
                        </div>
                        <div className="boxtext" onClick={() => setStep("step1")}>
                            <p className="thefonttext">Withdraw Dividend Vault Balances</p>
                        </div>
                    </>
                )
        }
    }

    return (
        <div
            style={{
                padding: "30px",
                height: "90px",
            }}>
            <div style={{ display: disp }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={holderData.profile_img ? holderData.profile_img : defaultImg}
                            alt=""
                            style={{ width: "45px", height: "45px", borderRadius: "100%", border: "0.5px solid rgb(231, 231, 231)" }}
                        />
                        <div style={{ paddingLeft: "10px" }}>
                            <div style={{ fontSize: "22px" }}>
                                {holderData.name}
                            </div>
                            {conditionalcrumbs()}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                            <img src={close} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {conditionalPaths()}
        </div>
    )
}

export default Actions