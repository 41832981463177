import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
import "./subdrawer.module.scss"
import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const ActionIndex = ({ showDrawer, SetShowDrawer, refechProfile, setRefechProfile, ActionBtn, selectedProduct, setSelectedProduct }) => {
  const [step, setStep] = useState("default")
  const [name, setName] = useState("");
  const [username, setUsername] = useState("")
  const [bio, setBio] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [country, setCountry] = useState("");
  const [colouredIcon, setColouredIcon] = useState("");
  const [colouredIconLoading, setColouredIconLoading] = useState("");
  const [path, setPath] = useState([]);
  const [successMsg, setSuccessMsg] = useState("")
  const [loadingtext, setloadingtext] = useState("")

  const { bankerEmail, setTabSelected, productSubDrawer, setProductSubDrawer, login,loginData, refetchProduct, setRefechProduct } = useContext(GlobalContex);

  useEffect(() => {
    setStep("Action");
    setPath(["Action"]);
  }, [productSubDrawer]);

  // useEffect(() => {
  //   console.log(ActionBtn, "ActionBtn")
  //   if (ActionBtn === "Actions") {
  //     setStep("Actions")
  //   } else{
  //     setStep("default")
  //   }
  // }, [step, ActionBtn])

  useEffect(() => {
    setStep(path[path.length - 1]);
  }, [path]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  // const uploadImage = async (e, setImage, setLoading) => {
  //   setLoading(true);
  //   const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
  //     e.target.files[0].name.lastIndexOf(".")
  //   )}`;
  //   const formData = new FormData();
  //   const file = renameFile(e.target.files[0], fileName);
  //   formData.append("files", file);
  //   const path_inside_brain = "root/";

  //   const jwts = await signJwt(fileName, emailDev, secret);
  //   console.log(jwts, "lkjkswedcf");
  //   let { data } = await Axios.post(
  //     `https://publications.apimachine.com/article/upload`,
  //     formData,
  //     {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //   );
  //   setImage(data[0].urlName);
  //   setLoading(false);
  // };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf('.')
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append('files', file);
    const path_inside_brain = 'root/';

    const jwts = await signJwt(fileName, emailDev, secret);
    // console.log(jwts, 'lkjkswedcf');
    let { data } = await Axios.post(
      `https://insurance.apimachine.com/insurance/general/upload`,
      formData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    console.log(data[0].urlName, 'drive uploaded img');
     setImage(data[0].urlName);
    setTimeout(() => {
      setLoading(false)
    }, 5000);
  };


  // const validate = () => {
  //   if (
  //     name &&
  //     username &&
  //     bio &&
  //     linkedIn &&
  //     twitter &&
  //     country &&
  //     colouredIcon
  //   ) {
  //     let data = {
  //       name,
  //       user_name: username,
  //       email: bankerEmail,
  //       description: bio,
  //       social_media: [{ "LinkedIn": linkedIn, "Twitter": twitter }],
  //       profile_pic: colouredIcon,
  //       country,
  //     }
  //     addProfile(data)
  //   } else {
  //     toast.warning("Please fill all the fields")
  //   }
  // }

  const ImageUploadAPI = () => {
    let body = {
      email: bankerEmail,
      token: login?.idToken,
      field_name: "product_icon",
      field_value: colouredIcon,
      product_id: selectedProduct.product_id
    }
    console.log(body, "body")
    setStep("loading")
    setloadingtext(`Updating Icon...`)
    Axios.post("https://comms.globalxchange.io/gxb/product/edit", body)
      .then(({ data }) => {
        console.log(data, "data")
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          } else {
            setStep("default")
            toast.warning(data.message || "API Error");
          }
          setStep("default")
          toast.warning(data.message || "API Error");
        } else {
          setStep("success")
          setSuccessMsg("You have successfully edited product icon. You will be redirected to the updated Products page now.")
          setTabSelected("Products")
          setStep("default");
          setProductSubDrawer(false);
          setColouredIcon("")
          setRefechProduct(!refetchProduct)
        }
      })
      .catch((err) => {
        toast.error("API Error");
      })
    // .finally(() => {
    //   setTabSelected("My Profile")
    //   setStep("default");
    //   SetShowDrawer(false);
    //   setRefechProfile(!refechProfile)
    // })
  }

  const deleteProduct = () => {
    let body = {
      email: bankerEmail,
      token: localStorage.getItem("TokenId"),
      product_id: selectedProduct.product_id
    }
    console.log(body)
    setStep("loading")
    setloadingtext(`Deleting Product...`)
    Axios.post("https://comms.globalxchange.io/gxb/product/delete", body)
      .then(({ data }) => {
        console.log(data, "data")
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          } else {
            setStep("default")
            toast.warning(data.message || "API Error");
          }
          setStep("default")
          toast.warning(data.message || "API Error");
        } else {
          setStep("success")
          setSuccessMsg("You have successfully deleted “name of product”. You will now be redirected to your updated product list.")
          setTabSelected("Products")
          setStep("default");
          setProductSubDrawer(false);
          setColouredIcon("")
          setRefechProduct(!refetchProduct)
        }
      })
      .catch((err) => {
        toast.error("API Error");
      })
  }



  const fullHeightDrawer = (message) => {
    if (message) {
      return (
        <div
          style={{
            height: window.innerHeight - 150,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingAnimation logoAnim sideDraw={true} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {message}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: window.innerHeight - 123,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingAnimation logoAnim sideDraw={true} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
            }}
          >
            Updating Icon...
          </div>
        </div>
      );
    }
  };


  const conditionalSteps = () => {
    switch (step) {
      case "loading":
        return fullHeightDrawer(loadingtext);
      case "Token Expired":
        return (
          <>
            <div className="sidebarTitle">Token Expired. Login Again.</div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <img src={lock} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Logout</div>
            </div>
          </>
        );
      case "success":
        return (
          <div
            className="assetDispText"
            style={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "60vh",
              padding: "0px 20px",
            }}
          >
            {successMsg}
          </div>
        );

      case "Edit Product":
        return (
          <>
            <div className="sidebarTitle">What element do you want to edit?</div>
            <div className="sidebarScroll">
              <div
                className="sidebarCard"
                onClick={() => {
                  setStep("Icon");
                  setPath([...path, "Icon"]);
                }}
              >
                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                <div>Icon</div>
              </div>
            </div>
          </>
        )
      case "Icon":
        return (
          <>
            <div className="productScrollDrawer">
              {/* <div className="storyname" style={{ paddingTop: "20px" }}>
            Name
          </div> */}
              <div>
                <div className="storyname">Current Icon</div>

                <div
                  className="filesUpload ImgBox"
                  style={{ justifyContent: "flex-start", width: "100%", height: "250px" }}
                >
                  <div className="fileInp icon flexProduct">
                    <img
                      className={`${Boolean(colouredIcon)}`}
                      src={
                        selectedProduct?.product_icon

                      }
                      alt=""
                      style={{ width: "95%", height: "95%" }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="storyname">New Icon</div>

                <div
                  className="filesUpload ImgBox"
                  style={{ justifyContent: "flex-start", width: "100%" }}
                >
                  <label className="fileInp icon flexProduct">
                    <img
                      className={`${Boolean(colouredIcon)}`}
                      src={
                        colouredIconLoading
                          ? loadingGif
                          : colouredIcon || uploadGroupIcon
                      }
                      alt=""
                      style={{ width: "100px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(
                          e,
                          setColouredIcon,
                          setColouredIconLoading
                        );
                      }}
                      accept="image/*"
                    />

                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
              </div>

              <div className="bottomProductBtn">
                <button className="updateProductBtn" onClick={ImageUploadAPI}>Update Product</button>
              </div>
            </div>
          </>
        );

      case "Delete Product":
        return (
          <>
            <div className="sidebarTitle">Are you sure you want to delete this product?</div>
            <div className="sidebarScroll">
              <div
                className="sidebarCard"
                onClick={deleteProduct}
              >
                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                <div>Yes</div>
              </div>
              <div
                className="sidebarCard"
                onClick={() => {
                  setStep("Action");
                  setPath(["Action"]);
                }}
              >
                {/* <img
              src={deletePub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                <div>No</div>
              </div>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className="sidebarTitle">What do you want to do?</div>
            <div className="sidebarScroll">
              <div
                className="sidebarCard"
                onClick={() => {
                  setStep("Edit Product");
                  setPath([...path, "Edit Product"]);
                }}
              >
                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                <div>Edit Product</div>
              </div>
              <div
                className="sidebarCard"
                onClick={() => {
                  setStep("Delete Product");
                  setPath([...path, "Delete Product"]);
                }}
              >
                {/* <img
              src={deletePub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                <div>Delete Product</div>
              </div>
              {/* <div
              className="sidebarCard"
              onClick={() => {
                setStep("Add To Publication");
                setPath([...path, "Add To Publication"]);
              }}
            >
              
              <div>
                Add Author
              </div>
            </div> */}
            </div>
          </>
        )
    }
  }



  return (
    <div
      className={productSubDrawer ? "right-drawer-visible" : "right-drawer-hidden"}
      style={{ height: window.innerHeight - 123, top: 132 }}
    >
      <div
        style={{
          padding: "30px",
          height: "90px",
        }}
      >
        {
          step === "loading" ||
            step === "Token Expired" ||
            step == "success" ? (
            ""
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img
                  src={
                    selectedPublication?.profile_pic
                      ? selectedPublication?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                  <div>
                    <div style={{ fontSize: "22px" }}>
                      {selectedProduct?.product_name}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                  <div
                    className="backButton icon-imgs colorImg"
                    onClick={(e) => {
                      setPath([]);
                      setProductSubDrawer(false);
                    }}
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <img src={PurpleClose} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="breadcrumb"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {path?.map((item, index) => {
                  return (
                    <div>
                      {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                      <span
                        onClick={(e) => handleBreadcrumbClick(item)}
                        className={
                          index === path.length - 1 ? "crumbSelected" : "crumbs"
                        }
                      >
                        {item}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {conditionalSteps()}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ActionIndex;
