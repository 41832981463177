import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";

const Users = () => {
  const { bankerEmail, collapse, getDisplayDate, getDays } = useContext(GlobalContex);

  const [allMarketsverseUsers, setAllMarketsverseUsers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(0);

  let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;

  // useEffect(() => {
  //   setDataLoading(true);
  //   console.log(Math.ceil((window.innerHeight - 175) / 90), "innerHeight")
  //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?skip=0&limit=${limit}`
  //     )
  //     .then((res) => {
  //       setAllMarketsverseUsers(res.data.users);
  //       setDataLoading(false);
  //     });
  // }, [])

  const fetchData = async (pageNumber) => {
    // console.log(pageNumber, "page")
    setDataLoading(true);

    // console.log("call API")

    setDataLoading(true);

    try {
      const response = await fetch(`https://comms.globalxchange.io/coin/vault/service/usersdata/get?skip=${page}&limit=50`);
      const jsonData = await response.json();
      const newData = jsonData.users;
      // console.log("newData", newData)

      setAllMarketsverseUsers((prevData) => [...prevData, ...newData]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setDataLoading(false);

  };




  const handleScroll = (event) => {
    if (dataLoading) {
      return;
    }
    const target = event.target;

    // console.log("scrollHeight" , target.scrollHeight)
    // console.log("scrollTop" , target.scrollTop)
    // console.log("clientHeight" , target.clientHeight)
    // console.log(Math.floor(target.scrollHeight - target.scrollTop), Math.ceil(target.clientHeight))

    if (Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight) {
      // console.log("calll")
      //increment page

      // fetchData(page);
      setPage((prevPage) => prevPage + 1); // Increment the page directly
    }
  };

  useEffect(() => {
    if (allMarketsverseUsers.length === 0) {
      fetchData(page);
    }
  }, []);

  // Add an event listener for scroll on component mount
  useEffect(() => {
    const scrollContainer = document.getElementById('usersData');
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the event listener on component unmount
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    // console.log("thepage", page)
    if (page > 0) {
      fetchData(page);
    }
  }, [page]);




  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div id="usersData"
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflow: 'auto'
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((item) => {
                  return contentSection(item, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((item, index) => {
                  return contentSection(
                    item,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Name</div>
        <div style={{ textAlign: "left" }}>Date</div>
        <div style={{ textAlign: "left" }}>Upline</div>
        <div style={{ textAlign: "left" }}>Username</div>
        <div style={{ textAlign: "left" }}>App</div>
      </div>
    );
  };

  const contentSection = (item, gridClass, gridValues) => {
    // console.log(item, "item")
    let imageDisp
    if (item?.profile_img || item?.profile_img !== "") {
      imageDisp = item?.profile_img
    } else {
      imageDisp = defaultImg
    }
    return (
      <div
        // onClick={(e) => {
        //   setSelectedMcbDashboardApp(item);
        //   setShowSubDraw(true);
        // }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={imageDisp}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
          // className={classNames.icon}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{item?.name} &nbsp;</div>
            <div className="subtitle">{item?.email}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="title">{getDays(item?.date)}</div>
          <div className="subtitle">Since {getDisplayDate(item?.date)}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="title">--</div>
          <div className="subtitle">--</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="title">{item?.username}</div>
          {/* <div className="subtitle">{item?.subCategory}</div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="title">--</div>
          {/* <div className="subtitle">$0.00</div> */}
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(limit > 2 ? limit - 2 : 1)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <Skeleton width={80} />
              <Skeleton width={120} style={{ height: "10px" }} />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <Skeleton width={50} />
              <Skeleton width={50} style={{ height: "10px" }} />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <Skeleton width={70} />
              {/* <Skeleton width={50} style={{ height: "10px" }} /> */}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}>
              <Skeleton width={50} />
              {/* <Skeleton width={40} style={{ height: "10px" }} /> */}
            </div>
          </div>
        );
      });
  };

  return (
    <div>
      {conditionalResposiveView(
        allMarketsverseUsers,
        dataLoading,
        "1.2fr 1fr 0.5fr 1fr 0.5fr", // Desktop view Grid columns
        "250px 200px 300px 200px 70px" // Mobile view Grid columns
      )}
    </div>
  );
};

export default Users;
