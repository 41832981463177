import React, { Fragment, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function BondTypeList({ setBondType, onClose }) {
  const [search, setSearch] = useState("");

  const bondTypes = [{ name: "Fixed Income" }];

  return (
    <Fragment>
      <div className="titleOp">Select Bond Type</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bond Types....|"
        />
      </div>
      <Scrollbars className="searchList">
        {bondTypes
          .filter((bondTypes) =>
            bondTypes.name?.toLowerCase().includes(search.toLowerCase())
          )
          .map((item) => (
            <div
              className="user"
              key={item.name}
              onClick={() => {
                setBondType(item);
                onClose();
              }}
              style={{ fontSize: "22px", fontWeight: 700 }}
            >
              <div>{item.name}</div>
              {/* <img className="dp" src={app.app_icon} alt="" />
                  <div className="userDetail">
                    <div className="name">{app.app_name}</div>
                    <div className="email">{app.created_by}</div>
                  </div> */}
            </div>
          ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default BondTypeList;
