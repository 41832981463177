import React, { useState, useEffect, useContext } from "react";
import NavBar from "../../../../globalComponents/NavBar";
import { GlobalContex } from "../../../../globalContex";
import Skeleton from "react-loading-skeleton";
import OfferingSubDrawer from "./OfferingSubDrawer";
import axios from "axios";
import "./offerings.scss";
import copyToClipboard from "../../../../static/images/icons/copy.svg";

const Offerings = () => {
  const {
    setShowSubDraw,
    bankerEmail,
    allCoins,
    FormatCurrency,
    FormatNumber,
    bondSelected,
    setBondSelected,

    // allBonds,
    // setAllBonds,
    // currenciesData,
    // setCurrenciesData,
    collapse,
    globalFilter,
    refetchBonds,
    setRefetchBonds,
    selectedAssetFilters,
    selectedStatusFilters,
    selectedLengthFilter,
    setSelectedLengthFilter,
    coinsCombinations,
    setCoinsCombinations,
  } = useContext(GlobalContex);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [dataLoading, setDataLoading] = useState(false);

  const [allBondCoins, setAllBondCoins] = useState({});
  const [allBonds, setAllBonds] = useState([]);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [currenciesData, setCurrenciesData] = useState(null);
  const [refetchData, setRefetchData] = useState(false);

  // useEffect(() => {
  //   setDataLoading(true);
  //   if (selectedCoin) {
  //     axios
  //       .get(
  //         `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?coin=${selectedCoin}&email=${bankerEmail}`
  //       )
  //       .then((res) => {
  //         console.log(res.data, "kewnckwenc");
  //         setAllBonds(res.data.custom_bonds);
  //         //   setCurrenciesData(res.data.currenciesData);
  //         setDataLoading(false);
  //         // setCurrenciesData(res.data.currenciesData);
  //       });
  //   } else {
  //     axios
  //       .get(
  //         `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`
  //       )
  //       .then((res) => {
  //         setAllBonds(res.data.custom_bonds);
  //         setCurrenciesData(res.data.currenciesData);
  //         setDataLoading(false);
  //       });
  //   }
  // }, [bankerEmail, selectedCoin]);

  useEffect(() => {
    // setDataLoading(true);
    // console.log("useeefectran");
    // axios
    //   .get(
    //     `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`
    //   )
    //   .then((res) => {
    //     setAllBonds(res.data.custom_bonds);
    //     setCurrenciesData(res.data.currenciesData);
    //     setDataLoading(false);
    //     setRefetchData(false);
    //   });
    // getFilteredBondsWithStatus();
    getSuggestions();
  }, [bankerEmail]);

  const getFilteredBondsWithAsset = () => {
    setDataLoading(true);
    if (selectedLengthFilter !== "") {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}&days=${selectedLengthFilter}`,
          {
            include: {
              coins: [...selectedAssetFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    } else {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`,
          {
            include: {
              coins: [...selectedAssetFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    }
  };

  const getFilteredBondsWithStatus = () => {
    setDataLoading(true);
    if (selectedLengthFilter !== "") {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}&days=${selectedLengthFilter}`,
          {
            include: {
              status: [...selectedStatusFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    } else if (selectedStatusFilters.length > 0) {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`,
          {
            include: {
              status: [...selectedStatusFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setCurrenciesData(res.data.currenciesData);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    } else {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`
        )
        .then((res) => {
          setAllBonds(res.data.custom_bonds);
          setCurrenciesData(res.data.currenciesData);
          setDataLoading(false);
          setRefetchData(false);
        });
    }
  };

  const getFilteredBondsWithBoth = () => {
    setDataLoading(true);
    if (selectedLengthFilter !== "") {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}&days=${selectedLengthFilter}`,
          {
            include: {
              status: [...selectedStatusFilters],
              coins: [...selectedAssetFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    } else {
      axios
        .post(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`,
          {
            include: {
              status: [...selectedStatusFilters],
              coins: [...selectedAssetFilters],
            },
          }
        )
        .then((res) => {
          console.log(res.data, "kjbwkdwjed");
          setAllBonds(res.data.custom_bonds);
          setDataLoading(false);
          setRefetchBonds(false);
        });
    }
  };

  const getFilteredBondsWithLength = () => {
    setDataLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}&days=${selectedLengthFilter}`,
        {}
      )
      .then((res) => {
        console.log(res.data, "kjbwkdwjed");
        setAllBonds(res.data.custom_bonds);
        setDataLoading(false);
        setRefetchBonds(false);
      });
  };

  const getAllBonds = () => {
    setDataLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`
      )
      .then((res) => {
        console.log(res.data, "kjbwkdwjed");
        setAllBonds(res.data.custom_bonds);
        setDataLoading(false);
        setRefetchBonds(false);
      });
  };

  const getSuggestions = () => {
    setDataLoading(true);
    axios
      .get(`https://defi.apimachine.com/supplyborrowrates/mlsuggestion/smart`)
      // .get(`http://localhost:3998/supplyborrowrates/mlsuggestion/smart`)
      .then((res) => {
        
        setCoinsCombinations(res.data.suggestion);
        setDataLoading(false);
        setRefetchBonds(false);
      });
  };

  useEffect(() => {
    if (refetchBonds) {
      if (
        refetchBonds &&
        selectedAssetFilters.length > 0 &&
        selectedStatusFilters.length === 0
      ) {
        getFilteredBondsWithAsset();
      } else if (
        refetchBonds &&
        selectedStatusFilters.length > 0 &&
        selectedAssetFilters.length === 0
      ) {
        getFilteredBondsWithStatus();
      } else if (
        refetchBonds &&
        selectedAssetFilters.length > 0 &&
        selectedStatusFilters.length > 0
      ) {
        getFilteredBondsWithBoth();
      } else if (
        refetchBonds &&
        selectedAssetFilters.length === 0 &&
        selectedStatusFilters.length === 0 &&
        selectedLengthFilter !== ""
      ) {
        getFilteredBondsWithLength();
      } else if (
        refetchBonds &&
        selectedAssetFilters.length === 0 &&
        selectedStatusFilters.length === 0 &&
        selectedLengthFilter == ""
      ) {
        getAllBonds();
      }
    }
  }, [refetchBonds]);

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>CoinsCombo</div>
        <div>Interest (APY)</div>
        <div>Swap gain (APY)</div>
        <div>ThirdSwap</div>
        <div>CummulativeCF</div>
        <div>Network</div>
        {/* <div>Stability</div> */}
      </div>
    );
  };

  const contentSection = (bond, gridClass, gridValues) => {
    return (
      <div
        onClick={(e) => {
          setBondSelected(bond);
          setShowSubDraw(true);
        }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={currenciesData?.[bond?.coinsData?.[0]?.coin]?.icon}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
            // className={classNames.icon}
          /> */}
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">
              {bond.coinsCombo}&nbsp;
              {/* {currenciesData?.[bond?.coinsData?.[0]?.coin]?.name} &nbsp; */}
              {/* <img
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    allBonds?.currenciesData?.[bond?.coinsData?.[0]?.coin]?.name
                  );
                }}
                src={copyToClipboard}
                alt=""
                style={{ width: "15px", height: "15px" }}
              /> */}
            </div>
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.interestGain, 2)}
            %&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.interestGainDueToSwap, 2)}
            %&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">{bond.ThirdSwap}&nbsp;</div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.cummulativeCollateralFactor, 2)}%&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">{bond.network}&nbsp;</div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail" style={{ paddingTop: "10px" }}>
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            {/* <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div> */}
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <div>
        {conditionalResposiveView(
          coinsCombinations,
          // allBonds,
          dataLoading,
          "1fr 1fr 1fr 1fr 1fr 2.5fr", // Desktop view Grid columns
          "200px 200px 200px 200px 200px 350px" // Mobile view Grid columns
        )}
        {/* <OfferingSubDrawer
          allBonds={allBonds}
          currenciesData={currenciesData}
        /> */}
      </div>

      {/* <div style={{ width: "100%" }}>
        <div
          className="listGrid"
          style={{ gridTemplateColumns: "1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr" }}
        >
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
          <div>Annual Return</div>
          <div>Term Return</div>
        </div>
      </div>
      <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
          height: window.innerHeight - 175,
          overflowY: "scroll",
        }}
      >
        {!dataLoading ? (
          allBonds ? (
            allBonds.map((bond) => {
              return (
                <div
                  className="listDataGrid"
                  style={{
                    gridTemplateColumns: "1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr",
                  }}
                  onClick={(e) => {
                    setBondSelected(bond);
                    setShowSubDraw(true);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={currenciesData?.[bond?.coinsData?.[0]?.coin]?.icon}
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                      }}
                      // className={classNames.icon}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                      <div className="title">
                        {currenciesData?.[bond?.coinsData?.[0]?.coin]?.name}{" "}
                        &nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              allBonds?.currenciesData?.[
                                bond?.coinsData?.[0]?.coin
                              ]?.name
                            );
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                      <div className="subtitle">
                        {bond?._id.substring(0, 10)}...&nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(bond?._id);
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="title">
                      {FormatCurrency(
                        bond?.coinsData?.[0]?.bondCost,
                        bond?.coinsData?.[0]?.coin
                      )}{" "}
                      {bond?.coinsData?.[0]?.coin}&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatCurrency(
                              bond?.coinsData?.[0]?.bondCost,
                              bond?.coinsData?.[0]?.coin
                            )} ${bond?.coinsData?.[0]?.coin}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      ${FormatCurrency(0)}&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `$${FormatCurrency(0)}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="title">
                      {FormatNumber(bond.months, 0)} Months &nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatNumber(bond.months, 1)} Months`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      {bond?.days} Days &nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(`${bond?.days} Days`);
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="title">
                      {FormatNumber(bond?.daily_interest_rate, 2)}
                      %&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatNumber(bond?.daily_interest_rate, 2)} %`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      ${FormatCurrency(bond?.coinsData?.[0]?.perDayEarnings)}
                      &nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `$${FormatNumber(bond?.daily_interest_rate, 2)}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="title">
                      {FormatNumber(bond?.monthly_interest_rate, 2)}
                      %&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatNumber(bond?.monthly_interest_rate, 2)} %`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      ${FormatCurrency(bond?.coinsData?.[0]?.monthlyEarnings)}
                      &nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `$${FormatCurrency(
                              bond?.coinsData?.[0]?.monthlyEarnings
                            )}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="title">
                      {FormatNumber(bond?.annual_interest_rate, 2)}
                      %&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatNumber(bond?.annual_interest_rate, 2)} %`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      ${FormatCurrency(bond?.coinsData?.[0]?.annualEarnings)}
                      &nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `$${FormatCurrency(
                              bond?.coinsData?.[0]?.annualEarnings
                            )}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="title">
                      {FormatNumber(bond?.term_interest_rate, 2)}%&nbsp;&nbsp;
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `${FormatNumber(bond?.term_interest_rate, 2)} %`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                    <div className="subtitle">
                      ${FormatCurrency(bond?.coinsData?.[0]?.termEarnings)}
                      &nbsp;&nbsp;
                      <img
                        // className={classNames.copy}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            `$${FormatCurrency(
                              bond?.coinsData?.[0]?.termEarnings
                            )}`
                          );
                        }}
                        src={copyToClipboard}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                    </div>
                  </div>
                </div>

                // <div className="bondsUsersGrid">
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              No Records Found
            </div>
          )
        ) : (
          Array(10)
            .fill("")
            .map((item, i) => {
              return (
                <div
                  className="listDataGrid post"
                  style={{
                    width: "100%",
                    // height: "120px",
                    gridTemplateColumns: "1.5fr 1fr 1.2fr 1fr 1fr 1fr 1fr",
                    // borderBottom: "solid 0.5px #EEEEEE",
                  }}
                >
                  <div
                    key={i}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Skeleton
                      className="dp"
                      circle
                      width={50}
                      height={50}
                      style={{ marginRight: "20px" }}
                    />
                    <div className="userDetail" style={{ paddingTop: "10px" }}>
                      <Skeleton width={100} />
                      <Skeleton width={120} style={{ height: "10px" }} />
                    </div>
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                    <Skeleton width={80} style={{ height: "10px" }} />
                  </div>
                </div>
              );
            })
        )}

        <OfferingSubDrawer
          allBonds={allBonds}
          currenciesData={currenciesData}
        />
      </div> */}
    </>
  );
};

export default Offerings;
