import React from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";

import TabsLayout from "../../globalComponents/TabsLayout";
import AdminModal from "../../globalComponents/AdminModal";

import crm from "../../static/images/sidebarIcons/crm.svg";
import dash from "../../static/images/sidebarIcons/dash.svg";
import affiliates from "../../static/images/sidebarIcons/affiliates.svg";
import vaults from "../../static/images/sidebarIcons/vaults.svg";
import terminal from "../../static/images/sidebarIcons/terminal.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";
import loans from "../../static/images/sidebarIcons/socially.svg";
import Lock from "../../static/images/icons/lock.svg";
import defaultImg from "../../static/images/icons/app_placeholder.png";
import BankerDashboard from "./BankerDashboard";
import VaultPage from "../../globalComponents/VaultPage";
import BankerBonds from "./BankerBonds";
import BankerCRM from "./BankerCRM";
import DefiSuggestions from "./DefiSuggestions";
import MobileMenu from "../../globalComponents/MobileMenu";
import DefiCoins from "./DefiCoins";
import CapitalizedCRM from "../Capitalized/CapitalizedCRM";
import Dividends from "../Capitalized/CapitalizedDividends";
import Affiliate from "../Capitalized/CapitalizedAffiliate";
import Funds from "../FundManagers/FundManagerFunds";
import OTCDashboard from "../OTCDesks/OTCDesksDahboard";
import OTCPayments from "../OTCDesks/OtcPayment";
import TerminalAssets from "../Terminals/TerminalAssets";

const Banker = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    localMenu,
    globalMenu,
    setDispdraw,
    showMobileMenu,
    bankerMenu,
  } = useContext(GlobalContex);

  // const openside = (e) => {
  //   themail = e.currentTarget.id;
  //   value.setselectemail(e.currentTarget.id);
  //   value.setdispdraw("TranslateX(0%)");
  //   value.settriggerevent("none");
  // };

  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      case "Dashboard":
        return <BankerDashboard />;
      case "Vaults":
        return <VaultPage />;
      case "Bonds":
        return <BankerBonds />;
      case "CRM":
        return <BankerCRM />;
      case "DefiSuggestions":
        return <DefiSuggestions />;
      case "DefiCoins":
        return <DefiCoins />;
      case "Dividends":
        return <Dividends />;
      case "ShareTokens CRM":
        return <CapitalizedCRM />;
      case "Affiliate":
        return <Affiliate />;
      case "Funds":
        return <Funds/>;
      case "OTC Dashboard":
        return <OTCDashboard/>;
      case "OTC Payments":
        return <OTCPayments/>;
      case "Terminal Assets":
        return <TerminalAssets/>
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={bankerMenu} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
            {/* <div className="filterRow"></div> */}
            {/* <div style={{ flexGrow: 1 }}>{conditionalPages()}</div> */}
          </div>
        </div>
      </div>

      {showMobileMenu ? (
        <MobileMenu localMenu={bankerMenu} />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}

      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default Banker;
