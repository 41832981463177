import React, { useState, useEffect, useContext } from "react";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";

import axios from "axios";
import "./UserCard.scss";
import { GlobalContex } from "../../../../globalContex";
import Skeleton from "react-loading-skeleton";

const EarningsPerAllCustomer = ({
  selectedUserBondHash,
  setSelectedUserBondHash,
  allUserData,
  setAllUserData,
}) => {
  const usdCoinImage =
    "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png";
  const {
    allCoins,
    collapse,
    bankerEmail,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedFilter2,
    setSelectedFilter2,
  } = useContext(GlobalContex);

  // const [allUserData, setAllUserData] = useState(null);

  const [totalSpreadEarned, setTotalSpreadEarned] = useState({});
  const [totalInterestEarned, setTotalInterestEarned] = useState({});
  const [totalInterestPaid, setTotalInterestPaid] = useState({});
  const [totalCustomComPaid, setTotalCustomComPaid] = useState({});

  useEffect(() => {
    setAllUserData(null);
    if (selectedFilter2 && startDate && endDate) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedFilter2}&from_date=${startDate}&to_date=${endDate}`
        )
        .then((res) => {
          setAllUserData(res.data);
          setTotalSpreadEarned(res.data.totalSpreadEarned);
          setTotalInterestEarned(res.data.totalInterestEarned);
          setTotalInterestPaid(res.data.totalInterestPaid);
          setTotalCustomComPaid(res.data.totalCustomComPaid);
        });
    } else if (selectedFilter2 && !endDate) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedFilter2}`
        )
        .then((res) => {
          setAllUserData(res.data);
          setTotalSpreadEarned(res.data.totalSpreadEarned);
          setTotalInterestEarned(res.data.totalInterestEarned);
          setTotalInterestPaid(res.data.totalInterestPaid);
          setTotalCustomComPaid(res.data.totalCustomComPaid);
        });
    } else if (!selectedFilter2 && endDate) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&from_date=${startDate}&to_date=${endDate}`
        )
        .then((res) => {
          setAllUserData(res.data);
          setTotalSpreadEarned(res.data.totalSpreadEarned);
          setTotalInterestEarned(res.data.totalInterestEarned);
          setTotalInterestPaid(res.data.totalInterestPaid);
          setTotalCustomComPaid(res.data.totalCustomComPaid);
        });
    } else {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}`
        )
        .then((res) => {
          setAllUserData(res.data);
          setTotalSpreadEarned(res.data.totalSpreadEarned);
          setTotalInterestEarned(res.data.totalInterestEarned);
          setTotalInterestPaid(res.data.totalInterestPaid);
          setTotalCustomComPaid(res.data.totalCustomComPaid);
        });
    }
  }, [bankerEmail, selectedFilter2, startDate, endDate]);

  return (
    <div style={{ height: window.innerHeight - 135, overflowY: "scroll" }}>
      {allUserData ? (
        <>
          <div className="cardTop">
            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: "8px" }}>
                <div className="userName">All Users</div>
                <div className="userNameSubtitle">
                  {allUserData?.usersCount} Bond Holders
                </div>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="cardBottom">
            <div className="cards">
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35px", height: "35px" }}>
                    <img
                      src={usdCoinImage}
                      alt=""
                      style={{ borderRadius: "50%", width: "100%" }}
                    />
                  </div>
                  <div className="assetName">All Assets</div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "35px",
                  }}
                >
                  <div className="leftAlign">
                    <div className="statTitle">
                      ${allUserData?.totalInterestEarnedUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Earned</div>
                  </div>
                  <div className="rightAlign">
                    <div className="statTitle">
                      ${allUserData?.totalCustomComPaidUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Custom Commission</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                  }}
                >
                  <div className="leftAlign">
                    <div className="statTitle">
                      ${allUserData?.totalInterestPaidUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Paid</div>
                  </div>
                  <div className="rightAlign">
                    <div className="statTitle">
                      ${allUserData?.totalSpreadEarnedUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Spread</div>
                  </div>
                </div>
              </div>
            </div>

            {Object.entries(totalSpreadEarned).map(([key, value], i) => (
              <div className="cards">
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "35px", height: "35px" }}>
                      <img
                        src={
                          allCoins.find((o) => o.coinSymbol === key).coinImage
                        }
                        alt=""
                        style={{ borderRadius: "50%", width: "100%" }}
                      />
                    </div>

                    <div className="assetName">
                      {allCoins.find((o) => o.coinSymbol === key).coinName}
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "35px",
                    }}
                  >
                    <div className="leftAlign">
                      <div className="statTitle">
                        {totalInterestEarned[key]?.toFixed(4)}
                      </div>
                      <div className="statSubtitle">Interest Earned</div>
                    </div>
                    <div className="rightAlign">
                      <div className="statTitle">
                        {totalCustomComPaid[key]?.toFixed(4)}
                      </div>
                      <div className="statSubtitle">Custom Commission</div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "30px",
                    }}
                  >
                    <div className="leftAlign">
                      <div className="statTitle">
                        {totalInterestPaid[key]?.toFixed(4)}
                      </div>
                      <div className="statSubtitle">Interest Paid</div>
                    </div>
                    <div className="rightAlign">
                      <div className="statTitle">
                        {totalSpreadEarned[key]?.toFixed(4)}
                      </div>
                      <div className="statSubtitle">Interest Spread</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        ""
      )}

      {allUserData
        ? allUserData.users.map((item) => {
            return (
              <>
                <div className="cardTop">
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={
                          item.userDetail.profile_img
                            ? item.userDetail.profile_img
                            : defaultImg
                        }
                        alt=""
                        width="35px"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <div style={{ paddingLeft: "8px" }}>
                      <div className="userName">{item?.userDetail.name}</div>
                      <div className="userNameSubtitle">{item?._id}</div>
                    </div>
                  </div>
                  <div
                    className="rightAlign"
                    style={{ paddingLeft: "8px" }}
                    // onClick={(e) => setSelectedUserBondHash(item)}
                  >
                    <div className="userName">
                      {item?.bondsCount} Bond Hashes
                    </div>
                    <div
                      className="userNameSubtitle clickable"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSelectedUserBondHash(item);
                        setSelectedFilter2(null);
                      }}
                    >
                      See Bond Hash List
                    </div>
                  </div>
                </div>
                <div className="cardBottom">
                  <div className="cards">
                    <div>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "35px", height: "35px" }}>
                          <img
                            src={usdCoinImage}
                            alt=""
                            style={{ borderRadius: "50%", width: "100%" }}
                          />
                        </div>
                        <div className="assetName">All Assets</div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "35px",
                        }}
                      >
                        <div className="leftAlign">
                          <div className="statTitle">
                            ${item?.totalInterestEarnedUSD.toFixed(2)}
                          </div>
                          <div className="statSubtitle">Interest Earned</div>
                        </div>
                        <div className="rightAlign">
                          <div className="statTitle">
                            ${item?.totalCustomComPaidUSD.toFixed(2)}
                          </div>
                          <div className="statSubtitle">Custom Commission</div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "30px",
                        }}
                      >
                        <div className="leftAlign">
                          <div className="statTitle">
                            ${item?.totalInterestPaidUSD.toFixed(2)}
                          </div>
                          <div className="statSubtitle">Interest Paid</div>
                        </div>
                        <div className="rightAlign">
                          <div className="statTitle">
                            ${item?.totalSpreadEarnedUSD.toFixed(2)}
                          </div>
                          <div className="statSubtitle">Interest Spread</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Object.entries(item?.totalSpreadEarned).map(
                    ([key, value], i) => (
                      <div className="cards">
                        <div>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "35px", height: "35px" }}>
                              <img
                                src={
                                  allCoins.find((o) => o.coinSymbol === key)
                                    .coinImage
                                }
                                alt=""
                                style={{ borderRadius: "50%", width: "100%" }}
                              />
                            </div>

                            <div className="assetName">
                              {
                                allCoins.find((o) => o.coinSymbol === key)
                                  .coinName
                              }
                            </div>
                          </div>
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "35px",
                            }}
                          >
                            <div className="leftAlign">
                              <div className="statTitle">
                                {item?.totalInterestEarned[key]?.toFixed(4)}
                              </div>
                              <div className="statSubtitle">
                                Interest Earned
                              </div>
                            </div>
                            <div className="rightAlign">
                              <div className="statTitle">
                                {item?.totalCustomComPaid[key]?.toFixed(4)}
                              </div>
                              <div className="statSubtitle">
                                Custom Commission
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "30px",
                            }}
                          >
                            <div className="leftAlign">
                              <div className="statTitle">
                                {item?.totalInterestPaid[key]?.toFixed(4)}
                              </div>
                              <div className="statSubtitle">Interest Paid</div>
                            </div>
                            <div className="rightAlign">
                              <div className="statTitle">
                                {item?.totalSpreadEarned[key]?.toFixed(4)}
                              </div>
                              <div className="statSubtitle">
                                Interest Spread
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            );
          })
        : Array(5)
            .fill("")
            .map((item) => {
              return (
                <>
                  <div className="cardTop">
                    <div style={{ display: "flex" }}>
                      <div>
                        <Skeleton
                          className="dp"
                          circle
                          width={35}
                          height={35}
                          style={{ marginRight: "20px" }}
                        />
                      </div>
                      <div style={{ paddingLeft: "8px" }}>
                        <div className="userName">
                          <Skeleton className="name" width={160} />
                        </div>
                        <div className="userNameSubtitle">
                          <Skeleton
                            className="email"
                            width={200}
                            style={{ height: "10px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                  <div className="cardBottom">
                    {Array(5)
                      .fill("")
                      .map((item) => {
                        return (
                          <div className="cards">
                            <div>
                              <div
                                className="user"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Skeleton
                                  className="dp"
                                  circle
                                  width={50}
                                  height={50}
                                  style={{ marginRight: "20px" }}
                                />
                                <div className="userDetail">
                                  <Skeleton className="name" width={160} />
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "35px",
                                }}
                              >
                                <div className="leftAlign">
                                  <div className="statTitle">
                                    <Skeleton
                                      className="email"
                                      width={80}
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                  <div className="statSubtitle">
                                    <Skeleton
                                      className="email"
                                      width={100}
                                      style={{ height: "8px" }}
                                    />
                                  </div>
                                </div>
                                <div className="rightAlign">
                                  <div className="statTitle">
                                    <Skeleton
                                      className="email"
                                      width={80}
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                  <div className="statSubtitle">
                                    <Skeleton
                                      className="email"
                                      width={100}
                                      style={{ height: "8px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "30px",
                                }}
                              >
                                <div className="leftAlign">
                                  <div className="statTitle">
                                    <Skeleton
                                      className="email"
                                      width={80}
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                  <div className="statSubtitle">
                                    <Skeleton
                                      className="email"
                                      width={100}
                                      style={{ height: "8px" }}
                                    />
                                  </div>
                                </div>
                                <div className="rightAlign">
                                  <div className="statTitle">
                                    <Skeleton
                                      className="email"
                                      width={80}
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                  <div className="statSubtitle">
                                    <Skeleton
                                      className="email"
                                      width={100}
                                      style={{ height: "8px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            })}
    </div>
  );
};

export default EarningsPerAllCustomer;
