import React, { useEffect, useState, useContext } from 'react'
import { GlobalContex } from "../../../globalContex";
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../../static/images/icons/app_placeholder.png";
import BankerSubDashboard from "./BankerSubDrawer"
function BankerDashboard() {
  const { bankerEmail ,setShowSubDraw} = useContext(GlobalContex);
  const [paymentInfo, setpaymentInfo] = useState([])
  const [bankid,setbankid]=useState("")
  const [dataLoading, setDataLoading] = useState(false);
  const tab = [
    {
      name: "Payment Method"
    },
    {
      name: "Country"
    },
    {
      name: 'Bank'
    }, {
      name: "Account"
    },
    {
      name: "Currency"
    }
  ]

  const datatoggle=(e)=>{
    setbankid(e)
    setShowSubDraw(true);
  }

  useEffect(() => {
    setDataLoading(true)
    axios
      .get(
        
        `https://comms.globalxchange.io/coin/vault/service/payment/methods/get`
      )
      .then((res) => {
        console.log("rzxczxc", res?.data?.methods)
        setpaymentInfo(res?.data?.methods)
        setDataLoading(false)

      });
  }, [bankerEmail])

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((item) => {
                  return contentSection(item, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((item, index) => {
                  return contentSection(
                    item,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (

           <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
           <div>Name</div>
           {/* <div style={{ textAlign: "left" }}>Country</div>
           <div style={{ textAlign: "left" }}>Bank</div>
           <div style={{ textAlign: "left" }}>Account</div>
           <div style={{ textAlign: "left" }}>Currency</div> */}
       </div>
    );
  };

  const contentSection = (item, gridClass, gridValues) => {
    return (
      <div
        onClick={() => {
          datatoggle(item.bank_account_id)
       //   setSelectedMcbDashboardApp(item);
       
        }}


        //           <img src={item?.paymentMethod_data?.icon} alt="" />
        // //                   <div>
        // //                     <h1>{item?.paymentMethod_data?.name}</h1>
        // //                     <p>{item?.paymentMethod_data?.code} </p>
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={item?.icon ? item?.icon : defaultImg}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{item?.name} &nbsp;</div>
            <div className="subtitle">{item?.code}</div>
          </div>
        </div>


        {/* <img src={item?.countryData?.image} alt="" />
//                   <div>
//                     <h1>{item?.countryData?.name}</h1>
//                     <p>{item?.countryData?.code} </p> */}

{/* 
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={item?.countryData?.image ? item?.countryData?.image : defaultImg}
              alt=""
              style={{
                // borderRadius: "50%",
                width: "30px",
              }}
            />
            <div style={{ paddingLeft: "15px" }}>
              <div className="title">{item?.countryData?.name} &nbsp;</div>
              <div className="subtitle">{item?.countryData?.code}</div>
            </div>
          </div>
        </div> */}

        {/* 
        <div className='row-bank'>
  //                   <img src={item?.instituteData?.profile_image} alt="" />
  //                   <div>
  //                     <h1>{item?.instituteData?.institute_name}</h1>
  //                     <p>{item?.instituteData?._id} </p>
  //                   </div>
  //                 </div> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={item?.instituteData?.profile_image ? item?.instituteData?.profile_image : defaultImg}
              alt=""
              style={{
                // borderRadius: "50%",
                width: "30px",
              }}
            />
            <div style={{ paddingLeft: "15px" }}>
              <div className="title">{item?.instituteData?.institute_name}</div>
              <div className="subtitle">{item?.instituteData?._id}</div>
            </div>
          </div>
        </div> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >

          <div className="title">{item?.account_name}</div>
          <div className="subtitle">{item?.bank_account_id}</div>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="title">{item?.currency}</div>

        </div> */}
      
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            {/* <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div> */}
            {/* <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div> */}
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        paymentInfo,
        dataLoading,
        "1fr 1fr 1.5fr 1fr 0.3fr", // Desktop view Grid columns
        "250px 200px 300px 200px 70px" // Mobile view Grid columns
      )}

      <BankerSubDashboard

bankid={bankid}
/>
    </>
  );
};

export default BankerDashboard;
