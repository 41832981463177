import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../../static/images/icons/app_placeholder.png";
import CRMdrawer from "./CRMSubDrawer";

const CapitalizedCRM = () => {
  const { shareToken, collapse, bondSelected, bankerEmail, selectedAppData, setShowSubDraw, holderData, setHolderData , selectedShareToken } =
    useContext(GlobalContex);

  const [allShareHolders, setAllShareHolders] = useState([]);
  const [step , setStep] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(bankerEmail, "ThebankerMAil")
    // axios
    //   .get(
    //     `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${bankerEmail}`
    //   )
    //   .then(({ data }) => {
    //     console.log("selected-app", selectedAppData)
    //     if (data.status) {
    //       let thedata
    //       if (selectedAppData) {
    //         thedata = selectedAppData
    //       } else {
    //         thedata = data.paths[0].token_profile_data.coinSymbol
    //       }
          axios
            .get(
              `https://comms.globalxchange.io/coin/investment/path/get?token=${JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}&getTokenHolders=true`
            )
            .then((res) => {
              setAllShareHolders(res.data.tokenHolders);
              // console.log("kwhedkhjwbefwe", res.data);
              setLoading(false);
            });
      //   } else {
      //     setLoading(false);
      //   }
      // });
  }, [localStorage.getItem("shareToken"), shareToken, selectedAppData , selectedShareToken ]);

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 120,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Name</div>
        <div style={{ textAlign: "left" }}>Affiliate</div>
        <div>Type</div>
        <div>Date</div>
        <div>Shares</div>
        <div>Trading Volume</div>
        <div>Current Volume</div>
      </div>
    );
  };

  const contentSection = (item, gridClass, gridValues) => {
    return (
      <div
        // onClick={(e) => {
        //   setBondSelected(bond);
        //   setShowSubDraw(true);
        // }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
        onClick={() => (setShowSubDraw(true), setHolderData(item) , setStep("default"))}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              border: "solid 0.5px #e7e7e7",
              borderRadius: "50%",
            }}
          >
            <img
              src={item.profile_img ? item.profile_img : defaultImg}
              alt=""
              style={{
                borderRadius: "50%",
                width: "30px",
              }}
            />
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{item.name}</div>
            <div className="subtitle">{item.email}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              border: "solid 0.5px #e7e7e7",
              borderRadius: "50%",
            }}
          >
            <img
              src={
                item.uplineData.profile_img
                  ? item.uplineData.profile_img
                  : defaultImg
              }
              alt=""
              style={{
                borderRadius: "50%",
                width: "30px",
                height: "30px",
              }}
            />
          </div>

          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{item.uplineData.name}</div>
            <div className="subtitle">{item.uplineData.email}</div>
          </div>
        </div>
        <div>
          <div className="title">--</div>
          <div className="subtitle">{item.countryData?.name}</div>
        </div>
        <div>
          <div className="title">
            {new Date(item?.txnsData?.firstTxnDate)
              .toUTCString()
              .substring(0, 11)}
          </div>
          {item.txnsData ? (
            <div className="subtitle">
              {item?.txnsData?.firstTxnDate.slice(-12)}
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="title">{item.token_balance}</div>
          <div className="subtitle">
            {item.percentage_of_tokenTotalSupply.toFixed(3)}
          </div>
        </div>
        <div>
          <div className="title">{item?.txnsData?.tradingVolume}</div>
          <div className="subtitle">{item?.txnsData?.txnsCount}Transaction</div>
        </div>
        <div>
          <div className="title">
            {item.token_balance_value_in_asset.toFixed(2)}
          </div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div
              className="user"
              key={i}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Skeleton
                className="dp"
                circle
                width={40}
                height={40}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={120} />
                <Skeleton width={80} style={{ height: "10px" }} />
              </div>
            </div>
            <div
              className="user"
              key={i + 1}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Skeleton
                className="dp"
                circle
                width={40}
                height={40}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={120} />
                <Skeleton width={80} style={{ height: "10px" }} />
              </div>
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        allShareHolders,
        loading,
        "2.5fr 2.5fr 1.5fr 1.5fr 1fr 1.5fr 1.5fr", // Desktop view Grid columns
        "300px 300px 200px 200px 200px 200px 200px" // Mobile view Grid columns
      )}
      <CRMdrawer step = {step} setStep = {setStep}/>
      {/* <OfferingSubDrawer allBonds={allBonds} currenciesData={currenciesData} /> */}
    </>
  );
};

export default CapitalizedCRM;
