import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function EquityList({ setEquity, onClose }) {
  const [allEquity, setAllEquity] = useState([]);

  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/vault/countries/data/get")
      .then((res) => {
        if (true)
          setAllEquity([
            "Pre-Seed",
            "Seed",
            "Series A",
            "Series B",
            "Series C",
          ]);
      })
      .finally(() => setAssetLoading(false));
  }, []);

  const [assetLoading, setAssetLoading] = useState(true);

  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select The Equity Round</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Assets....|"
        />
      </div>
      <Scrollbars className="searchList">
        {assetLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : allEquity
              .filter((item) =>
                item?.toLowerCase().includes(search.toLowerCase())
              )
              .map((item) => (
                <div
                  className="user"
                  key={item._id}
                  onClick={() => {
                    setEquity(item);
                    onClose();
                  }}
                >
                  <div className="userDetail">
                    <div className="name">{item}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default EquityList;
