import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";

import Offerings from "./Offerings";
import SoldOfferings from "./SoldOfferings";
import Tiers from "./Tiers";

const BankerBonds = () => {
  const tabs = ["Offerings", "Sold Offerings", "Tiers"];

  const { setShowSubDraw, tabSelected, setTabSelected } =
    useContext(GlobalContex);

  useEffect(() => {
    setTabSelected("Offerings");
  }, []);

  // const [tabSelected, setTabSelected] = useState("Offerings");

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "Offerings":
        return <Offerings />;
      case "Sold Offerings":
        return <SoldOfferings />;
      case "Tiers":
        return <Tiers />;

      default:
        return null;
    }
  }, [
    tabSelected,
    // openCoinFilter, refetchApi
  ]);

  return (
    <div>
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false]}
      />

      {tabComponent}

      {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
      {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >

        <Subdrawer />
      </div> */}
    </div>
  );
};

export default BankerBonds;
