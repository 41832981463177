import React from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";
import TabsLayout from "../../globalComponents/TabsLayout";

import crm from "../../static/images/sidebarIcons/crm.svg";
import dash from "../../static/images/sidebarIcons/dash.svg";
import GXTicon from "../../static/images/sidebarIcons/GXTicon.svg";
import affiliates from "../../static/images/sidebarIcons/affiliates.svg";
import vaults from "../../static/images/sidebarIcons/vaults.svg";
import terminal from "../../static/images/sidebarIcons/terminal.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";
import loans from "../../static/images/sidebarIcons/socially.svg";
import Lock from "../../static/images/icons/lock.svg";
import defaultImg from "../../static/images/icons/app_placeholder.png";
import VaultPage from "../../globalComponents/VaultPage";
import MarketsverseDashboard from "./MarketsverseDashboard";
import MarketsverseGXT from "./MarketsverseGXT";
import MarketsverseUsage from "./MarketsverseUsage";
import MarketsverseCRM from "./MarketsverseCRM";
import MarketsverseCredibility from "./MarketsverseCredibility";

import MobileMenu from "../../globalComponents/MobileMenu";

const MarketsVerse = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    localMenu,
    globalMenu,
    showMobileMenu,
    licenseCheck
  } = useContext(GlobalContex);

  const marketsVerseMenu = [
    {
      menuName: "Usage",
      menuIcon: dash,
      enabled: true,
    },
    {
      menuName: "CRM",
      menuIcon: dash,
      enabled: true,
    },
    {
      menuName: "Credibility",
      menuIcon: dash,
      enabled: true,
    }
  ];

  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      case "Usage":
        return <MarketsverseUsage />;
      case "CRM":
        return <MarketsverseCRM />;
      case "Credibility":
        return <MarketsverseCredibility />;
      // case "Dashboard":
      //   return <MarketsverseDashboard />;
      // case "GXT Marketplace":
      //   return <MarketsverseGXT />
      // case "Vaults":
      //   return <VaultPage />;
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={marketsVerseMenu} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
            {/* <div className="filterRow"></div> */}
            {/* <div style={{ flexGrow: 1 }}>{conditionalPages()}</div> */}
          </div>
        </div>
      </div>

      {showMobileMenu ? (
        <MobileMenu localMenu={marketsVerseMenu} />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}

      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default MarketsVerse;
