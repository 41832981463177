import React, { useMemo, useState, useEffect, useContext } from "react";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "./affiliate.scss";
import CapitalizedCompensation from "./CapitalizedCompensation";
import CapitalizedDistribution from "./CapitalizedDistribution";


const Affiliate = () => {
    const tabs = [
        "Compensation",
        "Comp Distribution"
    ]

    const { setShowSubDraw } = useContext(GlobalContex);

    const [tabSelected, setTabSelected] = useState("Compensation");


    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "Compensation":
                return <CapitalizedCompensation />;
            case "Comp Distribution":
                return <CapitalizedDistribution/>
            default:
                return null;
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);





    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false]}
            />

            {tabComponent}
        </div>
    )
}

export default Affiliate