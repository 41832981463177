import React, { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import Payment from "./Payment"
import Banks from "./Banks"
const MarketsverseDashboard = () => {
    const tabs = ["Payment Methods","Banks"];

    const { setShowSubDraw, selectedTab } = useContext(GlobalContex);

    const [tabSelected, setTabSelected] = useState("Payment Methods");
    //   tabSelected
    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "Payment Methods":
                return (
             <Payment/>
                )
       
                case "Banks":
                    return (
                 <Banks/>
                    )
           
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Coming Soon
                    </div>
                );
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);

    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false]}
            />

            {tabComponent}
        </div>
    );
};

export default MarketsverseDashboard;
