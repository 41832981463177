import React, { useEffect,useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
// import Clients from "./Clients";
// import EarningsPerCustomer from "./EarningsPerCustomer";
import MarketsverseBankers from "./MarketsverseBankers";
import DashboardApps from "../../MyApps/McbDashboard/DashboardApps";
import DashboardBrands from "../../MyApps/McbDashboard/DashboardBrands";
import DashboardFieldGroups from "../../MyApps/McbDashboard/DashboardFieldGroups";
import DashboardGroupFields from "../../MyApps/McbDashboard/DashboardGroupFields";

const MarketsverseDashboard = () => {
  const tabs = ["Apps", "Brands", "Banker", "LX", "Global Field Groups", "Global Group Fields"];

  const { setShowSubDraw, tabSelected, setTabSelected } = useContext(GlobalContex);

  // const [tabSelected, setTabSelected] = useState("Apps");

  useEffect(() => {
    setTabSelected("Apps");
  }, []);

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "Apps":
        return <DashboardApps />;
      case "Brands":
        return <DashboardBrands />;
      case "Banker":
        return <MarketsverseBankers />;
      case "Global Field Groups":
        return <DashboardFieldGroups />;
      case "Global Group Fields":
        return <DashboardGroupFields />;
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
    }
  }, [
    tabSelected,
    // openCoinFilter, refetchApi
  ]);

  return (
    <div>
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false]}
      />

      {tabComponent}
    </div>
  );
};

export default MarketsverseDashboard;
