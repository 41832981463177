import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./compensation.scss";
import { GlobalContex } from "../../../../globalContex";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import Skeleton from "react-loading-skeleton";
import CompensationDrawer from "./CompensationSubDrawer";

const CapitalizedCompensation = () => {
    const [dataLoading, setDataLoading] = useState(false);
    const [step, setStep] = useState("")
    const { selectedShareToken, setSelectedShareToken, setShowSubDraw, compData, setCompData, DistributionData, setDistributionData, compLoader,
        setCompLoader } = useContext(GlobalContex)
    const [keysData, setKeysData] = useState([{ name: "Total Commission" }])
    const [keysDistribData, setKeysDistribData] = useState([{ name: "Direct Affiliate" }, { name: "NetworkChain" }, { name: "Direct Executive" }, { name: "Executive NetworkChain" }])
    const [comissionPathId, setComissionPathId] = useState("")
    const [compensationData, setCompensationData] = useState([])


    


    useEffect(() => {
        console.log("compens ", selectedShareToken)
        setDataLoading(true)
        // axios
        //     .get(
        //         `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
        //             "bankerEmailNew"
        //         )}`
        //     )
        //     .then((res) => {
        //         // setDataLoading(false)
        //         if (res.data.status) {
        //             let coinsymbol
        //             if (selectedShareToken) {
        //                 coinsymbol = selectedShareToken?.token_profile_data.coinSymbol
        //             } else {
        //                 coinsymbol = res.data.paths[0].token_profile_data.coinSymbol
        //             }
        axios
            .get(
                `https://comms.globalxchange.io/coin/investment/path/get?token=${JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}`
            )
            .then((res1) => {
                // console.log("compens ", res1.data.paths.length)
                setDataLoading(false)
                setCompLoader(false)
                if (res1.data.status) {
                    console.log("The-data , ", res1.data.paths)
                    let thedata = [res1.data.paths[0].commission_fee_percentage]
                    // let thevalues = Object.values(res1.data.paths[0]?.commission_distribution)
                    // console.log(thevalues, "datttt")
                    setComissionPathId(res1.data.paths[0].path_id)
                    let finaldata = []
                    for (let i = 0; i < 1; i++) {
                        finaldata.push({ name: keysData[i]?.name, percentage: thedata[i] })
                    }

                    // let finaldistribdata = []
                    // for (let i = 0; i < 4; i++) {
                    //     finaldistribdata.push({ name: keysDistribData[i]?.name, percentage: thevalues[i] })
                    // }
                    console.log("final-datt ", finaldata)
                    setCompensationData(finaldata)
                    // setDistributionData(finaldistribdata)
                } else {
                    setDataLoading(false)
                    setCompensationData([])
                    // setDistributionData([])
                }
            });
        //     } else {
        //         setDataLoading(false)
        //         setCompensationData([])
        //         // setDistributionData([])
        //     }
        // })
    }, [selectedShareToken , compLoader])

    const loadingSection = (gridClass) => {
        return Array(1)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: "1.5fr 1.5fr 0.1fr",
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}>
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail" style={{ paddingTop: "10px" }}>
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                    </div>
                )
            })

    }

    // const contentSection = (bond, gridClass) => {
    //     return (
    //         <div className={gridClass}
    //             style={{
    //                 gridTemplateColumns: "1.5fr 1.5fr 0.1fr",
    //             }}>
    //             <div style={{ display: "flex", alignItems: "center" }}>

    //                 <div style={{ paddingLeft: "15px" }}>
    //                     <div className="title">
    //                         {bond?.userDetail.name}
    //                     </div>
    //                 </div>
    //             </div>
    //             <div style={{ display: "flex", alignItems: "flex-end", textAlign: "left" }}>
    //                 <div className="title">
    //                     ${bond.total_dividents_received_USD.toFixed(10)}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <div className="desktopWrapper">
                <div style={{ width: "100%" }}>
                    <div className="listGrid" style={{ gridTemplateColumns: "1.5fr 1.5fr 0.1fr" }}>
                        <div>Name</div>
                        <div>Percentage</div>
                    </div>
                </div>
                <div
                    style={{
                        // display: "flex",
                        fontWeight: 700,
                        fontSize: "20px",
                        height: window.innerHeight - 175,
                        overflowY: "scroll",
                    }}
                >
                    {!dataLoading ? (
                        compensationData?.length > 0 ? (
                            compensationData.map((comp) => {
                                return (
                                    <div className="listDataGrid"
                                        style={{
                                            gridTemplateColumns: "1.5fr 1.5fr 0.1fr",
                                        }} onClick={() => (setShowSubDraw(true), setStep("default"), setCompData(comp))}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <div style={{ paddingLeft: "15px" }}>
                                                <div className="title">
                                                    {comp.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "flex-end", textAlign: "left" }}>
                                            <div className="title">
                                                {comp.percentage.toFixed(2)}%
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "60vh",
                                }}
                            >
                                No Records Found
                            </div>
                        )
                    ) : (
                        loadingSection("listDataGrid")
                    )}
                    {/* <AppsSubDrawer allApps={allApps} /> */}
                </div>
                <CompensationDrawer step={step} setStep={setStep} comissionPathId={comissionPathId} />
            </div>
        </>
    )
}

export default CapitalizedCompensation