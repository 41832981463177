import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";

const MainnetCoins = () => {
  const { bankerEmail, collapse, FormatCurrency, FormatNumber,searchTerm} =
    useContext(GlobalContex);
  const [bondSelected, setBondSelected] = useState();
  const [currenciesData, setCurrenciesData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [allSoldOfferings, setAllSoldOfferings] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [allCoins, setAllCoin] = useState([]);
  const [coinsData, setCoinsData] = useState([]);

  useEffect(() => {
    setDataLoading(true);

    axios
      .get("https://comms.globalxchange.io/coin/iced/banker/custom/bond/get")
      .then((res) => {
        setAllCoin(res.data.currenciesData);
      });
    axios
      .get("https://defi.apimachine.com/supplyborrowrates/mlsuggestion/smart/protocolcoin?network=mainnet")
      .then((res) => {
        setCoinsData(res.data.sortedData);
      });

    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/stats?bond_creator=${bankerEmail}&displayCurrency=USD`
      )
      .then((res) => {
        console.log(res.data, "kewnckwenc");
        setAllSoldOfferings(res.data.bonds);
        setDataLoading(false);
        // setCurrenciesData(res.data.currenciesData);
      });
  }, [bankerEmail, selectedCoin]);
  const filteredCoins = coinsData.filter((coin) => {
    return coin.coin.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>CoinsCombo</div>
        <div>Supply (APY)</div>
        <div>Borrow (APY)</div>
        <div>Collateral Status</div>
        <div>Collateral Factor</div>
        <div>Platform</div>
        <div>Network</div>
      </div>
    );
  };

  const contentSection = (bond, gridClass, gridValues) => {
    return (
      <div
        // onClick={(e) => {
        //   setSelectedMcbDashboardApp(item);
        //   setShowSubDraw(true);
        // }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">
              {/* {bond.coinsData[0].coin} */}
              {bond.respectiveCoin}&nbsp;
            </div>
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.coinSupply, 2)}
            %&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.coinBorrow, 2)}
            %&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">{bond.collateralStatus?"yes":"No"}&nbsp;</div>
        </div>
        <div>
          <div className="title">
            {FormatNumber(bond?.collateralFactor, 2)}%&nbsp;&nbsp;
          </div>
        </div>
        <div>
          <div className="title">{bond.platform}&nbsp;</div>
        </div>
        <div>
          <div className="title">{bond.network}&nbsp;</div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        // allSoldOfferings,
        filteredCoins,
        dataLoading,
        "1fr 1fr 1fr 1fr 1fr 1fr 1fr", // Desktop view Grid columns
        "200px 200px 200px 200px 200px 200px 200px" // Mobile view Grid columns
      )}
    </>
  );
};

export default MainnetCoins;
