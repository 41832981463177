import React, { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
// import Clients from "./Clients";
// import EarningsPerCustomer from "./EarningsPerCustomer";
import Users from "./Users";
import Founders from "./Founders";
import Apps from "./Apps";
import Businesses from "./Businesses";

const MarketsverseUsage = () => {
    const tabs = ["Users", "Founders", "Apps", "Businesses"];

    const { setShowSubDraw } = useContext(GlobalContex);

    const [tabSelected, setTabSelected] = useState("Users");

    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "Users":
                return <Users />;
            case "Founders":
                return <Founders />;
            case "Apps":
                return <Apps />;
            case "Businesses":
                return <Businesses />;
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Coming Soon
                    </div>
                );
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);

    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false]}
            />

            {tabComponent}
        </div>
    );
};

export default MarketsverseUsage;
