import React, { useContext, useState } from "react";
// import "./subdrawer.scss";
import "../../../../../static/scss/subdrawer.scss";
import { GlobalContex } from "../../../../../globalContex";

import About from "./About";
import Actions from "./Actions";

function OfferingSubDrawer({ allBonds, currenciesData }) {
  // console.log("subdrawer " + tabSelected);
  const { showSubDraw, setShowSubDraw } = useContext(GlobalContex);

  const [selectedMenu, setSelectedMenu] = useState("About");

  const conditionalData = () => {
    switch (selectedMenu) {
      case "About":
        return <About allBonds={allBonds} currenciesData={currenciesData} />;

      case "Actions":
        return <Actions allBonds={allBonds} currenciesData={currenciesData} />;

      default:
        break;
    }
  };

  return (
    <div
      className={showSubDraw ? "right-drawer-visible" : "right-drawer-hidden"}
      style={{ height: window.innerHeight - 123 }}
    >
      <div className="navs-disp">
        <div
          onClick={(e) => setSelectedMenu("About")}
          className={
            selectedMenu === "About" ? "navs-data active-tab" : "navs-data"
          }
        >
          <div>About</div>
        </div>
        <div
          onClick={(e) => setSelectedMenu("Actions")}
          className={
            selectedMenu === "Actions" ? "navs-data active-tab" : "navs-data"
          }
        >
          <div>Actions</div>
        </div>
      </div>
      {/* {thedata} */}
      {conditionalData()}
    </div>
  );
}

export default OfferingSubDrawer;
