import React, { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import Clients from "./Clients";
import EarningsPerCustomer from "./EarningsPerCustomer";
import BondContracts from "./BondContracts";

const BankerCRM = () => {
  const tabs = ["Clients", "Earnings Per Customer","Bond Contracts"];

  const { setShowSubDraw } = useContext(GlobalContex);

  const [tabSelected, setTabSelected] = useState("Clients");

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "Clients":
        return <Clients />;
      //   case "Expring Bonds":
      //     return <SoldOfferings />;
      //   case "Leads":
      //     return <Tiers />;
      case "Earnings Per Customer":
        return <EarningsPerCustomer />;
      case "Bond Contracts":
        return <BondContracts/>
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
    }
  }, [
    tabSelected,
    // openCoinFilter, refetchApi
  ]);

  return (
    <div>
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false]}
      />

      {tabComponent}

      {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
      {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >

        <Subdrawer />
      </div> */}
    </div>
  );
};

export default BankerCRM;
