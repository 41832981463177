import axios from "axios";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../globalContex";

function BankerList({ setBanker, onClose }) {
  const [allBankers, setAllBanker] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios.get(`https://teller2.apimachine.com/admin/allBankers`).then((res) => {
      setAllBanker(res.data.data);
    });
  }, []);

  return (
    <Fragment>
      <div className="titleOp">Select Banker</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bankers....|"
        />
      </div>
      <Scrollbars className="searchList">
        {loading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : allBankers
              ?.filter(
                (item) =>
                  item.displayName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.email?.toLowerCase().includes(search.toLowerCase())
              )
              .map((banker) => (
                <div
                  className="user"
                  key={banker._id}
                  onClick={() => {
                    setBanker(banker);
                    onClose();
                  }}
                >
                  <img className="dp" src={banker.profilePicURL} alt="" />
                  <div className="userDetail">
                    <div className="name">{banker.displayName}</div>
                    <div className="email">{banker.email}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default BankerList;
