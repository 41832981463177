import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import TiersSubDrawer from "./TiersSubDrawer";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";

const Tiers = () => {
  const {
    bankerEmail,
    collapse,
    FormatCurrency,
    FormatNumber,
    setShowSubDraw,
    refetchTiers,
  } = useContext(GlobalContex);
  const [bondSelected, setBondSelected] = useState();
  const [currenciesData, setCurrenciesData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [allBondTiers, setAllBondTiers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [allCoins, setAllCoin] = useState([]);

  useEffect(() => {
    setDataLoading(true);

    axios
      .get("https://comms.globalxchange.io/coin/iced/banker/custom/bond/get")
      .then((res) => {
        setAllCoin(res.data.currenciesData);
      });

    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/get?email=${bankerEmail}`
      )
      .then((res) => {
        console.log(res.data, "kewnckwenc");
        setAllBondTiers(res.data.data);
        setDataLoading(false);
        // setCurrenciesData(res.data.currenciesData);
      });
  }, [bankerEmail, selectedCoin, refetchTiers]);

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <TiersSubDrawer
              allBonds={allBondTiers}
              currenciesData={currenciesData}
              bondSelected={bondSelected}
            /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Name</div>
        <div style={{ textAlign: "left" }}>App</div>
        <div style={{ textAlign: "left" }}>Banker</div>
        <div style={{ textAlign: "center" }}>Tier Type</div>
        <div style={{ textAlign: "center" }}>Bonds</div>
        <div style={{ textAlign: "center" }}>Users</div>
      </div>
    );
  };

  const contentSection = (bond, gridClass, gridValues) => {
    return (
      <div
        onClick={(e) => {
          setBondSelected(bond);
          setShowSubDraw(true);
        }}
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={bond.icon}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
            // className={classNames.icon}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{bond.name}</div>
            <div className="subtitle">{bond?.list_id}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            src={bond?.appData?.app_icon}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{bond?.appData?.app_name}</div>
            <div className="subtitle">{bond.app_code}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            src={bond?.bankerData?.profilePicURL}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{bond?.bankerData?.displayName}</div>
            <div className="subtitle">{bond?.bankerData?.bankerTag}</div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div className="title">{bond.listType}</div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="title">
            {FormatNumber(bond?.connected_bond_templates_count)}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="title">{FormatNumber(bond?.users_count)}</div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail">
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        allBondTiers,
        dataLoading,
        "2fr 1.5fr 2fr 1fr 1fr 1fr", // Desktop view Grid columns
        "200px 250px 200px 150px 150px 150px" // Mobile view Grid columns
      )}
      <TiersSubDrawer
        allBonds={allBondTiers}
        currenciesData={currenciesData}
        bondSelected={bondSelected}
      />
    </>
  );
};

export default Tiers;
