import axios from "axios";
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import "./borrowingStats.scss";
import Skeleton from "react-loading-skeleton";
// import CoinFilter from "../DashboardAll/CoinFilter";
// import { MyContext } from "../../myContext";
import { GlobalContex } from "../../../../globalContex";

const BorrowingStats = () => {
  const {
    allCoins,
    setAllCoins,
    openCoinFilter,
    setOpenCoinFilter,
    selectedCoin,
    setSelectedCoin,
    selectedFilter2,
    setSelectedFilter2,
    refetchApi,
    setRefetchApi,
    collapse,
  } = useContext(GlobalContex);
  const [allBondStats, setAllBondStats] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/stats?bond_creator=matthew.snively@yahoo.com&displayCurrency=USD`
      )
      .then((res) => {
        setAllBondStats(res.data);
      });
  }, []);

  const skl = (
    <div className="cardContainer">
      {Array(6)
        .fill("")
        .map((item) => {
          return (
            <div className="card" style={{}}>
              <div style={{}}>
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
          );
        })}
    </div>
  );

  const cardInfo = [
    "Customers",
    "Apps",
    "Executives",
    "Affiliates",
    "Currencies",
    "Offerings",
    "Contracts",
    "Bonds",
    "Completed Interest Payments",
    "Payable Interest Payments",
  ];

  const cardData = () => {
    return (
      <div
        className="cardContainer"
        style={{
          width:
            window.innerWidth > 600
              ? window.innerWidth - 365
              : window.innerWidth,
        }}
      >
        {cardInfo.map((item) => {
          return (
            <div className="card">
              <div className="title">--</div>
              <div className="subtitle">{item}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ overflowY: "scroll", height: window.innerHeight - 135 }}>
      {allBondStats.totalData ? (
        <div
          className="container1"
          style={
            {
              // paddingBottom: "20vh",
            }
          }
        >
          {/* Cumulative */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>All</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Cumulative)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
          </div>
          {/* Active */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>Active</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Current)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
            {/* <div className="cardContainer">
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Bond Offerings</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Customers</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.outstanding_bond_units}
            </div>
            <div className="subtitle">Bond Contracts</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.outstanding_bonds}
            </div>
            <div className="subtitle">Bonds</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.outstanding_bonds_investment_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Investment Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.outstanding_bonds_redemptionAmount_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Redemption Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.outstanding_bonds_remaining_earnings.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Payable Interest</div>
          </div>

          <div className="card">
            <div className="title">
              ${allBondStats?.totalData?.outstanding_bonds_voc.toFixed(2)}
            </div>
            <div className="subtitle">VOC</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.total_interest_payments_count}
            </div>
            <div className="subtitle">Interest Payments #</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.total_interest_payments_count}
            </div>
            <div className="subtitle">Interest Payments Value</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.total_interest_payments_count}
            </div>
            <div className="subtitle">Interest Payments Value</div>
          </div>
        </div> */}
          </div>
          {/* Expired */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>Expired</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Current)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
            {/* <div className="cardContainer">
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Bond Offerings</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Customers</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bond_units}
            </div>
            <div className="subtitle">Bond Contracts</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds}
            </div>
            <div className="subtitle">Bonds</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_investment_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Investment Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_redemptionAmount_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Redemption Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_remainings_earnings.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Payable Interest</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">VOC</div>
          </div>
        </div> */}
          </div>
          {/* Redeemed */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>Redeemed</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Cumulative)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
            {/* <div className="cardContainer">
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Bond Offerings</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Customers</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.redeemed_bond_units}
            </div>
            <div className="subtitle">Bond Contracts</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.redeemed_bonds}
            </div>
            <div className="subtitle">Bonds</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.redeemed_bonds_investment_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Investment Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.redeemed_bonds_redemptionAmount_value?.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Redemption Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.redeemed_bonds_remaining_earnings?.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Payable Interest</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">VOC</div>
          </div>
        </div> */}
          </div>
          {/* Pure Redeemed */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>Pure Redeemed</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Cumulative)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
            {/* <div className="cardContainer">
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.redeemed_bond_units}
            </div>
            <div className="subtitle">Contracts Sold</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Customers</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bond_units}
            </div>
            <div className="subtitle">Bond Contracts</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds}
            </div>
            <div className="subtitle">Bonds</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_investment_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Investment Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_redemptionAmount_value.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Redemption Value</div>
          </div>
          <div className="card">
            <div className="title">
              $
              {allBondStats?.totalData?.expired_bonds_remainings_earnings.toFixed(
                2
              )}
            </div>
            <div className="subtitle">Payable Interest</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">VOC</div>
          </div>
        </div> */}
          </div>
          {/* Renewed */}
          <div>
            <div className="headers">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "30px",
                }}
              >
                <div>Renewed</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                  }}
                >
                  (Cumulative)
                </div>
              </div>
              <div className="buttonSelected">Quantity</div>
              <div className="buttonNotSelected">Volume</div>
              <div className="buttonNotSelected">Avg Per Customer</div>
            </div>
            {cardData()}
            {/* <div className="cardContainer">
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Bond Offerings</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">Customers</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bond_units}
            </div>
            <div className="subtitle">Bond Contracts</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds}
            </div>
            <div className="subtitle">Bonds</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds_investment_value.toFixed(
                4
              )}
            </div>
            <div className="subtitle">Investment Value</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds_redemptionAmount_value.toFixed(
                4
              )}
            </div>
            <div className="subtitle">Redemption Value</div>
          </div>
          <div className="card">
            <div className="title">
              {allBondStats?.totalData?.expired_bonds_remainings_earnings.toFixed(
                4
              )}
            </div>
            <div className="subtitle">Payable Interest</div>
          </div>
          <div className="card" style={{ opacity: 0.5 }}>
            <div className="title">--</div>
            <div className="subtitle">VOC</div>
          </div>
        </div> */}
          </div>
        </div>
      ) : (
        <>
          <div className="container1">
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>All</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Cumulative)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>
              {skl}
            </div>
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>Active</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Current)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>
              {skl}
            </div>
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>Expired</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Current)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>
              {skl}
            </div>
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>Redeemed</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Cumulative)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>
              {skl}
            </div>
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>Pure Redeemed</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Cumulative)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>
              {skl}
            </div>
            <div>
              <div className="headers">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "30px",
                  }}
                >
                  <div>Renewed</div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      paddingLeft: "5px",
                    }}
                  >
                    (Cumulative)
                  </div>
                </div>
                <div className="buttonSelected">Quantity</div>
                <div className="buttonNotSelected">Volume</div>
                <div className="buttonNotSelected">Avg Per Customer</div>
              </div>

              {skl}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BorrowingStats;
