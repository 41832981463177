import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../../static/images/icons/app_placeholder.png";

function PartnerInstituteList({
  partnerInstitution,
  setPartnerInstitution,
  onClose,
}) {
  const [allInstitute, setAllInstitute] = useState([
    {
      _id: "5f059f131ad75b4cad91650a",
      short_name: "Use Default",
      profile_image: defaultImg,
    },
  ]);
  const [assetLoading, setAssetLoading] = useState(false);
  // useEffect(() => {
  //   Axios.get("https://accountingtool.apimachine.com/getlist-of-institutes")
  //     .then((res) => {
  //       if (res.data.status) {
  //         setAllInstitute(res.data.data);
  //       }
  //     })
  //     .finally(() => setAssetLoading(false));
  // }, []);
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select Partnering Institution</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Institution....|"
        />
      </div>
      <Scrollbars className="searchList">
        {assetLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : allInstitute
              .filter(
                (item) =>
                  item.short_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.institute_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((item) => (
                <div
                  className="user"
                  key={item._id}
                  onClick={() => {
                    if (partnerInstitution.length > 0) {
                      if (partnerInstitution.find((o) => o._id !== item._id)) {
                        setPartnerInstitution([...partnerInstitution, item]);
                        onClose();
                      } else {
                        onClose();
                      }
                    } else {
                      setPartnerInstitution([...partnerInstitution, item]);
                      onClose();
                    }
                  }}
                >
                  <img className="dp" src={item?.profile_image} alt="" />
                  <div className="userDetail">
                    <div className="name">{item?.short_name}</div>
                    <div className="email">{item?._id}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default PartnerInstituteList;
