import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";

const Clients = () => {
  const { bankerEmail, collapse } = useContext(GlobalContex);

  const [allClients, setAllClients] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/users/data/get?email=${bankerEmail}`
      )
      .then((res) => {
        setAllClients(res.data.users);
        // console.log("kwhedkhjwbefwe", res.data);
        setDataLoading(false);
      });
  }, [bankerEmail]);

  const conditionalResposiveView = (
    data,
    dataLoading,
    desktopDataGrid,
    mobileDataGrid,
    showSubDraw
  ) => {
    return (
      <>
        <div className="desktopWrapper">
          <div style={{ width: "100%" }}>
            {headerSection("listGrid", desktopDataGrid)}
          </div>
          <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              data?.length > 0 ? (
                data.map((bond) => {
                  return contentSection(bond, "listDataGrid", desktopDataGrid);
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", desktopDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>

        <div className="mobileWrapper">
          <div style={{ overflowY: "scroll", height: "80vh" }}>
            {headerSection("listGridMobile", mobileDataGrid)}

            {!dataLoading ? (
              data?.length > 0 ? (
                data?.map((bond, index) => {
                  return contentSection(
                    bond,
                    "listDataGridMobile",
                    mobileDataGrid
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              loadingSection("listDataGrid", mobileDataGrid)
            )}
            {/* <AppsSubDrawer allApps={allApps} /> */}
          </div>
        </div>
      </>
    );
  };

  // Change these three Sections according to the design

  const headerSection = (gridClass, gridValues) => {
    return (
      <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
        <div>Name</div>
        <div>Total Bonds</div>
        <div>Active</div>
        <div>Completed</div>
        <div>Redeemed</div>
        <div>Cancelled</div>
      </div>
    );
  };

  const contentSection = (item, gridClass, gridValues) => {
    return (
      <div
        className={gridClass}
        style={{
          gridTemplateColumns: gridValues,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={item.profile_img ? item.profile_img : defaultImg}
            alt=""
            style={{
              borderRadius: "50%",
              width: "30px",
            }}
            // className={classNames.icon}
          />
          <div style={{ paddingLeft: "15px" }}>
            <div className="title">{item.name} &nbsp;</div>
            <div className="subtitle">{item?.email}</div>
          </div>
        </div>

        <div>
          <div className="title">{item.total_bondsCount}</div>
          <div className="subtitle">{item.total_bondTxnsCount}</div>
        </div>

        <div>
          <div className="title">{item.active.bondsCount}</div>
          <div className="subtitle">{item.active.bondTxnsCount}</div>
        </div>
        <div>
          <div className="title">{item.completed.bondsCount}</div>
          <div className="subtitle">{item.completed.bondTxnsCount}</div>
        </div>
        <div>
          <div className="title">{item.redeemed.bondsCount}</div>
          <div className="subtitle">{item.redeemed.bondTxnsCount}</div>
        </div>
        <div>
          <div className="title">{item.cancelled.bondsCount}</div>
          <div className="subtitle">{item.cancelled.bondTxnsCount}</div>
        </div>
      </div>
    );
  };

  const loadingSection = (gridClass, gridValues) => {
    return Array(10)
      .fill("")
      .map((item, i) => {
        return (
          <div
            className={gridClass}
            style={{
              width: "100%",
              gridTemplateColumns: gridValues,
              // borderBottom: "solid 0.5px #EEEEEE",
            }}
          >
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                className="dp"
                circle
                width={50}
                height={50}
                style={{ marginRight: "20px" }}
              />
              <div className="userDetail" style={{ paddingTop: "10px" }}>
                <Skeleton width={100} />
                <Skeleton width={120} style={{ height: "10px" }} />
              </div>
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
            <div>
              <Skeleton width={120} />
              <Skeleton width={80} style={{ height: "10px" }} />
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {conditionalResposiveView(
        allClients,
        dataLoading,
        "2fr 1fr 1fr 1fr 1fr 1fr", // Desktop view Grid columns
        "300px 200px 200px 200px 200px 200px" // Mobile view Grid columns
      )}
      <div>
        <div style={{ width: "100%" }}>
          <div
            className="listGrid"
            style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr" }}
          >
            <div>Name</div>
            <div>Total Bonds</div>
            <div>Active</div>
            <div>Completed</div>
            <div>Redeemed</div>
            <div>Cancelled</div>
          </div>
          <div
            style={{
              fontWeight: 700,
              fontSize: "20px",
              height: window.innerHeight - 175,
              overflowY: "scroll",
            }}
          >
            {!dataLoading ? (
              allClients ? (
                allClients.map((item) => {
                  return (
                    <div
                      className="listDataGrid"
                      style={{
                        gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={item.profile_img ? item.profile_img : defaultImg}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "30px",
                          }}
                          // className={classNames.icon}
                        />
                        <div style={{ paddingLeft: "15px" }}>
                          <div className="title">{item.name} &nbsp;</div>
                          <div className="subtitle">{item?.email}</div>
                        </div>
                      </div>

                      <div>
                        <div className="title">{item.total_bondsCount}</div>
                        <div className="subtitle">
                          {item.total_bondTxnsCount}
                        </div>
                      </div>

                      <div>
                        <div className="title">{item.active.bondsCount}</div>
                        <div className="subtitle">
                          {item.active.bondTxnsCount}
                        </div>
                      </div>
                      <div>
                        <div className="title">{item.completed.bondsCount}</div>
                        <div className="subtitle">
                          {item.completed.bondTxnsCount}
                        </div>
                      </div>
                      <div>
                        <div className="title">{item.redeemed.bondsCount}</div>
                        <div className="subtitle">
                          {item.redeemed.bondTxnsCount}
                        </div>
                      </div>
                      <div>
                        <div className="title">{item.cancelled.bondsCount}</div>
                        <div className="subtitle">
                          {item.cancelled.bondTxnsCount}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  No Records Found
                </div>
              )
            ) : (
              Array(10)
                .fill("")
                .map((item, i) => {
                  return (
                    <div
                      className="listDataGrid post"
                      style={{
                        width: "100%",
                        // height: "120px",
                        gridTemplateColumns: "1.5fr 1fr 1.2fr 1fr 1fr 1fr 1fr",
                        // borderBottom: "solid 0.5px #EEEEEE",
                      }}
                    >
                      <div
                        key={i}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Skeleton
                          className="dp"
                          circle
                          width={50}
                          height={50}
                          style={{ marginRight: "20px" }}
                        />
                        <div className="userDetail">
                          <Skeleton width={100} />
                          <Skeleton width={120} style={{ height: "10px" }} />
                        </div>
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                      <div>
                        <Skeleton width={120} />
                        <Skeleton width={80} style={{ height: "10px" }} />
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
        {/* {bondSelected ? (
          <BondListSidebar
            bondTiersSelected={bondSelected}
            onClose={() => setBondSelected()}
            getBondList={getBondList}
            data={data}
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default Clients;
