import React from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";
import TabsLayout from "../../globalComponents/TabsLayout";

import crm from "../../static/images/sidebarIcons/crm.svg";
import dash from "../../static/images/sidebarIcons/dash.svg";
import affiliates from "../../static/images/sidebarIcons/affiliates.svg";
import vaults from "../../static/images/sidebarIcons/vaults.svg";
import terminal from "../../static/images/sidebarIcons/terminal.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";
import loans from "../../static/images/sidebarIcons/socially.svg";
import Lock from "../../static/images/icons/lock.svg";
import defaultImg from "../../static/images/icons/app_placeholder.png";

import funds from "../../static/images/menulogos/FundManager.svg";
import store from "../../static/images/sidebarIcons/store.svg";
import bots from "../../static/images/sidebarIcons/bots.svg";
import raindrops from "../../static/images/sidebarIcons/raindrops.svg";
import education from "../../static/images/sidebarIcons/education.svg";
import strategies from "../../static/images/sidebarIcons/strategies.svg";
import teams from "../../static/images/sidebarIcons/team.svg";
import FundManagerFunds from "./FundManagerFunds";
import MobileMenu from "../../globalComponents/MobileMenu";

const FundManagers = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    showMobileMenu,
    globalMenu,
  } = useContext(GlobalContex);

  const menuItems = [
    // {
    //   menuName: "Dashboard",
    //   menuIcon: dash,
    //   enabled: false,
    // },
    {
      menuName: "Funds",
      menuIcon: funds,
      enabled: true,
    },
    // {
    //   menuName: "Store",
    //   menuIcon: store,
    //   enabled: false,
    // },
    // {
    //   menuName: "CRM",
    //   menuIcon: crm,
    //   enabled: false,
    // },
    // {
    //   menuName: "Affilaites",
    //   menuIcon: affiliates,
    //   enabled: false,
    // },
    // {
    //   menuName: "Raindrops",
    //   menuIcon: raindrops,
    //   enabled: false,
    // },
    // {
    //   menuName: "Education",
    //   menuIcon: education,
    //   enabled: false,
    // },
    // {
    //   menuName: "Strategies",
    //   menuIcon: strategies,
    //   enabled: false,
    // },
    // {
    //   menuName: "Teams",
    //   menuIcon: teams,
    //   enabled: false,
    // },
    // {
    //   menuName: "Terminal",
    //   menuIcon: terminal,
    //   enabled: false,
    // },
    // {
    //   menuName: "Bots",
    //   menuIcon: bots,
    //   enabled: false,
    // },
    // {
    //   menuName: "Education",
    //   menuIcon: education,
    //   enabled: false,
    // },
    // {
    //   menuName: "Strategies",
    //   menuIcon: strategies,
    //   enabled: false,
    // },
    // {
    //   menuName: "Teams",
    //   menuIcon: teams,
    //   enabled: false,
    // },
    // {
    //   menuName: "Terminal",
    //   menuIcon: terminal,
    //   enabled: false,
    // },
    // {
    //   menuName: "Bots",
    //   menuIcon: bots,
    //   enabled: false,
    // },
  ];

  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      case "Funds":
        return <FundManagerFunds />;
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={menuItems} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
            {/* <div className="filterRow"></div> */}
            {/* <div style={{ flexGrow: 1 }}>{conditionalPages()}</div> */}
          </div>
        </div>
      </div>

      {showMobileMenu ? (
        <MobileMenu localMenu={menuItems} />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}
      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default FundManagers;
