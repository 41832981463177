// Package Imports
import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// CSS Imports
import "./App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

// Context Imports
import { GlobalContex } from "./globalContex";
import HomePage1 from "./pages/Registration/pages/HomePage";
import FirstPage from "./pages/Registration/pages/FirstPage";

// Image Imports

//cPage/Component Imports
import SignInPage from "./pages/SignInPage";
import HomePage from "./pages/HomePage";
import GlobalSidebar from "./globalComponents/GlobalSidebar";
// import Elements from "./pages/Elements";
import axios from "axios";

function App() {
  const { loginData, selectedApp } = useContext(GlobalContex);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/register" element={<HomePage1 />} />
          <Route exact path="/register/affiliate" element={<FirstPage />} />
          <Route exact path="/register/affiliate/:id" element={<FirstPage />} />
          <Route
            exact
            path="/register/pre-registered"
            element={<FirstPage />}
          />
          <Route
            exact
            path="/register/pre-registered/:id"
            element={<FirstPage />}
          />
          <Route exact path="/register/by-myself" element={<FirstPage />} />
          <Route exact path="/register/by-myself/:id" element={<FirstPage />} />
          {/* <Route path="/" element={<Elements />} /> */}
          <Route
            path="/example"
            element={
              loginData !== null ? (
                <Navigate to={`/${selectedApp?.appName}`} />
              ) : (
                <SignInPage />
              )
            }
          />
          <Route
            path="/login"
            element={
              loginData !== null ? (
                <Navigate to={`/${selectedApp?.appName}`} />
              ) : (
                <SignInPage />
              )
            }
          />
          {/* <Route
              path="/*"
              element={loginData !== null ? <HomePage /> : <Elements />}
            /> */}
          <Route
            path="/*"
            element={
              loginData !== null ? <HomePage /> : <Navigate to="/login" />
            }
          />
          {/* <Route
              path="*"
              element={loginData !== null ? <HomePage /> : <SignInPage />}
            /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
