import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../../../globalContex";
import "./filterDraw.scss";

function BondsList({ addingBonds, setAddingBonds, email, notAdded, listId }) {
  // const { data: bonds, isLoading: bondsLoading } = useBondsList(email);
  const [search, setSearch] = useState("");
  const { bankerEmail, allCoins, FormatCurrency, FormatNumber } =
    useContext(GlobalContex);
  const [bondsLoading, setBondsLoading] = useState(false);
  const [bonds, setBonds] = useState([]);
  const [currencies, setAllCurrencies] = useState(null);

  useEffect(() => {
    console.log(listId, "jhwvejdhwef");
    setBondsLoading(true);
    if (notAdded) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?notAddedToUsersList=true&user_list_id=${listId}&email=${bankerEmail}`
        )
        .then((res) => {
          if (res.status) {
            setBonds(res.data.custom_bonds);
            setAllCurrencies(res.data.currenciesData);
            setBondsLoading(false);
          }
        });
    } else {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/data/get?list_id=${listId}`
          // `https://comms.globalxchange.io/coin/iced/banker/custom/bond/list/data/get?list_id=5zv3h0pl2jnq2q8``https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?email=${bankerEmail}`
        )
        .then((res) => {
          if (res.status) {
            console.log(res.data, "kjwegkjwef");
            // setBonds(res.data.custom_bonds);
            // setAllCurrencies(res.data.currenciesData);

            setBonds(res.data?.bondsListData);
            // setAllCurrencies(allCoins);
            setBondsLoading(false);
          }
        });
    }
  }, []);

  return (
    <>
      <div className="filterTitle">
        Select Bonds To {notAdded ? "Add" : "Remove"}
      </div>
      <div className="filterSearch">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bonds By Asset....|"
        />
      </div>
      <div
        className="filterCoinCard"
        style={{
          // position: "unset",
          paddingBottom: "5vh",
        }}
      >
        {bondsLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="cardBody" key={i}>
                  <Skeleton className="dp coinImage" circle />
                  {/* <img className="coinImage" src={item.icon} alt="" /> */}
                  <div className="coinDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : bonds
              .filter(
                (user) =>
                  user?.coinsData[0]?.coin
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  currencies?.[user?.coinsData[0]?.coin]?.name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((item) => {
                return (
                  <div>
                    <div
                      className="bond"
                      key={item._id}
                      onClick={() => {
                        setAddingBonds((addingUsers) =>
                          addingUsers
                            ?.map((bnd) => bnd.bond_template_id)
                            .includes(item?.bond_template_id)
                            ? addingUsers.filter(
                                (bnd) =>
                                  bnd?.bond_template_id !==
                                  item?.bond_template_id
                              )
                            : [...addingUsers, item]
                        );
                      }}
                    >
                      <div className="coinDetail">
                        <img
                          className="dp"
                          style={{ width: "22px", height: "22px" }}
                          src={item?.coinsData?.[0]?.coin_icon}
                          alt=""
                        />
                        <div
                          className="name"
                          style={{ color: "#050505", fontSize: "16px" }}
                        >
                          {item?.coinsData?.[0]?.coin_name}
                        </div>
                        <input
                          readOnly
                          style={{
                            marginLeft: "auto",
                            width: "22px",
                            height: "22px",
                          }}
                          type="checkbox"
                          checked={addingBonds
                            ?.map((bnd) => bnd.bond_template_id)
                            .includes(item?.bond_template_id)}
                        ></input>
                      </div>
                      <div className="bondValues">
                        <span
                          className="cost"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatCurrency(
                            item?.coinsData?.[0]?.bondCost,
                            item?.coinsData?.[0]?.coin
                          )}{" "}
                          {item?.coinsData?.[0]?.coin}
                        </span>
                        <span
                          className="intrst"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatNumber(item?.daily_interest_rate, 3)}%/Day
                        </span>
                        <span
                          className="days"
                          style={{ fontSize: "12px", fontWeight: 700 }}
                        >
                          {FormatNumber(item?.days)} Days
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}

        <div className="space"></div>
      </div>
    </>
  );
}

export default BondsList;
