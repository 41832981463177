import React, { useContext, useState, useEffect } from "react";
import "./subdrawer.scss"
import axios from "axios";
import close from "../../../../../static/images/icons/close1.svg";
import { GlobalContex } from "../../../../../globalContex";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import Lock from "../../../../static/images/icons/lock.svg";



const Actions = ({ step, setStep, DistributionPathId, DistributionData }) => {
    const { showSubDraw, setShowSubDraw, holderData,
        setHolderData, selectedShareToken, setSelectedShareToken, loginData, compData, compensationData, setCompensationData, DistribData,
        setDistribData, distLoader,
        setDistLoader, } = useContext(GlobalContex);

    const [thePercent, setThePercent] = useState("")
    const [keysData, setKeysData] = useState([{ name: "Total Commission" }])
    const [directAff, setDirectAff] = useState("")
    const [networkChn, setNetworkChn] = useState("")
    const [directExec, setDirectExec] = useState("")
    const [execNetworkChn, setExecNetworkChn] = useState("")


    let disp
    if (step === "loading" || step === "success") {
        disp = "none"
    } else {
        disp = ""
    }
    // useEffect(() => {
    //     axios
    //         .get(
    //             `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
    //                 "bankerEmailNew"
    //             )}`
    //         )
    //         .then((res) => {
    //             console.log("ShareTokenValues ", res.data.paths)
    //             if (!selectedShareToken) {
    //                 setSelectedShareToken(res.data.paths[0])
    //             }
    //         });
    // }, [localStorage.getItem("bankerEmailNew")]);

    const openloader = () => {
        setStep("loading")
        let body = {
            email: localStorage.getItem("bankerEmailNew"),
            token: loginData.idToken,
            path_id: DistributionPathId,
            update_data: {
                commission_distribution: {
                    direct: directAff ? directAff : 0,
                    indirect: networkChn ? networkChn : 0,
                    direct_bd: directExec ? directExec : 0,
                    indirect_bd: execNetworkChn ? execNetworkChn : 0
                }
            }
        }
        console.log("newdistrib-body ", JSON.stringify(body))


        axios.post("https://comms.globalxchange.io/coin/investment/path/edit", body).then(res => {
            if (res.data.status) {
                if (res.data.message === "success") {
                    console.log(res.data, "the-data-inv")
                    setStep("success")
                    setDistLoader(true)
                    setDirectAff("")
                    setNetworkChn("")
                    setDirectExec("")
                    setExecNetworkChn("")
                    setTimeout(() => {
                        setShowSubDraw(false)
                        setStep("default")
                    }, 1000)
                } else {
                    setStep("step1")
                    toast.info(res.data.message)
                }
            } else if (res.data.message === "jwt expired") {
                setStep("Token Expired");
            } else {
                console.log("Error ", res.data)
                setStep("default")
            }
        })
    }


    const conditionalcrumbs = () => {
        switch (step) {
            case "step1":
                return (
                    <p className="crumb" onClick={() => setStep("default")}>Actions {"->"} <span className="thebreadcrumb"> Change Distribution</span></p>
                )
            default:
                return (
                    <p className="thebreadcrumb">Actions</p>
                )
        }
    }

    const conditionalPaths = () => {
        switch (step) {
            case "loading":
                return (
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingAnimation logoAnim sideDraw={true} />
                        <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                textAlign: "center",
                                // marginBottom: "20px",
                                color: "#182542",
                                fontSize: "18px",
                                fontWeight: "700"
                            }}
                        >
                            Change Comp Distribution For {JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}
                        </div>
                    </div>
                )
            case "success":
                return <div className="assetDispText">
                    You Have Successfully Updated The Comp Distribution For  {JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}
                </div>
            case "Token Expired":
                return (
                    <div style={{ padding: "30px", height: "90px" }}>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            {/* <img src={lock} alt="" style={{ width: "20px" }} /> */}
                            <div style={{ paddingLeft: "15px" }}>Logout</div>
                        </div>
                    </div>
                );
            case "step1":
                return (
                    <>
                        <div className="assetfields heightscroll">
                            <p className="assettext">Direct Affiliate</p>
                            <div >
                                <div style={{ display: "flex" }}>
                                    <p className="thedistribdata">{DistributionData[0]?.percentage.toFixed(2)}%</p>
                                    <input type="number" className="changedistrib" placeholder="0.00%" value={directAff} onChange={(e) => setDirectAff(e.target.value)} />
                                </div >
                                <br />
                                <div style={{ display: "flex" }}>
                                    <p className="thedistribdata">{DistributionData[1]?.percentage.toFixed(2)}%</p>
                                    <input type="number" className="changedistrib" placeholder="0.00%" value={networkChn} onChange={(e) => setNetworkChn(e.target.value)} />
                                </div>
                                <br />
                                <div style={{ display: "flex" }}>
                                    <p className="thedistribdata">{DistributionData[2]?.percentage.toFixed(2)}%</p>
                                    <input type="number" className="changedistrib" placeholder="0.00%" value={directExec} onChange={(e) => setDirectExec(e.target.value)} />
                                </div>
                                <br />
                                <div style={{ display: "flex" }}>
                                    <p className="thedistribdata">{DistributionData[3]?.percentage.toFixed(2)}%</p>
                                    <input type="number" className="changedistrib" placeholder="0.00%" value={execNetworkChn} onChange={(e) => setExecNetworkChn(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <br />

                        <div className="buttonaction">
                            <div className="leftbtn">
                                <button className="backbtn" onClick={() => setStep("default")}>Go Back</button>
                            </div>
                            <div className="rightbtn">
                                <button className="savebtn" onClick={openloader}>Save Changes</button>
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <div className="assetfields">
                        <div className="defaultcard">
                            <p>What Would You Like To Do?</p>
                        </div>
                        <div className="boxtext" onClick={() => setStep("step1")}>
                            <p className="thefonttext">Change Comp Distribution</p>
                        </div>
                    </div>
                )
        }
    }


    return (
        <div
            style={{
                height: "90px",
            }}>
            <div style={{ display: disp }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <div style={{
                        display: "flex", alignItems: "center", padding: "30px",
                    }}>
                        <div>
                            <div style={{ fontSize: "22px" }}>
                                {DistribData.name}
                            </div>
                            {conditionalcrumbs()}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", paddingRight: "30px" }}>
                        <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                            <img src={close} alt="" />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
            {conditionalPaths()}
        </div>
    )
}

export default Actions