import React, { useEffect, useMemo, useState } from "react";

import OtpInput from "react-otp-input";
import classNames from "./settingsModal.module.scss";
import arrowGo from "../../static/images/clipIcons/arrowGo.svg";

import { toast } from "react-toastify";

import axios from "axios";

import { useContext } from "react";
import { GlobalContex } from "../../globalContex";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import "./adminModal.scss";

import defaultImg from "../../static/images/icons/app_placeholder.png";
import Skeleton from "react-loading-skeleton";

function AdminModal({
  onClose = () => { },
  onSuccess = () => { },
  logoParam,
  modalOpen,
}) {
  const {
    bankerEmail,
    loginData,
    setBankerEmail,
    selectedApp,
    setgetBankerTag,
    query,
    setQuery,
    shareToken,
    setShareToken,
    banker,
    setBanker,
    allShareToken,
    setAllShareToken,
    allBankers,
    setAllBankers,
    setModalOpen,
    selectedTerminal,
    setSelectedTerminal,
    selectedShareToken,
    setSelectedShareToken,
    setSelectedAppData,
    allPublications,
    theApp,
    setTheApp,
    compLoader,
    setCompLoader,
    selectedBrand,
    setSelectedBrand,
    selectedBrandApp,
    setSelectedBrandApp,
    allAppsForBrand,
    selectedPublication,
    setSelectedPublication,
    allBrands,
    mcbAdminLoading,
  } = useContext(GlobalContex);

  console.log("selectedApp", selectedApp);

  const bankerEmailRef = useRef();

  const { pathname } = useLocation();

  const [step, setStep] = useState("");
  const [pin, setPin] = useState("");
  // const [query, setQuery] = useState("");
  const [newBankerData, setNewBankerData] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [bankerProfile, setBankerProfile] = useState(null);

  const [allTerminals, setAllTerminals] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  // const [query, setQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
          "bankerEmailNew"
        )}`
      )
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          setAllShareToken(res.data.paths);
          console.log("ShareTokenValues ", res.data.paths);
          if (!selectedShareToken) {
            setSelectedShareToken(JSON.stringify(res.data.paths[0]));
            localStorage.setItem(
              "selectedShareCoin",
              JSON.stringify(res.data.paths[0])
            );
          }
          console.log(res.data.paths, "jhvdjhwvedhjwed");
        } else {
          setAllShareToken([]);
          setLoading(false);
        }
      });
  }, [localStorage.getItem("bankerEmailNew")]);

  useEffect(() => {
    axios
      .get(
        `https://teller2.apimachine.com/admin/allBankers?email=${bankerEmail}`
      )
      .then(({ data }) => {
        setBankerProfile(data.data[0]);
        setgetBankerTag(data.data[0]);
      });
  }, [bankerEmail]);

  const selectedtokenapp = () => {
    setBankerEmail(newBankerData?.hardCoded[0]?.data?.email);
    setStep(null);
    setSelectedShareToken(null);
    localStorage.removeItem("selectedShareCoin");
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
          "bankerEmailNew"
        )}`
      )
      .then((res) => {
        if (!selectedShareToken) {
          setSelectedShareToken(JSON.stringify(res.data.paths[0]));
          localStorage.setItem(
            "selectedShareCoin",
            JSON.stringify(res.data.paths[0])
          );
        }
        setSelectedAppData(res.data.paths[0].token_profile_data.coinSymbol);
      });
  };

  // useEffect(() => {
  //   setAllShareToken([]);
  //   axios.get(`https://teller2.apimachine.com/admin/allBankers`).then((res) => {
  //     setAllBankers(res.data.data);
  //   });
  // }, []);

  const filteredSharedToken = allShareToken
    ? allShareToken.filter((item) => {
      const lowquery = query.toLowerCase();
      return (
        (item.token_profile_data.coinSymbol + item.apps_metadata[0].app_name)
          .toLowerCase()
          .indexOf(lowquery) >= 0
      );
    })
    : "";

  const checkPinValidity = () => {
    axios
      .post(`https://comms.globalxchange.io/gxb/apps/user/validate/group/pin`, {
        email: loginData.user.email,
        token: loginData?.idToken,
        pin: pin,
        group_id: "66me7fdkhxsbtur",
      })
      .then((res) => {
        if (res.data.status) {
          setModalLoading(false);
          setStep("Validated");
          setTimeout(() => {
            setStep("EnterEmail");
          }, 2000);
        } else {
          if (res.data.message === "jwt expired") {
            setModalLoading(false);
            setStep("InvalidJwt");
          } else {
            setModalLoading(false);
            setStep("Invalid");
          }
        }
      })
      .finally((e) => {
        setPin("");
      });
  };

  useEffect(() => {
    if (pin.length === 4) {
      setModalLoading(true);
      checkPinValidity();
    }
  }, [pin]);

  const handleSetBankerEmail = () => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${bankerEmailRef.current.value}`
      )
      .then((res) => {
        if (res.data.status) {
          console.log(res.data?.usersData, "kjwkdjwe");
          setNewBankerData(res.data.usersData[0]);
          setStep("VerifyUser");
          localStorage.setItem(
            "newBankerData",
            JSON.stringify(res.data.usersData[0])
          );
          localStorage.setItem(
            "bankerEmailNew",
            res.data.usersData[0]?.hardCoded[0]?.data?.email
          );
        }
      });

    // setBankerEmail(bankerEmailRef.current.value);
    // setStep(null);
  };

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/terminal/app/get?email=${bankerEmail}`
      )
      .then((res) => {
        if (res.data.status) {
          setAllTerminals(res.data.terminals);
          if (!selectedTerminal) {
            setSelectedTerminal(res.data.terminals[0]);
          }
        }
      });
  }, []);

  const conditionalSelectedTerminal = () => {
    if (allTerminals) {
      if (selectedTerminal) {
        return (
          <div style={{ display: "flex" }}>
            <img
              src={selectedTerminal.color_icon}
              alt=""
              style={{
                width: "17px",
                height: "17px",
                borderRadius: "100%",
              }}
            />
            <div style={{ fontSize: "12px", fontWeight: 700 }}>
              &nbsp;{selectedTerminal.terminal_name}
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", paddingLeft: "5px" }}>
            <img
              src={allTerminals[0]?.color_icon}
              alt=""
              style={{
                width: "17px",
                height: "17px",
                borderRadius: "100%",
              }}
            />
            <div
              style={{ fontSize: "12px", fontWeight: 700, paddingLeft: "5px" }}
            >
              {allTerminals[0]?.terminal_name}
            </div>
          </div>
        );
      }
    }
  };
  // const conditionalSelectedShareToken = () => {
  //   console.log(allShareToken, "thesharetokendata")
  //   if (allShareToken) {
  //     if (selectedShareToken) {
  //       return (
  //         <div style={{ display: "flex" }}>
  //           <img
  //             src={selectedTerminal.color_icon}
  //             alt=""
  //             style={{
  //               width: "17px",
  //               height: "17px",
  //               borderRadius: "100%",
  //             }}
  //           />
  //           <div style={{ fontSize: "12px", fontWeight: 700 }}>
  //             &nbsp;{selectedTerminal.terminal_name}
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div style={{ display: "flex", paddingLeft: "5px" }}>
  //           <img
  //             src={allTerminals[0]?.color_icon}
  //             alt=""
  //             style={{
  //               width: "17px",
  //               height: "17px",
  //               borderRadius: "100%",
  //             }}
  //           />
  //           <div
  //             style={{ fontSize: "12px", fontWeight: 700, paddingLeft: "5px" }}
  //           >
  //             {allTerminals[0]?.terminal_name}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  // }

  const conditionalDefaultLayout = () => {
    console.log(pathname, "kwjbeckjwed");
    switch (pathname) {
      case "/MyCryptoBrand":
      case "/MyApps":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection" style={{ marginTop: "-15px" }}>
                Settings
              </div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div style={{ overflowY: "scroll", height: "250px" }}>
                <div onClick={(e) => setStep("Admin")} className="menuCards1">
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: 700 }}>
                      Logged in User
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: "12px", fontWeight: 400 }}>
                        Currently Selected:&nbsp;
                      </div>
                      <>
                        <div>
                          {localStorage.getItem("newBankerData") ? (
                            <img
                              src={
                                JSON.parse(
                                  localStorage.getItem("newBankerData")
                                ).dynamic[0]?.data?.profile_img
                              }
                              alt=""
                              style={{
                                width: "17px",
                                height: "17px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : loginData?.user?.profile_img ? (
                            <img
                              src={loginData?.user?.profile_img}
                              alt=""
                              style={{
                                width: "17px",
                                height: "17px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                height: "17px",
                                width: "17px",
                                borderRadius: "100%",
                              }}
                            >
                              &nbsp;
                            </div>
                          )}
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: 700 }}>
                          &nbsp;{bankerEmail}
                        </div>
                      </>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => setStep("ChangeBrands")}
                  className="menuCards"
                  style={{
                    marginTop: "30px",
                    // border:"1px solid red"
                  }}
                >
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: 700 }}>
                      Selected Brands
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ fontSize: "12px", fontWeight: 400 }}>
                        Currently Selected:&nbsp;
                      </div>
                      {!mcbAdminLoading ? (
                        <>
                          <div
                            style={{
                              height: "17px",
                              width: "17px",
                              borderRadius: "100%",
                            }}
                          >
                            <img
                              src={
                                // selectedsingleBrands && selectedsingleBrands.brand_logo
                                selectedBrand?.brand_logo
                              }
                              alt=""
                              style={{
                                width: "17px",
                                height: "17px",
                                borderRadius: "100%",
                                marginTop: "-10px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              marginLeft: "5px",
                            }}
                          >
                            {
                              // selectedsingleBrands && selectedsingleBrands.brand_name
                              selectedBrand?.brand_name
                            }
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton
                            className="dp"
                            circle
                            width={17}
                            height={17}
                            style={{ marginRight: "10px" }}
                          />

                          <Skeleton width={100} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <img src={arrowGo} alt="" width="20px" />
                  </div>
                </div>

                <div
                  onClick={(e) => setStep("ChangeApp")}
                  className="menuCards"
                  style={{ marginTop: "30px" }}
                >
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: 700 }}>
                      Selected Application
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ fontSize: "12px", fontWeight: 400 }}>
                        Currently Selected:&nbsp;
                      </div>
                      {!mcbAdminLoading ? (
                        <>
                          <div
                            style={{
                              height: "17px",
                              width: "17px",
                              borderRadius: "100%",
                            }}
                          >
                            <img
                              src={
                                // selectedsingleApplication && selectedsingleApplication.app_icon
                                selectedBrandApp?.app_icon
                                // localStorage.getItem("selectedsingleapplication") ? JSON.parse(localStorage.getItem("selectedsingleapplication")).app_icon : null
                              }
                              alt=""
                              style={{
                                width: "17px",
                                height: "17px",
                                borderRadius: "100%",
                                marginTop: "-10px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              marginLeft: "5px",
                            }}
                          >
                            {
                              // selectedsingleApplication && selectedsingleApplication.app_name
                              selectedBrandApp?.app_name
                              //  localStorage.getItem("selectedsingleapplication") ? JSON.parse(localStorage.getItem("selectedsingleapplication")).app_name : null
                            }
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton
                            className="dp"
                            circle
                            width={17}
                            height={17}
                            style={{ marginRight: "10px" }}
                          />

                          <Skeleton width={100} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <img src={arrowGo} alt="" width="20px" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames.footerBtns}
              style={{ display: "flex", marginTop: "-50px" }}
            >
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "#E7E7E780", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
      case "/CapitalMarkets":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div onClick={(e) => setStep("Admin")} className="menuCards">
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Logged in User
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingRight: "3px",
                      }}
                    >
                      Currently Selected:&nbsp;
                    </div>
                    <div>
                      {localStorage.getItem("newBankerData") ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("newBankerData"))
                              .dynamic[0]?.data?.profile_img
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : loginData?.user?.profile_img ? (
                        <img
                          src={loginData?.user?.profile_img}
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "100%",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "3px",
                      }}
                    >
                      &nbsp;{bankerEmail}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>

              <div className="menuCards1" style={{ marginTop: "30px" }}>
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Banker Profile
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingRight: "3px",
                      }}
                    >
                      Currently Selected:&nbsp;
                    </div>
                    {bankerProfile?.profilePicURL ? (
                      <img
                        src={bankerProfile.profilePicURL}
                        alt=""
                        style={{
                          width: "17px",
                          height: "17px",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          height: "17px",
                          width: "17px",
                          background: "gray",
                          borderRadius: "100%",
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "3px",
                      }}
                    >
                      &nbsp;{bankerProfile?.displayName}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div> */}
              </div>
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
      case "/Terminals":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div onClick={(e) => setStep("Admin")} className="menuCards">
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Terminal Owner
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>
                    <div>
                      {localStorage.getItem("newBankerData") ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("newBankerData"))
                              .dynamic[0]?.data?.profile_img
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : loginData?.user?.profile_img ? (
                        <img
                          src={loginData?.user?.profile_img}
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "100%",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 700 }}>
                      &nbsp;{bankerEmail}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>
              <div
                className="menuCards"
                style={{ marginTop: "30px" }}
                onClick={(e) => setStep("Terminals")}
              >
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Terminal
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>

                    {conditionalSelectedTerminal()}
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>
            </div>
            <div className="footerBtns" style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className="individualButtons"
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className="individualButtons"
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                  color: "white",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
      case "/Capitalized":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div onClick={(e) => setStep("Admin")} className="menuCards">
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Logged In User
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>
                    <div>
                      {localStorage.getItem("newBankerData") ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("newBankerData"))
                              .dynamic[0]?.data?.profile_img
                              ? JSON.parse(
                                localStorage.getItem("newBankerData")
                              ).dynamic[0]?.data?.profile_img
                              : defaultImg
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : loginData?.user?.profile_img ? (
                        <img
                          src={
                            loginData?.user?.profile_img
                              ? loginData?.user?.profile_img
                              : defaultImg
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "100%",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 700 }}>
                      &nbsp;{bankerEmail}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>
              <div
                className="menuCards"
                style={{ marginTop: "30px" }}
                onClick={(e) => setStep("ChangeShareToken")}
              >
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    ShareToken
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          JSON.parse(localStorage.getItem("selectedShareCoin"))
                            ?.apps_metadata[0]?.app_icon
                        }
                        alt=""
                        style={{
                          width: "17px",
                          height: "17px",
                          borderRadius: "100%",
                        }}
                      />
                      <div style={{ fontSize: "12px", fontWeight: 700 }}>
                        &nbsp;
                        {
                          JSON.parse(localStorage.getItem("selectedShareCoin"))
                            ?.apps_metadata[0]?.app_name
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>
            </div>
            <div className="footerBtns" style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className="individualButtons"
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className="individualButtons"
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                  color: "white",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
      case "/OTCDesks":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div onClick={(e) => setStep("Admin")} className="menuCards">
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Logged in User
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingRight: "3px",
                      }}
                    >
                      Currently Selected:&nbsp;
                    </div>
                    <div>
                      {localStorage.getItem("newBankerData") ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("newBankerData"))
                              .dynamic[0]?.data?.profile_img
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : loginData?.user?.profile_img ? (
                        <img
                          src={loginData?.user?.profile_img}
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "100%",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "3px",
                      }}
                    >
                      &nbsp;{bankerEmail}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div>

              <div className="menuCards1" style={{ marginTop: "30px" }}>
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Banker Profile
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingRight: "3px",
                      }}
                    >
                      Currently Selected:&nbsp;
                    </div>
                    {bankerProfile?.profilePicURL ? (
                      <img
                        src={bankerProfile.profilePicURL}
                        alt=""
                        style={{
                          width: "17px",
                          height: "17px",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          height: "17px",
                          width: "17px",
                          background: "gray",
                          borderRadius: "100%",
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 700,
                        paddingLeft: "3px",
                      }}
                    >
                      &nbsp;{bankerProfile?.displayName}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div> */}
              </div>
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Settings
                </span>
                &nbsp;
                {">"}
              </div>
              <div className="titleSection">What Do You Want To Change?</div>

              <div onClick={(e) => setStep("Admin")} className="menuCards">
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Logged in User
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>
                    <div>
                      {localStorage.getItem("newBankerData") ? (
                        <img
                          src={
                            JSON.parse(localStorage.getItem("newBankerData"))
                              .dynamic[0]?.data?.profile_img
                          }
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : loginData?.user?.profile_img ? (
                        <img
                          src={loginData?.user?.profile_img}
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "100%",
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 700 }}>
                      &nbsp;{bankerEmail}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div> */}

              </div>
              {selectedApp.appName === "Publishers" || selectedApp.appName === "Classrooms" ? <></> :
                <div
                  onClick={() => setStep("ChangePublication")}
                  className="menuCards"
                  style={{
                    marginTop: "30px",
                    // border:"1px solid red"
                  }}
                >
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: 700 }}>
                      Publication
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ fontSize: "12px", fontWeight: 400 }}>
                        Currently Selected:&nbsp;
                      </div>
                      {selectedPublication ? (
                        <>
                          <div
                            style={{
                              height: "17px",
                              width: "17px",
                              borderRadius: "100%",
                            }}
                          >
                            <img
                              src={
                                // selectedsingleBrands && selectedsingleBrands.brand_logo
                                selectedPublication?.profile_pic
                              }
                              alt=""
                              style={{
                                width: "17px",
                                height: "17px",
                                borderRadius: "100%",
                                marginTop: "-10px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              marginLeft: "5px",
                            }}
                          >
                            {
                              // selectedsingleBrands && selectedsingleBrands.brand_name
                              selectedPublication?.name
                            }
                          </div>
                        </>
                      ) : (
                        ""
                        // <div
                        //   style={{
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     alignItems: "center",
                        //   }}
                        // >
                        //   <Skeleton
                        //     className="dp"
                        //     circle
                        //     width={17}
                        //     height={17}
                        //     style={{ marginRight: "10px" }}
                        //   />

                        //   <Skeleton width={100} />
                        // </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <img src={arrowGo} alt="" width="20px" />
                  </div>
                </div>
              }

              {/* <div className="menuCards" style={{ marginTop: "30px" }}>
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 700 }}>
                    Banker Profile
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                      Currently Selected:&nbsp;
                    </div>
                    {bankerProfile?.profilePicURL ? (
                      <img
                        src={bankerProfile.profilePicURL}
                        alt=""
                        style={{
                          width: "17px",
                          height: "17px",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          height: "17px",
                          width: "17px",
                          background: "gray",
                          borderRadius: "100%",
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    <div style={{ fontSize: "12px", fontWeight: 700 }}>
                      &nbsp;{bankerProfile?.displayName}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={arrowGo} alt="" width="20px" />
                </div>
              </div> */}
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: 0.4 }}>Next</span>
              </div>
            </div>
          </>
        );
    }
  };

  // useEffect(() => {
  //   conditionalDefaultLayout();
  // }, [bankerProfile]);

  const stepRender = useMemo(() => {
    switch (step) {
      case "Admin":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span className="crumbs" onClick={(e) => setStep(null)}>
                  Settings
                </span>
                &nbsp;
                {"> "}
                <span
                  className="crumbs"
                  style={{ textDecoration: "underline", fontWeight: 700 }}
                >
                  Admin
                </span>
              </div>
              <div className={classNames.inCard} style={{ paddingTop: "20px" }}>
                <div className={classNames.otpView}>
                  <div className={classNames.label}>Enter Admin PIN</div>
                  <OtpInput
                    value={pin}
                    onChange={setPin}
                    numInputs={4}
                    separator={<span> </span>}
                    shouldAutoFocus
                    containerStyle={classNames.otpInputWrapper}
                    inputStyle={classNames.otpInput}
                  />
                </div>
              </div>
            </div>

            <br />

            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => setStep(null)}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Back</span>
              </div>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: "0.25" }}>Close</span>
              </div>
            </div>
          </>
        );
      case "Validated":
        return (
          <>
            <div
              className="modalStyle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "25px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Your Pin Has Been Successfully Validated
            </div>
          </>
        );
      case "EnterEmail":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span className="crumbs" onClick={(e) => setStep(null)}>
                  Settings
                </span>
                &nbsp;
                {"> "}
                <span className="crumbs" onClick={(e) => setStep("Admin")}>
                  Admin
                </span>
                {"> "}
                <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                  Enter Email
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div className="titleSection">Enter Email</div>
                <div style={{ width: "100%" }}>
                  <input
                    ref={bankerEmailRef}
                    placeholder="Enter Email"
                    // value={newBankerEmail}
                    // onChange={(e) => setNewBankerEmail(e.target.value)}
                    type="text"
                    style={{
                      borderRadius: "15px",
                      width: "100%",
                      border: "0.5px solid #E5E5E5",
                      height: "59px",
                      padding: "0px 20px",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </div>
            <br />

            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => handleSetBankerEmail()}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span>Next</span>
              </div>
            </div>
          </>
        );
      case "VerifyUser":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span className="crumbs" onClick={(e) => setStep("Admin")}>
                  Admin
                </span>
                {"> "}
                <span className="crumbs" onClick={(e) => setStep("EnterEmail")}>
                  Enter Email
                </span>
                {">"}
                <span
                  className="crumbs"
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    paddingLeft: "5px",
                  }}
                >
                  Verify User
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div className="titleSection">Is This The User?</div>
                <div
                  onClick={selectedtokenapp}
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    width: "100%",
                    border: "0.5px solid #E5E5E5",
                    height: "65px",
                    padding: "40px 20px",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={newBankerData?.dynamic[0]?.data?.profile_img}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                    }}
                  />
                  <div style={{ paddingLeft: "10px" }}>
                    <div style={{ fontWeight: 700 }}>
                      {newBankerData?.hardCoded[0]?.data?.username}
                    </div>
                    <div>{newBankerData?.hardCoded[0]?.data?.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => setStep("EnterEmail")}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Back</span>
              </div>
              <div
                onClick={(e) => {
                  setBankerEmail(newBankerData?.hardCoded[0]?.data?.email);
                  setStep(null);
                }}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span>Next</span>
              </div>
            </div>
          </>
        );
      case "Invalid":
        return (
          <>
            <div
              className="modalStyle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "25px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              The Pin You Entered Is Invalid or You Are Not An Admin.
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => setStep("Admin")}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span>Back</span>
              </div>
            </div>
          </>
        );
      case "InvalidJwt":
        return (
          <>
            <div
              className="modalStyle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "25px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Token Has Expired.
              <br />
              Please Relogin.
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span>Sign-Out</span>
              </div>
            </div>
          </>
        );
      case "changeAdmin":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span style={{ textDecoration: "underline" }}>Settings</span>
                &nbsp;
                {">"}
              </div>
              <br />
              <div className="titleSection">Enter Email</div>
              <div style={{ width: "100%" }}>
                <input
                  ref={bankerEmailRef}
                  placeholder="Enter Email"
                  // value={newBankerEmail}
                  // onChange={(e) => setNewBankerEmail(e.target.value)}
                  type="text"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    border: "0.5px solid #E5E5E5",
                    height: "59px",
                    padding: "0px 20px",
                    color: "black",
                  }}
                />
              </div>
            </div>
            <div className={classNames.footerBtns} style={{ display: "flex" }}>
              <div
                onClick={(e) => onClose()}
                className={classNames.btnSettings}
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => {
                  console.log(bankerEmailRef.current.value, "qkjwdkqwd");
                  setBankerEmail(bankerEmailRef.current.value);
                }}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                  // opacity: newBankerEmail !== "" ? 1 : 0.5,
                }}
              >
                <span>Next</span>
              </div>
            </div>
          </>
        );
      case "ChangeApp":
        return (
          <>
            <div className="modalStyle" style={{ marginTop: "-15px" }}>
              <div className="headerSection">Settings</div>
              <div className="breadcrumb" style={{ marginTop: "-10px" }}>
                <span onClick={(e) => setStep(null)}>Settings</span>
                &nbsp;
                {"> "}
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Change App
                </span>
              </div>
              <div
                style={{
                  color: "#182542",
                  marginTop: "-15px",
                  fontSize: "15px",
                  fontWeight: "bolder",
                  paddingBottom: "20px",
                }}
              >
                Select One Your Apps
              </div>
              <div style={{ height: "310px", borderRadius: "10px" }}>
                <div>
                  <input
                    className="changeapp-search"
                    placeholder="Search Apps..."
                    style={{
                      height: "40px",
                      paddingLeft: "30px",
                      width: "100%",
                      borderRadius: "10px",
                      fontSize: "20px",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    height: "250px",
                    borderRadius: "10px",
                    overflowY: "scroll",
                    // border:"1px solid red"
                  }}
                // onClick={handleApplicationEvent}
                >
                  {allAppsForBrand
                    .filter((el) =>
                      el.app_name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((item) => {
                      return (
                        <div
                          className="changeapp-data"
                          style={{
                            width: "99%",
                            height: "80px",
                            borderRadius: "10px",
                            display: "flex",
                            marginTop: "32px",
                            marginLeft: "2px",
                            cursor: "pointer",
                            marginBottom: "10px",
                            // margin:"auto"
                          }}
                          key={item.app_name}
                          value={item.app_name}
                          onClick={(e) => {
                            setSelectedBrandApp(item);
                            localStorage.setItem(
                              "selectedBrandApp",
                              JSON.stringify(item)
                            );
                            setStep(null);
                          }}
                        // onClick={handleAppEvent}
                        >
                          <div style={{ width: "20%" }} value={item.app_name}>
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "70%",
                                height: "80%",
                                marginLeft: "10px",
                                marginTop: "10px",
                              }}
                              value={item.app_name}
                              src={item.app_icon}
                              alt="image"
                            />
                          </div>
                          <div style={{ width: "80%" }} value={item.app_name}>
                            <h4
                              value={item.app_name}
                              style={{
                                width: "100%",
                                color: "#182542",
                                paddingTop: "15px",
                                fontWeight: "bolder",
                                paddingLeft: "10px",
                              }}
                            >
                              {item.app_name}
                            </h4>
                            <p
                              value={item.app_name}
                              style={{
                                width: "100%",
                                color: "#182542",
                                paddingLeft: "10px",
                                lineHeight: "5px",
                                fontSize: "small",
                              }}
                            >
                              {item.app_code}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <br />

            <div
              className={classNames.footerBtns}
              style={{ display: "flex", marginTop: "-100px" }}
            >
              <div
                onClick={(e) => setStep(null)}
                className={classNames.btnSettings}
                style={{ backgroundColor: "#f3f3f3", color: "#292929" }}
              >
                <span>Back</span>
              </div>
              <div
                // onClick={(e) => checkPinValidity()}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: "0.5" }}>Next</span>
              </div>
            </div>
          </>
        );

      case "ChangeBrands":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span onClick={(e) => setStep(null)}>Settings</span>
                &nbsp;
                {"> "}
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Change Brands
                </span>
              </div>
              <div
                style={{
                  color: "#182542",
                  marginTop: "-15px",
                  fontSize: "15px",
                  fontWeight: "bolder",
                  paddingBottom: "20px",
                }}
              >
                Select One Of Your Brands
              </div>
              <div style={{ height: "310px", borderRadius: "10px" }}>
                <div>
                  <input
                    className="changeapp-search"
                    placeholder="Search Apps..."
                    style={{
                      height: "55px",
                      paddingLeft: "30px",
                      width: "100%",
                      borderRadius: "10px",
                      fontSize: "20px",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    height: "250px",
                    borderRadius: "10px",
                    overflowY: "scroll",
                    paddingBottom: "5vh",
                  }}
                >
                  {allBrands
                    .filter((el) =>
                      el.brand_name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((item) => {
                      return (
                        <div
                          onClick={(e) => {
                            setSelectedBrand(item);
                            localStorage.setItem(
                              "selectedBrand",
                              JSON.stringify(item)
                            );
                            setStep(null);
                          }}
                          className="changeapp-data"
                          style={{
                            width: "100%",
                            height: "80px",
                            borderRadius: "10px",
                            border: "0.5px solid #e7e7e7",
                            borderWidth: "0px 1px 0px 1px",
                            display: "flex",
                            marginTop: "15px",
                            cursor: "pointer",
                          }}
                          // onClick={handleBrandEvent}
                          key={item._id}
                        >
                          <div style={{ width: "20%" }} key={item._id}>
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "70%",
                                height: "80%",
                                marginLeft: "10px",
                                marginTop: "10px",
                              }}
                              src={item.brand_logo}
                              alt="image"
                            />
                          </div>
                          <div style={{ width: "60%" }} key={item._id}>
                            <h4
                              style={{
                                color: "#182542",
                                paddingTop: "15px",
                                fontWeight: "bolder",
                                paddingLeft: "10px",
                              }}
                            >
                              {item.brand_name}
                            </h4>
                            <p
                              style={{
                                color: "#182542",
                                paddingLeft: "10px",
                                lineHeight: "5px",
                                fontSize: "small",
                              }}
                            >
                              {item.operator_id}
                            </p>
                          </div>
                          <div style={{ width: "20%" }}>
                            <p
                              style={{
                                color: "#182542",
                                paddingTop: "30px",
                                fontWeight: "bolder",
                                paddingLeft: "10px",
                              }}
                              key={item._id}
                            >
                              {item.app_data.length}
                              <span> Apps</span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <br />

            <div
              className={classNames.footerBtns}
              style={{ display: "flex", marginTop: "-100px" }}
            >
              <div
                onClick={(e) => setStep(null)}
                className={classNames.btnSettings}
                style={{ backgroundColor: "#f3f3f3", color: "#292929" }}
              >
                <span>Back</span>
              </div>
              <div
                // onClick={(e) => checkPinValidity()}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: "0.5" }}>Next</span>
              </div>
            </div>
          </>
        );

      //Terminals Cases

      case "Terminals":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span className="crumbs" onClick={(e) => setStep("Admin")}>
                  Admin
                </span>
                {"> "}

                <span
                  className="crumbs"
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    paddingLeft: "5px",
                  }}
                >
                  Terminal
                </span>
              </div>
              <br />
              <div
                style={{
                  border: "0.5px solid #E5E5E5",
                  height: "62px",
                  borderRadius: "10px",
                  padding: "0px 20px",
                  marginBottom: "10px",
                }}
              >
                <input
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  placeholder="Search For A Terminal ..."
                  style={{ border: "none", height: "100%", width: "100%" }}
                />
              </div>
              <div
                style={{
                  height: "260px",
                  overflowY: "scroll",
                }}
              >
                <div>
                  {allTerminals
                    .filter((item) =>
                      item.terminal_name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    ?.map((item) => {
                      return (
                        <div
                          onClick={(e) => {
                            setSelectedTerminal(item);
                            localStorage.setItem(
                              "selectedTerminal",
                              JSON.stringify(item)
                            );
                            // setBankerEmail(
                            //   newBankerData?.hardCoded[0]?.data?.email
                            // );
                            setStep(null);
                          }}
                          className="terminalCards"
                        >
                          <img
                            src={item.color_icon ? item.color_icon : defaultImg}
                            alt=""
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "100%",
                            }}
                          />
                          <div style={{ paddingLeft: "10px" }}>
                            <div style={{ fontWeight: 700 }}>
                              {item?.terminal_name}
                            </div>
                            <div style={{ fontSize: "10px", fontWeight: 400 }}>
                              {item?.terminal_id}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <br />

            <div className="footerBtns" style={{ display: "flex" }}>
              <div
                onClick={(e) => setModalOpen(false)}
                className="individualButtons"
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => {
                  // setBankerEmail(newBankerData?.hardCoded[0]?.data?.email);
                  setStep("");
                }}
                className="individualButtons"
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                  color: "white",
                }}
              >
                <span>Back</span>
              </div>
            </div>
          </>
        );
      case "ChangePublication":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span onClick={(e) => setStep(null)}>Settings</span>
                &nbsp;
                {"> "}
                <span style={{ textDecoration: "underline", fontWeight: 700 }}>
                  Change Publication
                </span>
              </div>
              <div
                style={{
                  color: "#182542",
                  marginTop: "-15px",
                  fontSize: "15px",
                  fontWeight: "bolder",
                  paddingBottom: "20px",
                }}
              >
                Select One Of Your Publication
              </div>
              <div style={{ height: "310px", borderRadius: "10px" }}>
                <div>
                  <input
                    className="changeapp-search"
                    placeholder="Search Publications..."
                    style={{
                      height: "55px",
                      paddingLeft: "30px",
                      width: "100%",
                      borderRadius: "10px",
                      fontSize: "20px",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    height: "250px",
                    borderRadius: "10px",
                    overflowY: "scroll",
                    paddingBottom: "5vh",
                  }}
                >
                  {selectedApp?.appName === "Authors"
                    ? allPublications
                      .filter((el) =>
                        el.PublicationDetails[0]?.PublicationDetail[0]?.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((item) => {
                        return (
                          <div
                            onClick={(e) => {
                              setSelectedPublication(item?.PublicationDetails[0].PublicationDetail[0]);
                              localStorage.setItem(
                                "selectedPublication",
                                JSON.stringify(item)
                              );
                              setStep(null);
                            }}
                            className="changeapp-data"
                            style={{
                              width: "100%",
                              height: "80px",
                              borderRadius: "10px",
                              border: "0.5px solid #e7e7e7",
                              borderWidth: "0px 1px 0px 1px",
                              display: "flex",
                              marginTop: "15px",
                              cursor: "pointer",
                            }}
                            // onClick={handleBrandEvent}
                            key={item.PublicationDetails[0].PublicationDetail[0]?._id}
                          >
                            <div style={{ width: "20%" }} key={item.PublicationDetails[0]._id}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  width: "70%",
                                  height: "80%",
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                }}
                                src={item.PublicationDetails[0].PublicationDetail[0]?.profile_pic}
                                alt="imgg"
                              />
                            </div>
                            <div style={{ width: "60%" }} key={item._id}>
                              <h4
                                style={{
                                  color: "#182542",
                                  paddingTop: "15px",
                                  fontWeight: "bolder",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.PublicationDetails[0].PublicationDetail[0]?.name}
                              </h4>
                              <p
                                style={{
                                  color: "#182542",
                                  paddingLeft: "10px",
                                  lineHeight: "5px",
                                  fontSize: "small",
                                }}
                              >
                                {item?.PublicationDetails[0]?._id}
                              </p>
                            </div>
                            {/* <div style={{ width: "20%" }}>
                            <p
                              style={{
                                color: "#182542",
                                paddingTop: "30px",
                                fontWeight: "bolder",
                                paddingLeft: "10px",
                              }}
                              key={item?._id}
                            >
                              {item?.app_data?.length}
                              <span> Apps</span>
                            </p>
                          </div> */}
                          </div>
                        );
                      })
                    : allPublications
                      .filter((el) =>
                        el?.name.toLowerCase().includes(search.toLowerCase())
                      )
                      .map((item) => {
                        console.log(item, "item")
                        return (
                          <div
                            onClick={(e) => {
                              setSelectedPublication(item);
                              localStorage.setItem(
                                "selectedPublication",
                                JSON.stringify(item)
                              );
                              setStep(null);
                            }}
                            className="changeapp-data"
                            style={{
                              width: "100%",
                              height: "80px",
                              borderRadius: "10px",
                              border: "0.5px solid #e7e7e7",
                              borderWidth: "0px 1px 0px 1px",
                              display: "flex",
                              marginTop: "15px",
                              cursor: "pointer",
                            }}
                            // onClick={handleBrandEvent}
                            key={item._id}
                          >
                            <div style={{ width: "20%" }}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  width: "70%",
                                  height: "80%",
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                }}
                                src={item?.profile_pic}
                                alt="imgg"
                              />
                            </div>
                            <div style={{ width: "60%" }}>
                              <h4
                                style={{
                                  color: "#182542",
                                  paddingTop: "15px",
                                  fontWeight: "bolder",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item?.name}
                              </h4>
                              <p
                                style={{
                                  color: "#182542",
                                  paddingLeft: "10px",
                                  lineHeight: "5px",
                                  fontSize: "small",
                                }}
                              >
                                {item?._id}
                              </p>
                            </div>
                            {/* <div style={{ width: "20%" }}>
                        <p
                          style={{
                            color: "#182542",
                            paddingTop: "30px",
                            fontWeight: "bolder",
                            paddingLeft: "10px",
                          }}
                          key={item?._id}
                        >
                          {item?.app_data?.length}
                          <span> Apps</span>
                        </p>
                      </div> */}
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>

            <br />

            <div
              className={classNames.footerBtns}
              style={{ display: "flex", marginTop: "-100px" }}
            >
              <div
                onClick={(e) => setStep("")}
                className={classNames.btnSettings}
                style={{ backgroundColor: "#f3f3f3", color: "#292929" }}
              >
                <span>Back</span>
              </div>
              <div
                // onClick={(e) => checkPinValidity()}
                className={classNames.btnSettings}
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                }}
              >
                <span style={{ opacity: "0.5" }}>Next</span>
              </div>
            </div>
          </>
        );
      case "ChangeShareToken":
        return (
          <>
            <div className="modalStyle">
              <div className="headerSection">Settings</div>
              <div className="breadcrumb">
                <span className="crumbs" onClick={(e) => setStep("/Terminal")}>
                  Settings
                </span>
                {"> "}

                <span
                  className="crumbs"
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    paddingLeft: "5px",
                  }}
                >
                  Change ShareToken
                </span>
              </div>
              <br />
              <div
                style={{
                  border: "0.5px solid #E5E5E5",
                  height: "62px",
                  borderRadius: "10px",
                  padding: "0px 20px",
                  marginBottom: "10px",
                }}
              >
                <input
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  placeholder="Search ShareToken..."
                  style={{ border: "none", height: "100%", width: "100%" }}
                />
              </div>
              <div
                style={{
                  height: "260px",
                  overflowY: "scroll",
                }}
              >
                <div>
                  {allShareToken
                    .filter((item) =>
                      item?.apps_metadata[0].app_name
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    ?.map((item) => {
                      return (
                        <div
                          onClick={(e) => {
                            setSelectedShareToken(JSON.stringify(item));
                            // setCompLoader(true)
                            localStorage.setItem(
                              "selectedShareCoin",
                              JSON.stringify(item)
                            );
                            // setSelectedAppData(
                            //   item?.token_profile_data.coinSymbol
                            // );
                            setStep(null);
                          }}
                          className="terminalCards"
                        >
                          <img
                            src={
                              item?.token_profile_data.coinImage
                                ? item?.token_profile_data.coinImage
                                : defaultImg
                            }
                            alt=""
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "100%",
                            }}
                          />
                          <div style={{ paddingLeft: "10px" }}>
                            <div style={{ fontWeight: 700 }}>
                              {item?.apps_metadata[0].app_name}
                            </div>
                            <div style={{ fontSize: "10px", fontWeight: 400 }}>
                              {item?.token_profile_data.coinSymbol}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <br />

            <div className="footerBtns" style={{ display: "flex" }}>
              <div
                onClick={(e) => setModalOpen(false)}
                className="individualButtons"
                style={{ background: "white", color: "#292929" }}
              >
                <span>Close</span>
              </div>
              <div
                onClick={(e) => {
                  // setBankerEmail(newBankerData?.hardCoded[0]?.data?.email);
                  setStep("");
                }}
                className="individualButtons"
                style={{
                  background: selectedApp.appColor,
                  borderLeft: "1px solid gray",
                  color: "white",
                }}
              >
                <span>Back</span>
              </div>
            </div>
          </>
        );


      default:
        return conditionalDefaultLayout();
    }
  }, [
    loginData.user.email,
    logoParam,
    onClose,
    onSuccess,
    pin,
    step,
    bankerEmail,
    bankerProfile,
    pathname,
    modalOpen,
    localStorage.getItem("newBankerData"),
    searchQuery,
  ]);

  return (
    <>
      <div className={classNames.settingsModal}>
        <div
          className={classNames.overlayClose}
          onClick={() => {
            try {
              onClose();
            } catch (error) { }
          }}
        />
        <div className={classNames.settingsCard}>{stepRender}</div>
      </div>
    </>
  );
}

export default AdminModal;
