import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../../static/images/loading.gif";

import bonds from "../../../static/images/sidebarIcons/bonds.svg";

import "./newBondTier.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";

import { GlobalContex } from "../../../globalContex";

// import { useBankerDetails } from "../../../queryHooks";
// import jwt from "jsonwebtoken";
// import { sign } from "react-native-pure-jwt";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const NewLxProfile = ({ step, setStep, loading, setLoading,setMainMenu }) => {
  const navigate = useNavigate();

  const {
    loginData,
    bankerEmail,
    allBankers,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    setGlobalMenuAdd,
    FormatCurrency,
    FormatNumber,
    allCoins,
  } = useContext(GlobalContex);

  // Form Variables

  const [banker, setBanker] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [icon, setIcon] = useState("");
  const [iconLoading, setIconLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  useEffect(() => {
    console.log(loading, "kjqwbdkwed");
  }, [loading]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading1) => {
    setLoading1(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://publications.apimachine.com/article/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data[0].urlName);
    setLoading1(false);
  };

  const handleReplaceTab = () => {
    const item = {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    };
    if (
      tabs.findIndex((o) => o.menuName === item.menuName) < 0 ||
      tabs.length < 1
    ) {
      const index = tabs.findIndex((o) => o.menuName === selectedTab.menuName);
      console.log(
        tabs.findIndex((o) => o.menuName === selectedTab.menuName),
        selectedTab,
        "jhwgjwhe"
      );
      tabs.splice(index, 1, item);
      setSelectedTab(tabs.length < 1 ? tabs[index] : item);
    } else {
      setSelectedTab(item);
    }
  };

  function getContent() {
    switch (step) {
      case "Success":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
          //   handleReplaceTab();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                You Have Successfully Created a New LX Profile.
              </div>
            </div>
          </div>
        );

      case "Token Expired":
        return (
          <>
            <div className="newConglomerate">
              <div className="succesView">
                <div className="labelItm" style={{ textAlign: "center" }}>
                  Token Expired. Login Again.
                </div>
              </div>
            </div>

            <div className="footerBtns">
              <div onClick={(e) => setGlobalMenuAdd(false)}>Go Back</div>
              <div
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Logout
              </div>
            </div>
          </>
        );

      case "Failed":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
          //   handleReplaceTab();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                {/* Lx Profile Creation Failed */}
                {errMsg ? errMsg : "Lx Profile Creation Failed"}
              </div>
            </div>
          </div>
        );

      //   case "bondAddFailware":
      //     setTimeout(() => {
      //       // navigate("/ventures/Brands");
      //       setGlobalMenuAdd(false);
      //     }, 2000);
      //     return (
      //       <div className="newConglomerate">
      //         <div className="succesView">
      //           <div className="labelItm" style={{ textAlign: "center" }}>
      //             Bond Creation Failed.
      //           </div>
      //         </div>
      //       </div>
      //     );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Enter First Name  */}
                <div className="name">Enter First Name</div>
                <div className="inputWrap">
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="First Name"
                  />
                </div>
                {/* Enter Last Name  */}
                <div className="name">Enter Last Name</div>
                <div className="inputWrap">
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Last Name"
                  />
                </div>
                {/* Create LX Username  */}
                <div className="name">Create LX Username</div>
                <div className="inputWrap">
                  <input
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter LX Name"
                  />
                </div>

                {/* Upload Logos */}
                <div className="name">Upload Logos</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text" style={{ fontWeight: "700" }}>
                      Profile Picture
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>

                <div className="space"></div>
              </Scrollbars>
            </div>

            <div className="footerBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}

                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className={
                  firstName && lastName && userName && icon
                    ? "btnSelected1"
                    : "btnSubmit"
                }
                style={{
                  fontSize: "20px",
                  opacity: firstName && lastName && userName && icon ? 1 : 0.5,
                }}
                onClick={() => validate()}
              >
                Submit App
              </div>
            </div>
          </>
        );
    }
  }

  const validate = () => {
    if (firstName && lastName && userName && icon) {
      setLoading(true);
      createLxProfile();
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  };

  const createLxProfile = () => {
    setLoading(true);
    axios
      .post(
        `https://teller2.apimachine.com/lxUser/register`,
        {
          profilePicURL: icon,
          firstName: firstName,
          lastName: lastName,
          lxTag: userName,
        },
        {
          headers: {
            email: bankerEmail,
            token: loginData?.idToken,
          },
        }
      )
      .then(({ data }) => {
        if (data.status === false) {
          setStep("Failed");
          setErrMsg(data.message);
          toast.error(data.message || "API Error");
        } else setStep("Success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        // setBondError(error?.response?.data?.message);
        setStep("Failed");
        setErrMsg(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* {getContent()} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#18191D" }}>
            Creating New Lx Profile ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
};

export default NewLxProfile;
