import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../../globalContex";
import copyToClipboard from "../../../../static/images/icons/copy.svg";

const Funds = () => {
  const { bankerEmail, collapse } = useContext(GlobalContex);

  const [bondSelected, setBondSelected] = useState();
  const [currenciesData, setCurrenciesData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [allSoldOfferings, setAllSoldOfferings] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [allFunds, setAllFunds] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?banker_email=${bankerEmail}&investmentType=funds`
      )
      .then((res) => {
        console.log(res.data, "kewnckwenc");
        setAllFunds(res.data.paths);
        setDataLoading(false);
      });
  }, [bankerEmail, selectedCoin]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          className="listGrid"
          style={{ gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1.5fr" }}
        >
          <div>Coin</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Asset
          </div>
          <div>Price</div>
          <div>Market Cap</div>
          <div>Supply</div>
          <div>Launch Date</div>
        </div>
      </div>
      <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
          height: window.innerHeight - 175,
          overflowY: "scroll",
        }}
      >
        {!dataLoading ? (
          allFunds?.length > 0 ? (
            allFunds.map((item) => {
              return (
                <div
                  className="listDataGrid"
                  style={{
                    gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1.5fr",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={item.token_profile_data.coinImage}
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                      }}
                      // className={classNames.icon}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                      <div className="title">
                        {/* {bond.coinsData[0].coin} */}
                        {item.token_profile_data.coinName} &nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              item.token_profile_data.coinName
                            );
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                      <div className="subtitle">
                        {item.token_profile_data.coinSymbol}&nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              item.token_profile_data.coinSymbol
                            );
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={item.asset_metaData.coinImage}
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                      }}
                      // className={classNames.icon}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                      <div className="title">
                        {/* {bond.coinsData[0].coin} */}
                        {item.asset_metaData.coinName} &nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              item.asset_metaData.coinName
                            );
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                      <div className="subtitle">
                        {item.asset}&nbsp;
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(item.asset);
                          }}
                          src={copyToClipboard}
                          alt=""
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">{item?.token_price.toFixed(2)}</div>
                    <div className="subtitle">{item.asset}</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">
                      {item?.asset_balance.toFixed(2)}
                    </div>
                    <div className="subtitle">{item.asset}</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">
                      {item?.circulating_supply.toFixed(2)}
                    </div>
                    <div className="subtitle">{item.asset}</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="title">
                      {new Date(item?.date).toUTCString().substring(0, 16)}
                    </div>
                    <div className="subtitle">{item.asset}</div>
                  </div>
                </div>

                // <div className="bondsUsersGrid">
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              No Records Found
            </div>
          )
        ) : (
          Array(10)
            .fill("")
            .map((item, i) => {
              return (
                <div
                  className="listDataGrid post"
                  style={{
                    width: "100%",
                    // height: "120px",
                    gridTemplateColumns: "2fr 1.5fr 1fr 1fr 1fr",
                    // borderBottom: "solid 0.5px #EEEEEE",
                  }}
                >
                  <div
                    key={i}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Skeleton
                      className="dp"
                      circle
                      width={50}
                      height={50}
                      style={{ marginRight: "20px" }}
                    />
                    <div className="userDetail" style={{ paddingTop: "10px" }}>
                      <Skeleton width={100} />
                    </div>
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                </div>
              );
            })
        )}
      </div>
    </>
  );
};

export default Funds;
