import axios from "axios";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../globalContex";

function BondTemplate({ bondAsset, bondTemplate, setBondTemplate, onClose }) {
  const { allCoins, bankerEmail } = useContext(GlobalContex);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);
  const bondTypes = [{ name: "Fixed Income" }];

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/iced/banker/custom/bond/get`, {
        params: {
          email: bankerEmail,
          coin: bondAsset?.coinSymbol,
          status: "active",
        },
      })
      .then(({ data }) => {
        setTemplates(data);
      });
  }, []);

  return (
    <Fragment>
      <div className="titleOp" style={{ marginTop: "0px" }}>
        Select Connected Bond Template
      </div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bond Template....|"
        />
      </div>
      <Scrollbars className="searchList">
        {templates?.custom_bonds
          ?.filter((o) =>
            o?.coinsData[0]?.coin?.toLowerCase().includes(search?.toLowerCase())
          )
          ?.map((item) => (
            <div
              className="user1"
              key={item.name}
              onClick={() => {
                setBondTemplate(item);
                onClose();
              }}
              style={{
                fontSize: "16px",
                fontWeight: 500,
                height: "100px",
                padding: "20px",
                borderBottom: "1px solid #e7e7e7",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      allCoins.find(
                        (o) => o.coinSymbol === item.coinsData[0].coin
                      ).coinImage
                    }
                    alt=""
                    style={{
                      borderRadius: "50%",
                      width: "22px",
                      height: "22px",
                    }}
                  />
                  <div style={{ paddingLeft: "10px" }}>
                    {
                      allCoins.find(
                        (o) => o.coinSymbol === item.coinsData[0].coin
                      ).coinName
                    }
                  </div>
                </div>

                <input type="checkbox" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <div>
                  {item.coinsData[0].bondCost}&nbsp;{item.coinsData[0].coin}
                </div>
                <div>{item?.monthly_interest_rate?.toFixed(4)}%/Month</div>
                <div>{item.days} Days</div>
              </div>
            </div>
          ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default BondTemplate;
