import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";
import ActionIndex from "./ProductsSubDrawer/ActionIndex"

const Products = () => {
    const { loginData, globalSearch, collapse, getDisplayDate, getDays, fetchProductData, setFetchProductData,productSubDrawer, setProductSubDrawer,refetchProduct, setRefechProduct } = useContext(GlobalContex);

    const [allProducts, setAllProducts] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("")
    //   const [page, setPage] = useState(0);

    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;

    // useEffect(() => {
    //   setDataLoading(true);
    //   console.log(Math.ceil((window.innerHeight - 175) / 90), "innerHeight")
    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;
    //   axios
    //     .get(
    //       `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?skip=0&limit=${limit}`
    //     )
    //     .then((res) => {
    //       setAllMarketsverseUsers(res.data.users);
    //       setDataLoading(false);
    //     });
    // }, [])

    const fetchData = async () => {
        // console.log(pageNumber, "page")
        setDataLoading(true);

        // console.log("call API")

        setDataLoading(true);

        try {
            const response = await fetch(`https://comms.globalxchange.io/gxb/product/get?product_created_by=${loginData?.user?.email}`);
            const jsonData = await response.json();
            const newData = jsonData.products;

            setAllProducts(newData);
            console.log(newData, "newData")
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setDataLoading(false);

    };






    useEffect(() => {
        fetchData();
    }, [fetchProductData,refetchProduct, setRefechProduct]);



    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div id="usersData"
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflow: 'auto'
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.product_name
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?.product_code
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        {/* <AppsSubDrawer allApps={allApps} /> */}
                        <ActionIndex selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}/>
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        {/* <AppsSubDrawer allApps={allApps} /> */}
                        <ActionIndex selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}/>
                    </div>
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Name</div>
                <div style={{ textAlign: "left" }}>Identifier</div>
                <div style={{ textAlign: "left" }}>Category</div>
                <div style={{ textAlign: "left" }}>Gross</div>
                <div style={{ textAlign: "left" }}>Status</div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        let imageDisp
        if (item?.product_icon || item?.product_icon !== "") {
            imageDisp = item?.product_icon
        } else {
            imageDisp = defaultImg
        }
        return (
            <div
                onClick={(e) => {
                //   setSelectedMcbDashboardApp(item);
                  setSelectedProduct(item);
                  setProductSubDrawer(true);
                }}
                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={imageDisp}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                        <div className="title">{item?.product_name} &nbsp;</div>
                        <div className="subtitle">Created on {getDisplayDate(item?.date)}</div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginRight: "20px",
                    }}
                >
                    <div className="title">{item?.product_code}</div>
                    <div className="subtitle">{item.product_id}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    {/* <img
                        src={item?.brand_country ? item?.brand_country?.image : defaultImg}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                        }}
                    /> */}
                    <div>
                        <div className="title">{item?.product_category_code}</div>
                        <div className="subtitle">Created on {item?.sub_category_code}</div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <div className="title">$--</div>
                    {/* <div className="subtitle">{item?.subCategory}</div> */}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <div className="title">{item?.status}</div>
                    {/* <div className="subtitle">$0.00</div> */}
                </div>
            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={70} />
                            {/* <Skeleton width={50} style={{ height: "10px" }} /> */}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={100} />
                            {/* <Skeleton width={40} style={{ height: "10px" }} /> */}
                        </div>
                    </div>
                );
            });
    };

    return (
        <div>
            {conditionalResposiveView(
                allProducts,
                dataLoading,
                "1.2fr 1fr 1fr 0.6fr 0.5fr", // Desktop view Grid columns
                "250px 200px 300px 200px 70px" // Mobile view Grid columns
            )}
        </div>
    );
};

export default Products;
