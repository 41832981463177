import React, { useContext, useState } from "react";
import "../subdrawer.scss";
import axios from "axios";
import close from "../../../../../static/images/icons/close1.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";
import UploadIcon from "../../../../../static/images/sidebarIcons/uploadicon.svg"
import { GlobalContex } from "../../../../../globalContex";


const Actions = ({ step, setStep }) => {
    const { showSubDraw, setShowSubDraw, holderData,
        setHolderData, loginData, contractsData } = useContext(GlobalContex);
    const [withdrawData, setWithdrawData] = useState("")
    let disp
    if (step === "loading" || step === "success") {
        disp = "none"
    } else {
        disp = ""
    }


    const conditionalPaths = () => {
        switch (step) {
            case "loading":
                return (
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingAnimation logoAnim sideDraw={true} />
                        <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                textAlign: "center",
                                // marginBottom: "20px",
                                color: "#182542",
                                fontSize: "18px",
                                fontWeight: "700"
                            }}
                        >
                            Withdrawing From All Dividend Vaults
                        </div>
                    </div>
                )
            case "Token Expired":
                return (
                    <div style={{ padding: "30px", height: "90px" }}>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            {/* <img src={lock} alt="" style={{ width: "20px" }} /> */}
                            <div style={{ paddingLeft: "15px" }}>Logout</div>
                        </div>
                    </div>
                );
            case "step1":
                return (
                    <>
                        <div className="defaultcard">
                            <p>What Would You Like To Do?</p>
                        </div>
                        <div className="boxtext" onClick={() => setStep("step2")}>
                            <p className="thefonttext">Add Document</p>
                        </div>

                    </>
                )
            case "step2":
                return (
                    <>
                        <div className="defaultcard">
                            <p>Name Of Bond</p>
                            <div>
                                <input type="text" placeholder="Name..." className="theinput-box" />
                            </div>
                            <br />
                            <br />
                            <p>Select Document Type</p>
                            <div style={{ display: "flex" }}>
                                <p className="filetype">Image</p>
                                &nbsp;&nbsp;
                                <p className="filetype">File</p>
                            </div>
                            <br />
                            <br />
                            <p>Upload Document</p>
                            <img src={UploadIcon} alt="upload-icon" className="theiconupld" />
                            <div className="addbtn">
                                <p className="doctext">Add Document</p>
                            </div>
                        </div>
                    </>
                )
            case "success":
                return (
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p className="success-title">Congratulations</p>

                            </div>
                            <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                                <img src={close} alt="" />
                            </div>
                        </div>
                        <p className="success-desc">You Have Successfully Transferred The Following Amounts From {holderData.name}'s {JSON.parse(localStorage.getItem("selectedShareCoin"))?.apps_metadata[0].app_code} Dividend Vaults to {JSON.parse(localStorage.getItem("selectedShareCoin"))?.apps_metadata[0].app_code} Liquid Vaults</p>
                        <div className="thevalue">
                            <p>Value Of Withdrawal:</p>
                            <p className="numvalue">${withdrawData.totalWithdrawAmountUSD.toFixed(4)} USD</p>
                        </div>
                        <div className="thescrolldata">
                            {Object.entries(withdrawData.totalWithdrawData).map((item) => {
                                return (
                                    <>
                                        <div className="tokendata">
                                            <p className="thecoin thefonttext">{item[0]}</p>
                                            <p className="thecoin thefonttext">{item[1].toFixed(10)}</p>
                                        </div>
                                        <br />
                                    </>
                                )
                            })}
                        </div>
                    </div>
                )
            default:
                return (
                    <>
                        <div className="defaultcard">
                            <p>What Would You Like To Do?</p>
                        </div>
                        <div className="boxtext" onClick={() => setStep("step1")}>
                            <p className="thefonttext">Create Bond Prospectus</p>
                        </div>
                        <div className="boxtext" style={{ opacity: 0.4 }}>
                            <p className="thefonttext">Edit Bond Prospectus</p>
                        </div>
                        <div className="boxtext" style={{ opacity: 0.4 }}>
                            <p className="thefonttext">Delete Prospectus</p>
                        </div>
                    </>
                )
        }
    }

    const conditionalcrumbs = () => {
        switch (step) {
            case "step1":
            case "step2":
                return (
                    <div style={{display:"flex"}}>
                        <p className="crumb" onClick={() => setStep("default")}>Actions {"->"}</p>
                        &nbsp;
                        <p className="thebreadcrumb">Create</p>
                    </div>
                )
            default:
                return (
                    <p className="thebreadcrumb">Actions</p>
                )
        }
    }


    return (
        <div
            style={{
                padding: "30px",
                height: window.innerHeight - 215,
                overflow: "scroll"
            }}>
            <div style={{ display: disp }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            // src={holderData.profile_img ? holderData.profile_img : defaultImg}
                            src={contractsData.userData?.profile_img ? contractsData.userData.profile_img : defaultImg}
                            alt=""
                            style={{ width: "45px", height: "45px", borderRadius: "100%", border: "0.5px solid rgb(231, 231, 231)" }}
                        />
                        <div style={{ paddingLeft: "10px" }}>
                            <div style={{ fontSize: "22px" }}>
                                {contractsData.bond_template_id}
                            </div>
                            {conditionalcrumbs()}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                            <img src={close} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {conditionalPaths()}
        </div>
    )
}


export default Actions