import React from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";
import TabsLayout from "../../globalComponents/TabsLayout";

import crm from "../../static/images/sidebarIcons/crm.svg";
import dash from "../../static/images/sidebarIcons/dash.svg";
import affiliates from "../../static/images/sidebarIcons/affiliates.svg";
import vaults from "../../static/images/sidebarIcons/vaults.svg";
import terminal from "../../static/images/sidebarIcons/terminal.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";
import loans from "../../static/images/sidebarIcons/socially.svg";
import Lock from "../../static/images/icons/lock.svg";
import defaultImg from "../../static/images/icons/app_placeholder.png";
import VaultPage from "../../globalComponents/VaultPage";
import MobileMenu from "../../globalComponents/MobileMenu";
import OtcDesk from "./OTCDesksDahboard";
import Deposite from "./DepositPath"
import Otcpayment from "./OtcPayment"
import payments from "../../static/images/sidebarIcons/payments.png"
const Capitalized = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    showMobileMenu,
    globalMenu,
  } = useContext(GlobalContex);

  const menuItems = [
    {
      menuName: "Dashboard",
      menuIcon: dash,
      enabled: true,
    },
    {
      menuName: "Payments",
      menuIcon: payments,
      enabled: true,
    },
  ];

  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      default:
        return (
          <>
          </>
        );
        case "Payments":
          return <Otcpayment />;
        case "Dashboard":
          return <OtcDesk />;
        case "Vaults":
          return <Deposite />;
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={menuItems} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <NavBar
              tabs={tabs}
              tabSelected={tabSelected}
              setTabSelected={setTabSelected}
              enabledFilters={[true, true, true, false, false]}
            /> */}
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
            {/* <div className="filterRow"></div> */}
            {/* <div style={{ flexGrow: 1 }}>{conditionalPages()}</div> */}
          </div>
        </div>
      </div>

      {showMobileMenu ? (
        <MobileMenu localMenu={menuItems} />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}
      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default Capitalized;
