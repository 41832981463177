import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAllergies, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
import SelectCourse from "./selectCourse";
// import AppsList from "./AppsList";
// import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";

import plus from "../../../static/images/globaldrawer/plus.svg";

// import AppList from "./AppsList";
import LoadingAnimation from "../../LoadingAnimation";
// import FontList from "./FontList";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com";


const NewCourseVideo = ({
    step,
    setStep,
    setMainMenu,
    loading,
    setLoading,
}) => {
    const navigate = useNavigate();

    const {
        loginData,
        bankerEmail,
        refetchAppData,
        setRefetchAppData,
        globalMenuAdd,
        setGlobalMenuAdd,
        tabSelected,
        setTabSelected,
        setSlider,
        refetchCourses,
        setRefetchCourses
    } = useContext(GlobalContex);


    //Publishers New Publication
    const [name, setName] = useState("");
    const [app, setApp] = useState("");
    const [description, setDescription] = useState("");
    const [orderNum, setOrderNum] = useState("");
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [selectCourse, setSelectCourse] = useState("")
    const [coverPhoto, setCoverPhoto] = useState("");
    const [coverPhotoLoading, setCoverPhotoLoading] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [videoLength, setVideoLength] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        localStorage.setItem("step", step);
    }, [step]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    // const uploadImage = async (e, setImage, setLoading) => {
    //     setLoading(true);
    //     const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //         e.target.files[0].name.lastIndexOf(".")
    //     )}`;
    //     const formData = new FormData();
    //     const file = renameFile(e.target.files[0], fileName);
    //     formData.append("files", file);
    //     const path_inside_brain = "root/";

    //     const jwts = await signJwt(fileName, emailDev, secret);
    //     console.log(jwts, "lkjkswedcf");
    //     let { data } = await Axios.post(
    //         `https://publications.apimachine.com/article/upload`,
    //         formData,
    //         {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //             },
    //         }
    //     );
    //     setImage(data[0].urlName);
    //     setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
          e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';
    
        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
          `https://publications.apimachine.com/article/upload`,
          formData,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
      };

    function getContent() {
        switch (step) {
            case "success":
                return (
                    <div className="newConglomerate">
                        <div className="succesView">
                            <div className="labelItm" style={{ textAlign: "center" }}>
                                You Have Successfully Created the video {selectCourse.name}. You Will Be
                                Redirected To The Updated Video List Automatically
                            </div>
                        </div>
                    </div>
                );
            //   case "app":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <AppList setApp={setApp} onClose={() => setStep("")} />
            //       </div>
            //     );
            case "select section":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <SelectCourse setSelectCourse={setSelectCourse} onClose={() => setStep("")} />
                    </div>
                );
            //   case "Font":
            //     return (
            //       <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
            //         <FontList setTextFont={setTextFont} onClose={() => setStep("")} />
            //       </div>
            //     );

            case "Token Expired":
                return (
                    <>
                        <div className="newConglomerate">
                            <div className="succesView">
                                <div className="labelItm" style={{ textAlign: "center" }}>
                                    Token Expired. Login Again.
                                </div>
                            </div>
                        </div>

                        <div className="footerBtns">
                            <div
                            // onClick={(e) => handleBackStep()}
                            >
                                Go Back
                            </div>
                            <div
                                onClick={(e) => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                Logout
                            </div>
                        </div>
                    </>
                );

            default:
                return (
                    <>
                        <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                            <Scrollbars
                                className="scrollForm"
                                renderTrackHorizontal={() => <div />}
                                renderThumbHorizontal={() => <div />}
                                renderTrackVertical={() => <div />}
                                renderThumbVertical={() => <div />}
                            >
                                {/* Publication Name */}


                                {/* Select Connected App */}
                                {/* <div className="name">Select Connected App</div>
                {app?.app_code ? (
                  <div className="user" onClick={() => setStep("app")}>
                    <img className="dp" src={app?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{app?.app_name}</div>
                      <div className="email">{app?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("app")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select An App"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )} */}
                                {/* Select Country */}
                                <div className="name">Which section are you adding this video to?</div>
                                {selectCourse?.section_name ? (
                                    <div className="countryuser" onClick={() => setStep("select section")}>
                                        {/* <img className="dp" src={selectCourse?.profile_pic} alt="" /> */}
                                        {/* &nbsp;&nbsp; */}
                                        <div>
                                            <div className="name">{selectCourse?.section_name}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="inputWrap" style={{ cursor: "pointer" }} onClick={() => setStep("select section")}>
                                        <input
                                            type="text"
                                            className="text"
                                            placeholder="Select Section"
                                            readOnly
                                            style={{ cursor: "pointer" }}
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </div>
                                    </div>
                                )}

                                <div className="name">What is the name of the video?</div>
                                <div className="inputWrap">
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="Name..."
                                    />
                                </div>

                                {/* Publication Description */}
                                <div className="name">What is the description for this video?</div>
                                {/* <div className="inputWrap"> */}
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    type="text"
                                    className="text-textarea"
                                    placeholder="Description..."
                                    rows={5}
                                    cols={40}
                                />
                                {/* </div> */}

                                <div className="name">What is the link for this video?</div>
                                <div className="inputWrap">
                                    <input
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="Enter video link..."
                                    />
                                </div>
                                <div className="name">Upload a icon</div>
                                <div>
                                    <div
                                        className="filesUpload"
                                        style={{ justifyContent: "flex-start" }}
                                    >
                                        <label className="fileInp icon" style={{
                                            height: "95px",
                                            borderRadius: "15px"
                                        }}>
                                            <img
                                                className={`${Boolean(colouredIcon)}`}
                                                src={
                                                    colouredIconLoading
                                                        ? loadingGif
                                                        : colouredIcon || cloudUploadIcon
                                                }
                                                alt=""
                                                style={{ width: "40px" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    uploadImage(
                                                        e,
                                                        setColouredIcon,
                                                        setColouredIconLoading
                                                    );
                                                }}
                                                accept="image/*"
                                            />
                                            <div
                                                className="text"
                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                            >
                                                {/* Colored Icon */}
                                            </div>
                                            <div className="hovTxt">
                                                Upload
                                                <br />
                                                New
                                            </div>
                                        </label>

                                    </div>
                                </div>
                                <div className="name">How long is this video</div>
                                <div className="inputWrap">
                                    <input
                                        value={videoLength}
                                        onChange={(e) => setVideoLength(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="00:00"
                                    />
                                    <div
                                        className="btnCheck"
                                        style={{
                                            border: "none"
                                        }}
                                    >
                                        <div type="text" >Minutes</div>

                                    </div>
                                </div>
                                {/* Publication Webiste */}
                                <div className="name">What is the ordering of this video inside of the section?</div>
                                <div className="inputWrap">
                                    <div>
                                    </div>
                                    <input
                                        value={orderNum}
                                        onChange={(e) => setOrderNum(e.target.value)}
                                        type="number"
                                        className="text"
                                        placeholder="Enter video order..."
                                    />
                                </div>

                                <div>

                                    &nbsp;
                                    <div
                                        className="NextStepBtn"
                                        onClick={() => validate()}
                                        style={{
                                            opacity:
                                                name &&
                                                description &&
                                                link &&
                                                colouredIcon &&
                                                videoLength &&
                                                orderNum &&
                                                selectCourse
                                                    ? 1
                                                    : 0.3,
                                        }}
                                    >
                                        Add Video
                                    </div>
                                    &nbsp;
                                </div>
                                <div className="space"></div>
                            </Scrollbars>
                        </div>



                        {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{ fontWeight: 700, opacity: visibleSubmit ? 1 : 0.3 }}
              >
                Submit App
              </div>
            </div> */}
                    </>
                );
        }
    }

    const validate = () => {
        if (
            name &&
            description &&
            link &&
            colouredIcon &&
            videoLength &&
            orderNum &&
            selectCourse
        ) {
            setVisibleSubmit(true);
            createNewVideo();
        } else {
            toast.warning("All Fields Are Mandatory");
        }
    };

    const createNewVideo = () => {
        setLoading(true);
        axios
            .post(`https://publications.apimachine.com/videosections/create`, {
                section_id: selectCourse._id,
                name: name,
                description: description,
                image: colouredIcon,
                video_link: link,
                video_length: videoLength,
                video_order: orderNum,
            })
            .then(({ data }) => {
                setLoading(false);
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    toast.error(data.message || "API Error");
                } else {
                    setStep("success");
                    setTimeout(() => {
                        setStep("");
                        setMainMenu("");
                        setTabSelected("Videos");
                        setSlider(false);
                        setStep("");
                        setRefetchCourses(!refetchCourses);
                    }, 2000);
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
    };

    // const createApp = () => {
    //   setLoading(true);
    //   axios
    //     .post(`https://comms.globalxchange.io/gxb/apps/create`, {
    //       app_name: appName,
    //       app_code: appCode,
    //       profile_start_code: appCode,
    //       parent_group_id: conglomerate.group_id,
    //       GXNativeapp: true,
    //       mobileApp: true,
    //       short_description: shortDesc,
    //       long_description: longDesc,
    //       categories: categories.map((o) => o.category_id),
    //       color_codes: [
    //         {
    //           primarycolourcode: primaryColour,
    //         },
    //       ],
    //       user_label: callUsers,
    //       app_icon: colouredIcon,
    //       white_logo: whiteLogo2,
    //       website: website,
    //       cover_photo: coverPhoto,
    //       operatorid: brand.operator_id,
    //       email: bankerEmail,
    //       token: loginData.idToken,
    //       country: country.name,
    //       data: {
    //         color_logo: colouredLogo,
    //         white_icon: whiteLogo,
    //         website_description: websiteDesc,
    //         website_title: websiteTitle,
    //         currencyname: displayCurrency.coinSymbol,
    //       },
    //     })
    //     .then(({ data }) => {
    //       if (data.status === false) {
    //         toast.success(data.message || "API Error");
    //       } else setStep("success");
    //     })
    //     .catch((error) => {
    //       toast.error(error?.response?.data?.message);
    //     })
    //     .finally(() => setLoading(false));
    // };

    return (
        <>
            {loading ? (
                // <div
                //   style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "70vh",
                //   }}
                // >
                //   <div className="loader"></div>
                //   <div style={{ padding: "20px", fontWeight: 600, color: "#18191D" }}>
                //     Creating New Publication ...
                //   </div>
                // </div>
                <div
                    style={{
                        height: window.innerHeight,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "40px",
                            fontWeight: 600,
                            color: "#18191D",
                            fontSize: "20px",
                        }}
                    >
                        Creating {name} ...
                    </div>
                </div>
            ) : (
                <>
                    {getContent()}
                    <ToastContainer />
                </>
            )}
        </>
    );
};

export default NewCourseVideo;
