import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContex } from "../../../../globalContex";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import Skeleton from "react-loading-skeleton";

// import { TransitionGroup, CSSTransition } from "react-transition-group";

const PerUserBondHashList = ({
  selectedUserBondHash,
  setSelectedUserBondHash,
  allUserData,
  setAllUserData,
}) => {
  const {
    allCoins,
    collapse,
    bankerEmail,
    selectedFilter2,
    startDate,
    endDate,
    setSelectedFilter2,
    setSelectedFilter21,
  } = useContext(GlobalContex);

  const usdCoinImage =
    "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png";

  const [bondHashes, setBondHashes] = useState([]);
  const [collapseBondHash, setCollapseBondHash] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFilter2 !== null) {
      //   console.log(allUserData.users, "jhwvdkwekdw");
      const foundObj = allUserData.users.find((o) => o._id === selectedFilter2);
      if (foundObj) {
        setSelectedUserBondHash(foundObj);
      }
    }
  }, [selectedFilter2, startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate && selectedUserBondHash) {
      setLoading(true);
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedUserBondHash?._id}&from_date=${startDate}&to_date=${endDate}`
        )
        .then((res) => {
          setBondHashes(res.data.users[0].coinsData);
          setSelectedUserBondHash(res.data.users[0]);
          setLoading(false);
        });
    } else {
      setLoading(true);
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedUserBondHash?._id}`
        )
        .then((res) => {
          setBondHashes(res.data.users[0].coinsData);
          setSelectedUserBondHash(res.data.users[0]);
          setLoading(false);
        });
    }
  }, [startDate, endDate]);

  //   useEffect(() => {
  //     if (selectedFilter2 && startDate && endDate) {
  //       axios
  //         .get(
  //           `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedFilter2}&from_date=${startDate}&to_date=${endDate}`
  //         )
  //         .then((res) => {
  //           setBondHashes(res.data.users[0].bondsData);
  //         });
  //     } else if (selectedFilter2 && !endDate) {
  //       axios
  //         .get(
  //           `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedFilter2}`
  //         )
  //         .then((res) => {
  //           setBondHashes(res.data.users[0].bondsData);
  //         });
  //     } else {
  //       axios
  //         .get(
  //           `https://comms.globalxchange.io/coin/iced/banker/earned/spread/per/customer/get?banker_email=${bankerEmail}&customer_email=${selectedFilter2}`
  //         )
  //         .then((res) => {
  //           setBondHashes(res.data.users[0].bondsData);
  //         });
  //     }
  //   }, [bankerEmail, selectedFilter2, startDate, endDate]);

  return (
    <>
      <div
        style={{
          height: window.innerHeight - 135,
          overflowY: "scroll",
          color: "#050505",
        }}
        // style={{
        //   position: "fixed",
        //   top: "130px",
        //   bottom: "10px",
        //   left: collapse ? "95px" : "395px",
        //   right: "0px",
        //   overflowY: "scroll",

        // }}
      >
        <>
          <div className="cardTop">
            <div style={{ display: "flex" }}>
              <div>
                <img
                  src={
                    selectedUserBondHash?.userDetail.profile_img
                      ? selectedUserBondHash?.userDetail.profile_img
                      : defaultImg
                  }
                  alt=""
                  width="35px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ paddingLeft: "8px" }}>
                <div className="userName">
                  {selectedUserBondHash?.userDetail.name}
                </div>
                <div className="userNameSubtitle">
                  {selectedUserBondHash?._id}
                </div>
              </div>
            </div>
            <div className="rightAlign" style={{ paddingLeft: "8px" }}>
              <div className="userName">
                {selectedUserBondHash?.bondsCount} Bond Hashes
              </div>
              <div
                className="userNameSubtitle clickable"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setSelectedUserBondHash(null);
                  setSelectedFilter2(null);
                  setSelectedFilter21(null);
                }}
              >
                Close Bond Hash List
              </div>
            </div>
          </div>
          <div className="cardBottom">
            <div className="cards">
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35px", height: "35px" }}>
                    <img
                      src={usdCoinImage}
                      alt=""
                      style={{ borderRadius: "50%", width: "100%" }}
                    />
                  </div>
                  <div className="assetName">All Assets</div>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "35px",
                  }}
                >
                  <div className="leftAlign">
                    <div className="statTitle">
                      ${selectedUserBondHash?.totalInterestEarnedUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Earned</div>
                  </div>
                  <div className="rightAlign">
                    <div className="statTitle">
                      ${selectedUserBondHash?.totalCustomComPaidUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Custom Commission</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                  }}
                >
                  <div className="leftAlign">
                    <div className="statTitle">
                      ${selectedUserBondHash?.totalInterestPaidUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Paid</div>
                  </div>
                  <div className="rightAlign">
                    <div className="statTitle">
                      ${selectedUserBondHash?.totalSpreadEarnedUSD.toFixed(2)}
                    </div>
                    <div className="statSubtitle">Interest Spread</div>
                  </div>
                </div>
              </div>
            </div>
            {Object.entries(selectedUserBondHash?.totalSpreadEarned).map(
              ([key, value], i) => (
                <div className="cards">
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "35px", height: "35px" }}>
                        <img
                          src={
                            allCoins.find((o) => o.coinSymbol === key).coinImage
                          }
                          alt=""
                          style={{ borderRadius: "50%", width: "100%" }}
                        />
                      </div>

                      <div className="assetName">
                        {allCoins.find((o) => o.coinSymbol === key).coinName}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "35px",
                      }}
                    >
                      <div className="leftAlign">
                        <div className="statTitle">
                          {selectedUserBondHash?.totalInterestEarned[
                            key
                          ]?.toFixed(4)}
                        </div>
                        <div className="statSubtitle">Interest Earned</div>
                      </div>
                      <div className="rightAlign">
                        <div className="statTitle">
                          {selectedUserBondHash?.totalCustomComPaid[
                            key
                          ]?.toFixed(4)}
                        </div>
                        <div className="statSubtitle">Custom Commission</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "30px",
                      }}
                    >
                      <div className="leftAlign">
                        <div className="statTitle">
                          {selectedUserBondHash?.totalInterestPaid[
                            key
                          ]?.toFixed(4)}
                        </div>
                        <div className="statSubtitle">Interest Paid</div>
                      </div>
                      <div className="rightAlign">
                        <div className="statTitle">
                          {selectedUserBondHash?.totalSpreadEarned[
                            key
                          ]?.toFixed(4)}
                        </div>
                        <div className="statSubtitle">Interest Spread</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          {!loading
            ? bondHashes.map((item1) => {
                return (
                  <>
                    <div
                      className="cardTop"
                      style={{
                        borderBottom: collapseBondHash.find(
                          (o) => o === item1.coin
                        )
                          ? "0.5px solid #E7E7E7"
                          : "",
                        borderTop: collapseBondHash.find(
                          (o) => o === item1.coin
                        )
                          ? "0.5px solid #E7E7E7"
                          : "",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <img
                            src={
                              allCoins.find((o) => o.coinSymbol === item1?.coin)
                                .coinImage
                            }
                            alt=""
                            width="35px"
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div style={{ paddingLeft: "8px" }}>
                          <div className="userName">
                            {
                              allCoins.find((o) => o.coinSymbol === item1?.coin)
                                .coinName
                            }
                          </div>
                          <div className="userNameSubtitle">
                            Total Investment: {item1.investment.toFixed(4)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="rightAlign"
                        style={{ paddingLeft: "8px" }}
                      >
                        <div className="userName">
                          {item1?.bondsCount} Bond Hashes
                        </div>
                        {collapseBondHash.find((o) => o === item1.coin) ? (
                          <div
                            className="userNameSubtitle clickable"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              setCollapseBondHash([
                                ...collapseBondHash.filter(
                                  (o) => o !== item1.coin
                                ),
                              ])
                            }
                          >
                            Expand For{" "}
                            {
                              allCoins.find((o) => o.coinSymbol === item1?.coin)
                                .coinName
                            }
                          </div>
                        ) : (
                          <div
                            className="userNameSubtitle clickable"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              setCollapseBondHash([
                                ...collapseBondHash,
                                item1.coin,
                              ])
                            }
                          >
                            Collapse For{" "}
                            {
                              allCoins.find((o) => o.coinSymbol === item1?.coin)
                                .coinName
                            }
                          </div>
                        )}
                      </div>
                    </div>
                    {item1.bondsData.map((item) => {
                      return collapseBondHash.find((o) => o === item1.coin) ? (
                        ""
                      ) : (
                        <div
                          in={collapseBondHash.find((o) => o !== item1.coin)}
                          timeout={200}
                          classNames="fade"
                        >
                          <div
                            style={{
                              padding: "0px 30px",
                              paddingRight: "75px",
                              //   transition: "right 1s ease-in-out",
                              marginBottom: "34px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns:
                                    "1.5fr 1.5fr 1fr 1fr 1fr",
                                  border: "0.5px solid #E7E7E7",
                                  height: "51px",
                                  fontSize: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    borderRight: "0.5px solid #E7E7E7",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "29px",
                                  }}
                                >
                                  Investment:
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {item?.investment.toFixed(4)} {item?.coin}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    borderRight: "0.5px solid #E7E7E7",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "29px",
                                  }}
                                >
                                  Banker Interest Rate:
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {item?.interest_rate.toFixed(4)}{" "}
                                    {item?.coin}
                                  </span>
                                </div>
                                <div
                                  style={{ borderRight: "0.5px solid #E7E7E7" }}
                                ></div>
                                <div
                                  onClick={(e) =>
                                    window.open(
                                      `https://tokenhash.com/bond/${item?._id}`,
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    borderRight: "0.5px solid #E7E7E7",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Banker Bond Hash
                                </div>
                                <div
                                  onClick={(e) =>
                                    window.open(
                                      `https://tokenhash.com/bond/${item?.user_bond_contract_id}`,
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Client Bond Hash
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  height: "260px",
                                  border: "0.5px solid #E7E7E7",
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr 1fr",
                                  gap: "128px",
                                  padding: "0px 29px",
                                  paddingTop: "47px",
                                  paddingRight: "73px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    paddingBottom: "38px",
                                  }}
                                >
                                  <div className="bondHashTitle">
                                    Banker Has Received:
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Payments:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.bankerData?.txnsCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Amount:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.bankerData?.amount.toFixed(4)}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Value:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      ${" "}
                                      {item?.bankerData?.amount_usd.toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    paddingBottom: "38px",
                                  }}
                                >
                                  <div className="bondHashTitle">
                                    Client Has Received:
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Payments:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.customerData?.txnsCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Amount:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.customerData?.amount.toFixed(4)}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Interest Value:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      ${" "}
                                      {item?.customerData?.amount_usd.toFixed(
                                        2
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    paddingBottom: "38px",
                                  }}
                                >
                                  <div className="bondHashTitle">
                                    Affiliate Has Received:
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Commission Payments:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.customComData?.txnsCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Commission Amount:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      {item?.customComData?.amount.toFixed(4)}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>Commission Value:</div>
                                    <div style={{ fontWeight: 700 }}>
                                      ${" "}
                                      {item?.customComData?.amount_usd.toFixed(
                                        2
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  height: "35px",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "20px",
                                  background: "#050505",
                                  color: "white",
                                  fontWeight: 700,
                                }}
                              >
                                So Far You Have Banker Has Earned
                                <span
                                  style={{
                                    color: "#50C7AD",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.spreadEarned.toFixed(4)} {item?.coin}
                                </span>
                                Worth
                                <span
                                  style={{
                                    color: "#50C7AD",
                                    padding: "0px 5px",
                                  }}
                                >
                                  ${item?.spreadEarnedUSD.toFixed(2)} USD
                                </span>{" "}
                                At The Time Of Payment
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })
            : Array(5)
                .fill("")
                .map((item) => {
                  return (
                    <>
                      <div className="cardTop">
                        <div style={{ display: "flex" }}>
                          <div>
                            <Skeleton
                              className="dp"
                              circle
                              width={35}
                              height={35}
                              style={{ marginRight: "20px" }}
                            />
                          </div>
                          <div style={{ paddingLeft: "8px" }}>
                            <div className="userName">
                              <Skeleton className="name" width={160} />
                            </div>
                            <div className="userNameSubtitle">
                              <Skeleton
                                className="email"
                                width={200}
                                style={{ height: "10px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="rightAlign"
                          style={{ paddingLeft: "8px" }}
                        >
                          <div className="userName">
                            <Skeleton className="name" width={160} />
                          </div>
                          <div className="userNameSubtitle">
                            <Skeleton
                              className="email"
                              width={200}
                              style={{ height: "10px" }}
                            />
                          </div>
                        </div>
                      </div>
                      {Array(2)
                        .fill("")
                        .map((item1) => {
                          return (
                            <div
                              style={{
                                padding: "0px 30px",
                                paddingRight: "75px",
                                transition: "right 1s ease-in-out",
                                marginBottom: "34px",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      "1.5fr 1.5fr 1fr 1fr 1fr",
                                    border: "0.5px solid #E7E7E7",
                                    height: "51px",
                                    fontSize: "12px",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderRight: "0.5px solid #E7E7E7",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "29px",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      borderRight: "0.5px solid #E7E7E7",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "29px",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      borderRight: "0.5px solid #E7E7E7",
                                    }}
                                  ></div>
                                  <div
                                    onClick={(e) =>
                                      window.open(
                                        `https://tokenhash.com/bond/${item?._id}`,
                                        "_blank"
                                      )
                                    }
                                    style={{
                                      borderRight: "0.5px solid #E7E7E7",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                  </div>
                                  <div
                                    onClick={(e) =>
                                      window.open(
                                        `https://tokenhash.com/bond/${item?.user_bond_contract_id}`,
                                        "_blank"
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    height: "260px",
                                    border: "0.5px solid #E7E7E7",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    gap: "128px",
                                    padding: "0px 29px",
                                    paddingTop: "47px",
                                    paddingRight: "73px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      paddingBottom: "38px",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      paddingBottom: "38px",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      paddingBottom: "38px",
                                    }}
                                  >
                                    <div className="userName">
                                      <Skeleton className="name" width={160} />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="userName">
                                        <Skeleton
                                          className="name"
                                          width={130}
                                        />
                                      </div>
                                      <Skeleton
                                        className="name"
                                        width={80}
                                        height={10}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                    background: "#050505",
                                    color: "white",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div className="userName">
                                    <Skeleton
                                      className="name"
                                      width={609}
                                      height={10}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
        </>
      </div>
    </>
  );
};

export default PerUserBondHashList;
