import React, { useContext, useState, useEffect } from "react";
import "./subdrawer.scss"
import axios from "axios";
import close from "../../../../../static/images/icons/close1.svg";
import { GlobalContex } from "../../../../../globalContex";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
// import Lock from "../../../../static/images/icons/lock.svg";



const Actions = ({ step, setStep, comissionPathId }) => {
    const { showSubDraw, setShowSubDraw, holderData,
        setHolderData, selectedShareToken, setSelectedShareToken, loginData, compData, compensationData, setCompensationData ,compLoader,
        setCompLoader } = useContext(GlobalContex);

    const [thePercent, setThePercent] = useState("")
    const [keysData, setKeysData] = useState([{ name: "Total Commission" }])


    let disp
    if (step === "loading" || step === "success") {
        disp = "none"
    } else {
        disp = ""
    }
    // useEffect(() => {
    //     axios
    //         .get(
    //             `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
    //                 "bankerEmailNew"
    //             )}`
    //         )
    //         .then((res) => {
    //             console.log("ShareTokenValues ", res.data.paths)
    //             if (!selectedShareToken) {
    //                 setSelectedShareToken(res.data.paths[0])
    //             }
    //         });
    // }, [localStorage.getItem("bankerEmailNew")]);

    const openloader = () => {
        setStep("loading")
        let body = {
            email: localStorage.getItem("bankerEmailNew"),
            token: loginData.idToken,
            path_id: comissionPathId,
            update_data: {
                commission_fee_percentage: thePercent ? thePercent : 0
            }
        }
        console.log("newcomp-body ", body)

        if(!thePercent){
            setThePercent(0)
        }
        // app_code: selectedShareToken?.apps_metadata[0].app_code

        axios.post("https://comms.globalxchange.io/coin/investment/path/edit", body).then(res => {
            if (res.data.status) {
                setStep("success")
                setCompLoader(true)
                setTimeout(() => {
                    setThePercent("")
                    setShowSubDraw(false)
                    setStep("default")
                }, 1000)
            } else if (res.data.message === "jwt expired") {
                setStep("Token Expired");
            } else {
                setStep("default")
            }
        })
    }


    const conditionalcrumbs = () => {
        switch (step) {
            case "step1":
                return (
                    <p className="crumb" onClick={() => setStep("default")}>Actions {"->"} <span className="thebreadcrumb">Change Commission</span></p>
                )
            default:
                return (
                    <p className="thebreadcrumb">Actions</p>
                )
        }
    }

    const conditionalPaths = () => {
        switch (step) {
            case "loading":
                return (
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingAnimation logoAnim sideDraw={true} />
                        <div
                            style={{
                                position: "absolute",
                                bottom: 0,
                                textAlign: "center",
                                // marginBottom: "20px",
                                color: "#182542",
                                fontSize: "18px",
                                fontWeight: "700"
                            }}
                        >
                            Change Total Commission For {JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}
                        </div>
                    </div>
                )
            case "success":
                return <div className="assetDispText">
                    You Have Successfully Updated The Total Commission For  {JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol} To {parseInt(thePercent).toFixed(2)}%
                </div>
            case "Token Expired":
                return (
                    <div style={{ padding: "30px", height: "90px" }}>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            {/* <img src={lock} alt="" style={{ width: "20px" }} /> */}
                            <div style={{ paddingLeft: "15px" }}>Logout</div>
                        </div>
                    </div>
                );
            case "step1":
                return (
                    <>
                        <br />
                        <div className="assetfields">
                            <p className="assettext">Current Total Commission</p>
                            <p style={{ fontWeight: 700 }}>{compData?.percentage.toFixed(2)}%</p>
                        </div>
                        <br />
                        <div className="assetfields">
                            <p className="assettext">Enter New Total Commission</p>
                            <input type="number" placeholder="0.00%" className="assetinput" value={thePercent} onChange={(e) => setThePercent(e.target.value)} />
                        </div>
                        <div className="buttonaction">
                            <div className="leftbtn">
                                <button className="backbtn" onClick={() => setStep("default")}>Go Back</button>
                            </div>
                            <div className="rightbtn">
                                <button className="savebtn" onClick={openloader}>Save Changes</button>
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <div className="assetfields">
                        <div className="defaultcard">
                            <p>What Would You Like To Do?</p>
                        </div>
                        <div className="boxtext" onClick={() => setStep("step1")}>
                            <p className="thefonttext">Change Total Commission</p>
                        </div>
                    </div>
                )
        }
    }


    return (
        <div
            style={{
                height: "90px",
            }}>
            <div style={{ display: disp }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <div style={{
                        display: "flex", alignItems: "center", padding: "30px",
                    }}>
                        <div>
                            <div style={{ fontSize: "22px" }}>
                                Total Commission
                            </div>
                            {conditionalcrumbs()}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", paddingRight: "30px" }}>
                        <div className="backButton icon-imgs" onClick={() => (setShowSubDraw(false), setStep("default"))}>
                            <img src={close} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {conditionalPaths()}
        </div>
    )
}

export default Actions