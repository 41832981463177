import axios from "axios";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../../globalContex";

function BondPayoutAppList({ setBondPayoutApp, onClose }) {
  const { bankerEmail } = useContext(GlobalContex);
  const [search, setSearch] = useState("");

  const [payoutApps, setPayoutApps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${bankerEmail}`
      )
      .then(({ data }) => {
        setPayoutApps(data.userApps);
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <div className="titleOp">Select Bond Payout App</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Apps....|"
        />
      </div>
      <Scrollbars className="searchList">
        {loading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : payoutApps
              .filter(
                (payoutApps) =>
                  payoutApps.app_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  payoutApps.app_code
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((item) => (
                <div
                  className="user"
                  key={item.app_code}
                  onClick={() => {
                    setBondPayoutApp(item);
                    onClose();
                  }}
                >
                  <img className="dp" src={item.app_icon} alt="" />
                  <div className="userDetail">
                    <div className="name">{item.app_name}</div>
                    <div className="email">{item.app_code}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default BondPayoutAppList;
