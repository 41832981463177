import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAllergies, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
// import CountryList from "./CountryList";
// import AppsList from "./AppsList";
// import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";

import plus from "../../../static/images/globaldrawer/plus.svg";
import closeWhite from "../../../static/images/icons/closeWhite.svg";

// import AppList from "./AppsList";\
import PublicationList from "./PublicationList";
import LoadingAnimation from "../../LoadingAnimation";
// import PublicationList from "./PublicationList";
import defaultImg from "../../../static/images/icons/defaultImg.svg";
// import AuthorList from "./AuthorList";
// import EnterArticle from "./EnterArticle";
import CategoryList from "./CategoryList";
import NavBarList from "./NavBarList";
import plusIcon from "../../../static/images/addNewIcons/plusIcon.svg";
import closeIconRed from "../../../static/images/addNewIcons/closeIconRed.svg";
import downArrow from "../../../static/images/downArrow.svg";
import CurrencyList from "./CurrencyList";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const NewWebinar = ({ step, setStep, setMainMenu, loading, setLoading }) => {
    const navigate = useNavigate();

    const {
        loginData,
        bankerEmail,
        refetchAppData,
        setRefetchAppData,
        globalMenuAdd,
        setGlobalMenuAdd,
        tabSelected,
        setTabSelected,
        selectedPublication,
        wideDrawer,
        setWideDrawer,
        selectedApp,
        refetchArticles,
        setSlider,
        setRefetchArticles,
        refetchCourses,
        setRefetchCourses,
        formatDate,
        scrollTo,
        sidebarRef
    } = useContext(GlobalContex);

    //
    const [publication, setPublication] = useState(null);
    const [author, setAuthor] = useState(null);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleExcerpt, setArticleExcerpt] = useState("");
    const [articleBody, setArticleBody] = useState("");

    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [coverPhoto, setCoverPhoto] = useState("");
    const [coverPhotoLoading, setCoverPhotoLoading] = useState("");

    const [categories, setCategories] = useState([]);
    const [navBars, setNavBars] = useState([]);
    const [buttonTitle, setButtonTitle] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [altTag, setAltTag] = useState("");
    const [courseName, setCourseName] = useState("");
    const [name, setName] = useState("");
    const [tagline, setTagline] = useState("");
    const [learnMore, setLearnMore] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [lang, setLang] = useState("");
    const [requirements, setRequirements] = useState("");
    const [audience, setAudience] = useState("");
    const [courseLink, setCourseLink] = useState("");
    const [courseType, setCourseType] = useState(["paid", "free"]);
    const [selectedCourseType, setSelectedCourseType] = useState("paid");
    const [currency, setCurrency] = useState("");
    const [coursePrice, setCoursePrice] = useState("");
    const [selectDate, setSelectDate] = useState("");
    const [selectCurrency, setSelectCurrency] = useState("")
    const [link, setLink] = useState("");

    // Form Variables

    const [company, setCompany] = useState();
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenName, setTokenName] = useState("");
    const [tokenAvailable, setTokenAvailable] = useState(null);

    //Publishers New Publication
    const [publicationName, setPublicationName] = useState("");
    const [app, setApp] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [country, setCountry] = useState(null);

    const [visibleSubmit, setVisibleSubmit] = useState(false);

    const [metaTag, setMetaTag] = useState("")
    const [metaArr, setMetaArr] = useState([])

    const [keywords, setKeywords] = useState("")
    const [keywordsArr, setKeywordsArr] = useState([])
    const [customUrl, setCustomUrl] = useState("")
    const [timeline, settimeline] = useState("AM")
    const [thetimeline, setthetimeline] = useState("AM")
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")
    const [dispstart, setdispstart] = useState("none")
    const [dispend, setdispend] = useState("none")
    let thefields = ["AM", "PM"]

    useEffect(() => {
        localStorage.setItem("step", step);
    }, [step]);

    useEffect(() => {
        if (sidebarRef.current) {
        //   console.log("sidebarRef:", sidebarRef.current);
        //   console.log("scrollTo:", scrollTo);
          sidebarRef.current.scrollTop(scrollTo);
        }
      }, [step]);

    useEffect(() => {
        // console.log("selectedPublication", selectedPublication);
        // setPublication(selectedPublication);
        if (selectedApp.appName === "Authors") {
            if (!publication?.PublicationDetail[0]?.fxa_app_id) {
                axios
                    .get(
                        `https://publications.apimachine.com/application/publisher/detail/${bankerEmail}`
                    )
                    .then(({ data }) => {

                        setPublication(data?.data[0]?.PublicationDetails[0]);
                        setLoading(false);
                    });
            }
            axios.get(`https://publications.apimachine.com/publisher?email=${bankerEmail}`).then((res) => {
                if (res.data.data.length > 0) {
                    setAuthor(res.data.data[0])
                }
            })
        } else {
            setPublication(selectedPublication);
        }
    }, [])

    const handleCheckTokenAvailability = () => {
        if (tokenAvailable === null && tokenSymbol !== "") {
            axios
                .get(
                    `https://comms.globalxchange.io/coin/investment/path/get?token=${tokenSymbol}`
                )
                .then((res) => {
                    if (res.data.status) {
                        setTokenAvailable(false);
                    } else {
                        setTokenAvailable(true);
                    }
                });
        } else {
            setTokenAvailable(null);
            setTokenSymbol("");
        }
    };

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    // const uploadImage = async (e, setImage, setLoading) => {
    //     setLoading(true);
    //     const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //         e.target.files[0].name.lastIndexOf(".")
    //     )}`;
    //     const formData = new FormData();
    //     const file = renameFile(e.target.files[0], fileName);
    //     formData.append("files", file);
    //     const path_inside_brain = "root/";

    //     const jwts = await signJwt(fileName, emailDev, secret);
    //     console.log(jwts, "lkjkswedcf");
    //     let { data } = await Axios.post(
    //         `https://publications.apimachine.com/article/upload`,
    //         formData,
    //         {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //             },
    //         }
    //     );
    //     console.log(data[0].urlName, "data[0].urlName")
    //     setImage(data[0].urlName);
    //     setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
          e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';
    
        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
          `https://publications.apimachine.com/article/upload`,
          formData,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
      };

    const authorStep = () => {
        if (selectedApp.appName !== "Authors") {
            setStep("Select Author")
        }
    }

    const removeCategory = (category) => {
        setCategories(categories.filter((item) => item !== category));
    };
    const removeNav = (navBar) => {
        setNavBars(navBars.filter((item) => item !== navBar));
    };

    const addMetaTag = (metaTag) => {
        if (metaTag === "") return
        setMetaArr([...metaArr, metaTag])
        console.log(metaArr, "metaArr")
        setMetaTag("")
    }

    const removeTag = (item) => {
        setMetaArr(metaArr.filter((tag) => tag !== item));
    };

    const addKeyword = (keyword) => {
        if (keyword === "") return
        setKeywordsArr([...keywordsArr, keyword])
        console.log(keywordsArr, "keywordsArr")
        setKeywords("")
    }

    const removeKeyword = (item) => {
        setKeywordsArr(keywordsArr.filter((keyword) => keyword !== item));
    };

    const opentime = (e) => {
        setstarttime(e.target.value)
    }

    const opendropstart = (e) => {
        if (dispstart === "block") {
            setdispstart("none")
        } else {
            setdispstart("block")
        }

    }


    const getContent = () => {
        switch (step) {
            case "success":
                setTimeout(() => {
                    // // navigate("/ventures/Brands");
                    // window.location.reload();
                    setSlider(false);
                }, 1000);
                setRefetchAppData(true);
                return (
                    <div className="newConglomerate">
                        <div className="succesView">
                            <div className="labelItm" style={{ textAlign: "center" }}>
                                You Have Successfully Added {name}. You Will Be
                                Redirected To The Updated Webinar's List Automatically
                            </div>
                        </div>
                    </div>
                );
            case "app":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        {/* <AppList setApp={setApp} onClose={() => setStep("")} /> */}
                    </div>
                );

            case "select currency":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <CurrencyList setSelectCurrency={setSelectCurrency} onClose={() => setStep("")} />
                    </div>
                );

            case "Token Expired":
                return (
                    <>
                        <div className="newConglomerate">
                            <div className="succesView">
                                <div className="labelItm" style={{ textAlign: "center" }}>
                                    Token Expired. Login Again.
                                </div>
                            </div>
                        </div>

                        <div className="footerBtns">
                            <div
                            // onClick={(e) => handleBackStep()}
                            >
                                Go Back
                            </div>
                            <div
                                onClick={(e) => {
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                Logout
                            </div>
                        </div>
                    </>
                );

            case "Select Publication":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <PublicationList
                            setPublication={setPublication}
                            onClose={() => setStep("")}
                        />
                    </div>
                );
            case "Select Author":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        {/* <AuthorList setAuthor={setAuthor} onClose={() => setStep("")} /> */}
                    </div>
                );

            case "Enter Article":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        {/* <EnterArticle
              articleBody={articleBody}
              setArticleBody={setArticleBody}
              onClose={() => {
                setStep("");
                setWideDrawer(false);
              }}
            /> */}
                    </div>
                );
            case "Add Category":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <CategoryList
                            categories={categories}
                            setCategories={setCategories}
                            onClose={() => setStep("")}
                        />
                    </div>
                );
            case "Add Navbar":
                return (
                    <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
                        <NavBarList
                            navBars={navBars}
                            setNavBars={setNavBars}
                            onClose={() => setStep("")}
                        />
                    </div>
                );

            default:
                return (
                    <>
                        <div className="newConglomerate">
                            <Scrollbars
                                ref={sidebarRef}
                                className="scrollForm"
                                renderTrackHorizontal={() => <div />}
                                renderThumbHorizontal={() => <div />}
                                renderTrackVertical={() => <div />}
                                renderThumbVertical={() => <div />}
                            >
                                <div className="name">Which publication is this webinar for?</div>
                                {selectedApp?.appName === "Authors" ? (
                                    publication?.PublicationDetail[0]?.fxa_app_id ? (
                                        <div
                                            className="user"
                                            onClick={() => setStep("Select Publication")}
                                            style={{ borderRadius: "35px" }}
                                        >
                                            <img
                                                className="dp"
                                                src={
                                                    publication?.PublicationDetail[0]?.profile_pic
                                                        ? publication?.PublicationDetail[0]?.profile_pic
                                                        : defaultImg
                                                }
                                                alt=""
                                            />
                                            <div className="userDetail">
                                                <div className="name">
                                                    {publication?.PublicationDetail[0]?.name}
                                                </div>
                                                <div className="email">
                                                    {publication?.PublicationDetail[0]?.fxa_app_id}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="inputWrap"
                                            onClick={() => setStep("Select Publication")}
                                        >
                                            <input
                                                type="text"
                                                className="text"
                                                placeholder="Click To Select"
                                                readOnly
                                            />
                                            <div
                                                className="btnCheck"
                                                style={{
                                                    border: "none",
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCaretDown} />
                                            </div>
                                        </div>
                                    )
                                ) : publication?.fxa_app_id ? (
                                    <div
                                        className="user"
                                        onClick={() => setStep("Select Publication")}
                                        style={{ borderRadius: "35px" }}
                                    >
                                        <img
                                            className="dp"
                                            src={
                                                publication?.profile_pic
                                                    ? publication?.profile_pic
                                                    : defaultImg
                                            }
                                            alt=""
                                        />
                                        <div className="userDetail">
                                            <div className="name">{publication?.name}</div>
                                            <div className="email">{publication?.fxa_app_id}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="inputWrap"
                                        onClick={() => setStep("Select Publication")}
                                        style={{ borderRadius: "35px" }}
                                    >
                                        <input
                                            type="text"
                                            className="text"
                                            placeholder="Click To Select"
                                            readOnly
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </div>
                                    </div>
                                )}
                                {/* Name of course */}
                                <div className="name">What is the name of the webinar?</div>
                                <div className="inputWrap">
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="Name..."
                                    />
                                </div>


                                <div className="name">Describe the webinar</div>
                                <div className="inputWrap" style={{ height: "auto", minHeight: "170px", padding: "15px" }}>
                                    <textarea
                                        style={{ width: "100%", border: "none" }}
                                        rows="5"
                                        placeholder="Description...."
                                        className="text"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>

                                <div className="name">What is the registration link for the webinar??</div>
                                <div className="inputWrap">
                                    <input
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                        type="text"
                                        className="text"
                                        placeholder="Enter registration link..."
                                    />
                                </div>


                                {/* Upload Logos */}
                                <div className="name">Upload a icon</div>
                                <div>
                                    <div
                                        className="filesUpload"
                                        style={{ justifyContent: "flex-start" }}
                                    >
                                        <label className="fileInp icon">
                                            <img
                                                className={`${Boolean(colouredIcon)}`}
                                                src={
                                                    colouredIconLoading
                                                        ? loadingGif
                                                        : colouredIcon || cloudUploadIcon
                                                }
                                                alt=""
                                                style={{ width: "40px" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    uploadImage(
                                                        e,
                                                        setColouredIcon,
                                                        setColouredIconLoading
                                                    );
                                                }}
                                                accept="image/*"
                                            />
                                            <div
                                                className="text"
                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                            >
                                                {/* Colored Icon */}
                                            </div>
                                            <div className="hovTxt">
                                                Upload
                                                <br />
                                                New
                                            </div>
                                        </label>
                                        {/* <label
                                            className="fileInp icon"
                                            style={{
                                                flex: "0 0 50%",
                                                maxWidth: "50%",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <img
                                                className={`${Boolean(coverPhoto)}`}
                                                src={
                                                    coverPhotoLoading
                                                        ? loadingGif
                                                        : coverPhoto || cloudUploadIcon
                                                }
                                                alt=""
                                                style={{ width: "40px" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                                                }}
                                                accept="image/*"
                                            />
                                            <div
                                                className="text"
                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                            >
                                                Cover Photo
                                            </div>
                                            <div className="hovTxt">
                                                Upload
                                                <br />
                                                New
                                            </div>
                                        </label> */}
                                        {/* <label
      className="fileInp icon"
      style={{ visibility: "hidden" }}
    ></label> */}
                                    </div>
                                </div>
                                <div className="name">When is this webinar going to take place?</div>
                                <div className="inputWrap">
                                    <input
                                        value={selectDate}
                                        onChange={(e) => setSelectDate(e.target.value)}
                                        type="date"
                                        className="text"
                                        placeholder="00:00"
                                    />
                                    {/* <div
                                        className="btnCheck"
                                        style={{
                                            border: "none"
                                        }}
                                    >
                                        <div type="text" >
                                            <img src={downArrow} alt="" style={{ width: "10px" }} />
                                        </div>

                                    </div> */}
                                </div>
                                <div className="name">What is the timing for the webinar?</div>
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="the-pos">
                                        <input type="time" min="00:00" max="11:00" placeholder="Start" className={"start-time without_ampm" + (dispstart === "block" ? " borderFlat" : " borderRound")}
                                            value={starttime} onChange={opentime} onClick={opendropstart} />
                                        <p className="disp-field" onClick={opendropstart}>{timeline}</p>
                                        <div className="disp-time-card" style={{ display: dispstart }}>
                                            <div className="select-time-fields">
                                                {thefields.map((e) => {
                                                    return (
                                                        <div onClick={() => settimeline(e)}>
                                                            <p className={"fields " + (timeline === e ? " active-line" : "")}>{e}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="timebox">
                                                {Array(12).fill("").map((e, index) => {
                                                    return (
                                                        <div onClick={() => (setstarttime(("0" + index).slice(-2) + ":00"), setdispstart("none"))}>
                                                            <p className={"select-time" + (starttime === (("0" + index).slice(-2) + ":00") ? " active-line" : "")}>{("0" + index).slice(-2) + ":00"}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="the-pos">
                                        <input type="time" min="00:00" max="11:00" placeholder="End" className={"end-time without_ampm" + (dispend === "block" ? " borderFlat" : " borderRound")} value={endtime}
                                            onChange={(e) => setendtime(e.target.value)} onClick={() => dispend === "block" ? setdispend("none") : setdispend("block")}
                                        />
                                        <p className="disp-field1" onClick={() => dispend === "block" ? setdispend("none") : setdispend("block")}>{thetimeline} </p>
                                        <div className="disp-time-card" style={{ display: dispend }}
                                        >
                                            <div className="select-time-fields">
                                                {thefields.map((e) => {
                                                    return (
                                                        <div onClick={() => setthetimeline(e)}>
                                                            <p className={"fields " + (thetimeline === e ? " active-line" : "")}>{e}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="timebox" >
                                                {Array(12).fill("").map((e, index) => {
                                                    return (
                                                        <div onClick={() => (setendtime(("0" + index).slice(-2) + ":" + "00"), setdispend("none"))} >
                                                            <p className={"select-time" + (endtime === (("0" + index).slice(-2) + ":00") ? " active-line" : "")}>{("0" + index).slice(-2) + ":00"}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="name">What type of course is it?</div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    {courseType?.map((item, index) => {
                                        return (
                                            <div key={index} onClick={() => setSelectedCourseType(item)}>
                                                <div className={"inputBtn " + (selectedCourseType === item ? "btnActive" : "")}>
                                                    {item.charAt(0).toUpperCase() + item.slice(1)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ display: selectedCourseType === "paid" ? "" : "none" }}>
                                    <div className="name">Which currency will it be charged in?</div>
                                    {selectCurrency?.coinSymbol ? (
                                        <div className="countryuser" style={{ height: "68px" }} onClick={() => setStep("select currency")}>
                                            {/* <img className="dp" src={selectCourse?.profile_pic} alt="" /> */}
                                            {/* &nbsp;&nbsp; */}
                                            <div>
                                                <div className="name">{selectCurrency?.coinSymbol}</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="inputWrap" style={{ cursor: "pointer" }} onClick={() => setStep("select currency")}>
                                            <input
                                                type="text"
                                                className="text"
                                                placeholder="Select Currency"
                                                readOnly
                                                style={{ cursor: "pointer" }}
                                            />
                                            <div
                                                className="btnCheck"
                                                style={{
                                                    border: "none",
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCaretDown} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="name">What is the cost of attending the webinar?</div>
                                    <div className="inputWrap">
                                        <input
                                            value={coursePrice}
                                            onChange={(e) => setCoursePrice(e.target.value)}
                                            type="text"
                                            className="text"
                                            placeholder="0.00"
                                        />
                                        <div
                                            className="btnCheck"
                                            style={{
                                                border: "none"
                                            }}
                                        >
                                            <div type="text" >
                                                {selectCurrency?.coinSymbol}
                                            </div>

                                        </div>
                                    </div>

                                    {/* Add Categories */}

                                </div>
                                <div className="name">Add Categories</div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {categories?.map((item, index) => {
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",

                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        border: "0.5px solid #E7E7E7",
                                                        padding: "30px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        margin: "10px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div style={{
                                                        background: "black",
                                                        width: "15px",
                                                        padding: "3px",
                                                        borderRadius: "20px",
                                                        position: "absolute",
                                                        height: "15px",
                                                        top: "0px",
                                                        right: "-6px",
                                                        marginTop: "-5px",
                                                    }}
                                                        onClick={() => removeCategory(item)}
                                                    >
                                                        <img style={{
                                                            marginTop: "-15px",
                                                            width: "10px",
                                                            height: "10px",
                                                            cursor: "pointer"
                                                        }} src={closeWhite} alt="" />
                                                    </div>
                                                    <img
                                                        src={item?.thumbnail}
                                                        alt=""
                                                        style={{ width: "60px", height: "60px" }}
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        wordWrap: "break-word",
                                                        width: "100px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {item?.title}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div
                                        onClick={(e) => setStep("Add Category")}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            border: "0.5px solid #E7E7E7",
                                            padding: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "10px",
                                        }}
                                    >
                                        <img src={plus} alt="" />
                                    </div>
                                </div>

                                {/* Add Categories */}
                                <div className="name" style={{ paddingTop: "20px" }}>
                                    Add NavBars
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {navBars.map((item, index) => {
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",

                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        border: "0.5px solid #E7E7E7",
                                                        padding: "30px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        margin: "10px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div style={{
                                                        background: "black",
                                                        width: "15px",
                                                        padding: "3px",
                                                        borderRadius: "20px",
                                                        position: "absolute",
                                                        height: "15px",
                                                        top: "0px",
                                                        right: "-6px",
                                                        marginTop: "-5px",
                                                    }}
                                                        onClick={() => removeNav(item)}
                                                    >
                                                        <img style={{
                                                            marginTop: "-15px",
                                                            width: "10px",
                                                            height: "10px",
                                                            cursor: "pointer"
                                                        }} src={closeWhite} alt="" />
                                                    </div>
                                                    <img
                                                        src={item?.icon}
                                                        alt=""
                                                        style={{ width: "60px", height: "60px" }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        wordWrap: "break-word",
                                                        width: "100px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {item.navTitle}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div
                                        onClick={(e) => setStep("Add Navbar")}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            border: "0.5px solid #E7E7E7",
                                            padding: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "10px",
                                        }}
                                    >
                                        <img src={plus} alt="" />
                                    </div>
                                </div>
                                &nbsp;
                                <div
                                    className="NextStepBtn"
                                    onClick={() => validate()}
                                    style={{
                                        opacity:
                                            selectedPublication &&
                                                name &&
                                                link &&
                                                colouredIcon &&
                                                selectDate &&
                                                starttime &&
                                                endtime &&
                                                selectedCourseType &&
                                                categories &&
                                                navBars &&
                                                description &&
                                                colouredIcon
                                                ? 1
                                                : 0.3,
                                    }}
                                >
                                    Add Webinar
                                </div>
                                &nbsp;
                                <div className="space"></div>
                            </Scrollbars>
                        </div >

                        {/* <div className="footerBtns">
                            <div
                                className="newField"
                                style={{ fontWeight: 700 }}
                                onClick={(e) => {
                                    setStep("");
                                    setMainMenu("");
                                }}
                            >
                                Go Back
                            </div>
                            <div
                                className="btnSubmit"
                                onClick={() => validate()}
                                style={{
                                    fontWeight: 700,
                                    opacity:
                                        courseName &&
                                            tagline &&
                                            learnMore &&
                                            courseDescription &&
                                            requirements &&
                                            lang &&
                                            colouredIcon &&
                                            audience &&
                                            courseLink &&
                                            selectedCourseType
                                            ? 1
                                            : 0.3,
                                }}
                            >
                                Create Course
                            </div>
                        </div> */}

                        {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className="btnSubmit"
                onClick={() => validate()}
                style={{ fontWeight: 700, opacity: visibleSubmit ? 1 : 0.3 }}
              >
                Submit App
              </div>
            </div> */}
                    </>
                );
        }
    };

    const validate = () => {
        if (
            selectedPublication &&
            name &&
            link &&
            colouredIcon &&
            selectDate &&
            starttime &&
            endtime &&
            selectedCourseType &&
            categories &&
            navBars &&
            description &&
            colouredIcon

        ) {
            setVisibleSubmit(true);
            AddWebinar();
        } else {
            toast.warning("All Fields Are Mandatory");
        }
    };

    const AddWebinar = () => {
        setLoading(true);
        let pubdata;
        if (selectedPublication) {
            pubdata = selectedPublication._id
        } else {
            pubdata = "63a1a2c60e46260e093cf260"
        }
        let body = {
            publication_id: pubdata,
            email: loginData?.user?.email,
            image: colouredIcon,
            title: name,
            date: formatDate(selectDate),
            startTime: starttime + " " + timeline,
            endTime: endtime + " " + thetimeline,
            description: description,
            registrationLink: link,
            costStructure: selectedCourseType,
            cost: selectedCourseType === "paid" ? coursePrice : "",
            currency: selectedCourseType === "paid" ? selectCurrency?.coinSymbol : "",
            category: categories.map((o) => o._id),
            navbar: navBars.map((o) => o._id),
        }
        console.log(JSON.stringify(body), "webinar-body")
        axios
            .post(`https://publications.apimachine.com/webinar/add`, body,)
            .then(({ data }) => {
                setLoading(false);
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    }
                    toast.success(data.message || "API Error");
                } else {
                    setStep("success");
                    setTimeout(() => {
                        setStep("");
                        setMainMenu("");
                        setTabSelected("Courses");
                        setSlider(false);
                        setStep("");
                        setRefetchCourses(!refetchCourses);
                    }, 2000);
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setRefetchCourses(!refetchCourses);
                setLoading(false);
                setTabSelected("Courses");
            });
    };

    return (
        <>
            {loading ? (
                // <div
                //   style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "70vh",
                //   }}
                // >
                //   <div className="loader"></div>
                //   <div style={{ padding: "20px", fontWeight: 600, color: "#18191D" }}>
                //     Creating New Publication ...
                //   </div>
                // </div>
                <div
                    style={{
                        height: window.innerHeight,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "40px",
                            fontWeight: 600,
                            color: "#18191D",
                            fontSize: "20px",
                        }}
                    >
                        Creating Course...
                    </div>
                </div>
            ) : (
                getContent()
            )}
            <ToastContainer />
        </>
    );
};

export default NewWebinar;
