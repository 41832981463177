import React, { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import Leads from "./Leads";
// import Clients from "./Clients";
// import EarningsPerCustomer from "./EarningsPerCustomer";
// import Users from "./Users";
// import Founders from "./Founders";
// import Apps from "./Apps";
// import Businesses from "./Businesses";

const MarketsverseCRM = () => {
    const tabs = ["Products", "Programs", "Leads", "Specialists", "Customers", "Inactive Customers", "Discarded Leads", "Campaigns"]

    const { setShowSubDraw } = useContext(GlobalContex);

    const [tabSelected, setTabSelected] = useState("Leads");

    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "Leads":
                return <Leads />;
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Coming Soon
                    </div>
                );
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);

    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false]}
            />

            {tabComponent}
        </div>
    );
};

export default MarketsverseCRM;
