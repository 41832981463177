import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContex } from "../../../../globalContex";
import Skeleton from "react-loading-skeleton";

const MyPairs = () => {
  const { collapse, selectedTerminal, setSelectedTerminal } =
    useContext(GlobalContex);
  const [allPairs, setAllPairs] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/terminal/app/pairs/get?app_code=${selectedTerminal?.app_code}`
      )
      .then((res) => {
        setAllPairs(res.data.pairs);
        setDataLoading(false);
      });
  }, [selectedTerminal]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          className="listGrid"
          style={{ gridTemplateColumns: "2fr 1.5fr 1fr 1fr 1fr" }}
        >
          <div>Pair</div>
          <div>Type</div>
          <div>Base</div>
          <div>Quote</div>
          <div>Fee</div>
        </div>
      </div>
      <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
          height: window.innerHeight - 175,
          overflowY: "scroll",
        }}
      >
        {!dataLoading ? (
          allPairs?.length > 0 ? (
            allPairs.map((item) => {
              return (
                <div
                  className="listDataGrid"
                  style={{
                    gridTemplateColumns: "2fr 1.5fr 1fr 1fr 1fr",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        height: "25px",
                        width: "25px",
                        // background: "gray",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item?.ter_coin_pair?.image}
                        alt=""
                        style={{ height: "25px", width: "25px" }}
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <div style={{ fontSize: "15px", fontWeight: 700 }}>
                        {item?.ter_coin_pair?.pair}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ fontSize: "15px", fontWeight: 700 }}>
                      {item.isForex ? "Fiat" : "Crypto"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ fontSize: "15px", fontWeight: 700 }}>
                      {item?.ter_coin_pair?.base}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ fontSize: "15px", fontWeight: 700 }}>
                      {item?.ter_coin_pair?.quote}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ fontSize: "15px", fontWeight: 700 }}>
                      {item?.cut.toFixed(2)}%
                    </div>
                  </div>
                </div>

                // <div className="bondsUsersGrid">
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              No Records Found
            </div>
          )
        ) : (
          Array(10)
            .fill("")
            .map((item, i) => {
              return (
                <div
                  className="listDataGrid post"
                  style={{
                    width: "100%",
                    // height: "120px",
                    gridTemplateColumns: "2fr 1.5fr 1fr 1fr 1fr",
                    // borderBottom: "solid 0.5px #EEEEEE",
                  }}
                >
                  <div
                    key={i}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Skeleton
                      className="dp"
                      circle
                      width={50}
                      height={50}
                      style={{ marginRight: "20px" }}
                    />
                    <div className="userDetail" style={{ paddingTop: "10px" }}>
                      <Skeleton width={100} />
                    </div>
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                  <div>
                    <Skeleton width={120} />
                  </div>
                </div>
              );
            })
        )}
      </div>
    </>
  );
};

export default MyPairs;
