import React, { useContext, useState } from "react";
import "./subdrawer.scss";
import { GlobalContex } from "../../../../globalContex";

import Actions from "./Actions";


const CRMdrawer = ({step , setStep}) => {
    const { showSubDraw, setShowSubDraw } = useContext(GlobalContex);

    const [selectedMenu, setSelectedMenu] = useState("Actions");

    let disp
    if(step === "loading" || step === "success"){
        disp = "none"
    }else {
         disp = ""
    }


    const conditionalData = () => {
        switch (selectedMenu) {

            case "Actions":
                return <Actions step={step} setStep={setStep} />;

            default:
                break;
        }
    };

    return (
        <div
            className={showSubDraw ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 125, top: "116px" }}
        >
            <div className="navs-disp" style={{display:disp}}>
                <div
                    // onClick={(e) => setSelectedMenu("About")}
                    className={
                        selectedMenu === "About" ? "navs-data active-tab" : "navs-data"
                    }
                >
                    <div>About</div>
                </div>
                <div
                    onClick={(e) => setSelectedMenu("Actions")}
                    className={
                        selectedMenu === "Actions" ? "navs-data active-tab" : "navs-data"
                    }
                >
                    <div>Actions</div>
                </div>
            </div>
            {/* {thedata} */}
            {conditionalData()}
        </div>

    )
}

export default CRMdrawer