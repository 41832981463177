import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./payouts.scss";
import { GlobalContex } from "../../../../globalContex";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import Skeleton from "react-loading-skeleton";


const CapitalizedPayouts = () => {
    const [dataLoading, setDataLoading] = useState(false);
    const [payoutsData, setPayoutsData] = useState([])

    const { selectedAppData, selectedShareToken, setSelectedShareToken } = useContext(GlobalContex)

    useEffect(() => {
        console.log("payout code ", selectedShareToken)
        setDataLoading(true)
        // axios
        //     .get(
        //         `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
        //             "bankerEmailNew"
        //         )}`
        //     )
        //     .then((res) => {
        //         setDataLoading(false)
        //         if (res.data.status) {
        //             let coinsymbol
        //             if(selectedShareToken){
        //                 coinsymbol = selectedShareToken?.token_profile_data.coinSymbol
        //             }else{
        //                 coinsymbol = res.data.paths[0].token_profile_data.coinSymbol
        //             }
        axios
            .get(
                `https://comms.globalxchange.io/coin/app/dividend/vault/dividend/get?token_symbol=${JSON.parse(localStorage.getItem("selectedShareCoin"))?.token_profile_data.coinSymbol}`
            )
            .then((res1) => {
                setDataLoading(false)
                if (res1.data.status) {
                    setPayoutsData(res1.data.data)
                }
            });
        //     } else {
        //         setDataLoading(false)
        //         setPayoutsData([])
        //     }
        // })
    }, [selectedShareToken])


    const loadingSection = (gridClass) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: "1.5fr 1.5fr 0.1fr",
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}>
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail" style={{ paddingTop: "10px" }}>
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div>
                            <Skeleton width={120} />
                            <Skeleton width={80} style={{ height: "10px" }} />
                        </div>
                    </div>
                )
            })

    }


    const contentSection = (bond, gridClass) => {
        return (
            <div className={gridClass}
                style={{
                    gridTemplateColumns: "1.5fr 1.5fr 0.1fr",
                }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={bond?.userDetail.profile_img ? bond?.userDetail.profile_img : defaultImg}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }} />
                    <div style={{ paddingLeft: "15px" }}>
                        <div className="title">
                            {bond?.userDetail.name}
                        </div>
                        <div className="subtitle">
                            {bond?.userDetail.username}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", textAlign: "left" }}>
                    <div className="title">
                        ${bond.total_dividents_received_USD.toFixed(10)}
                    </div>
                    <div className="subtitle">
                        {bond.total_divident_payments_received} Payments
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="desktopWrapper">
                <div style={{ width: "100%" }}>
                    <div className="listGrid" style={{ gridTemplateColumns: "1.5fr 1.5fr 0.1fr" }}>
                        <div>Name</div>
                        <div>All Time Earnings</div>
                    </div>
                </div>
                <div
                    style={{
                        // display: "flex",
                        fontWeight: 700,
                        fontSize: "20px",
                        height: window.innerHeight - 175,
                        overflowY: "scroll",
                    }}
                >
                    {!dataLoading ? (
                        payoutsData?.length > 0 ? (
                            payoutsData.map((payout) => {
                                return contentSection(payout, "listDataGrid");
                            })
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "60vh",
                                }}
                            >
                                No Records Found
                            </div>
                        )
                    ) : (
                        loadingSection("listDataGrid")
                    )}
                    {/* <AppsSubDrawer allApps={allApps} /> */}
                </div>
            </div>
        </>
    )
}

export default CapitalizedPayouts