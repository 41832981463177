import React, { useMemo , useState, useContext } from "react";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "./dividends.scss"
import CapitalizedPayouts from "./CapitalizedPayouts";

const Dividends = () => {
    const tabs = [
        "Payouts"
    ];

    const { setShowSubDraw } = useContext(GlobalContex);

    const [tabSelected, setTabSelected] = useState("Payouts");

    const tabComponent = useMemo(() => {
        switch (tabSelected) {
          case "Payouts":
            return <CapitalizedPayouts />; 
          default:
            return null;
        }
      }, [
        tabSelected,
        // openCoinFilter, refetchApi
      ]);


      return (
        <div>
          <NavBar
            tabs={tabs}
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
            enabledFilters={[true, true, true, false, false]}
          />
    
          {tabComponent}
    
          {/* <div style={{ width: "100%" }}>
            <div className="listGrid">
              <div>Asset</div>
              <div>Cost</div>
              <div>Length</div>
              <div>Daily Return</div>
              <div>Monthly Return</div>
            </div>
          </div> */}
          {/* <div
            style={{
              // display: "flex",
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
    
            <Subdrawer />
          </div> */}
        </div>
      );
}

export default Dividends