import React, { useState } from "react";
import EarningsPerAllCustomer from "./EarningsPerAllCustomer";
import PerUserBondHashList from "./PerUserBondHashList";

const EarningsPerCustomer = () => {
  const [selectedUserBondHash, setSelectedUserBondHash] = useState(null);
  const [allUserData, setAllUserData] = useState(null);

  return selectedUserBondHash ? (
    <PerUserBondHashList
      selectedUserBondHash={selectedUserBondHash}
      setSelectedUserBondHash={setSelectedUserBondHash}
      allUserData={allUserData}
      setAllUserData={setAllUserData}
    />
  ) : (
    <EarningsPerAllCustomer
      selectedUserBondHash={selectedUserBondHash}
      setSelectedUserBondHash={setSelectedUserBondHash}
      allUserData={allUserData}
      setAllUserData={setAllUserData}
    />
  );
};

export default EarningsPerCustomer;
