import React, { useContext, useEffect, useState } from "react";

import back from "../../../../../static/images/icons/back.svg";
import close from "../../../../../static/images/icons/close1.svg";
import editApp from "../../../../../static/images/clipIcons/appData.svg";
import userIcon from "../../../../../static/images/clipIcons/userIcon.svg";
import editIcon from "../../../../../static/images/icons/editIcon.png";
import bondIcon from "../../../../../static/images/clipIcons/bondIcon.svg";
import plusIcon from "../../../../../static/images/clipIcons/plus.svg";
import closeIcon from "../../../../../static/images/clipIcons/no.svg";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";

import connectedBonds from "../../../../../static/images/icons/connectedBonds.svg";
import bondContracts from "../../../../../static/images/icons/bondPurchases.svg";
import bondIssued from "../../../../../static/images/icons/bondIssued.svg";
import interestPayments from "../../../../../static/images/icons/interestPayments.svg";
import bondSettlements from "../../../../../static/images/icons/bondSettlements.svg";
import expiredBonds from "../../../../../static/images/icons/expiredBonds.svg";

import { GlobalContex } from "../../../../../globalContex";
import axios from "axios";
import "./subdrawer.scss"
const About = ({ allBonds,bankid, currenciesData }) => {
  const { showSubDraw, setShowSubDraw, bondSelected,selectedApp, setBondSelected } =
    useContext(GlobalContex);
  const [step, setStep] = useState(null);
  const [path, setPath] = useState([]);

  const [bondHoldersdata, setBondHoldersdata] = useState(null);
  const [bondHoldersdata1, setBondHoldersdata1] = useState(null);
  const [bondHoldersdata2, setBondHoldersdata2] = useState(null);
  const [bankerDetail, setbankerDetail] = useState([])
console.log("bankid",bankid)
  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/user/bank/account/get?bank_account_id=${bankid}`
      )
      .then((res) => {
        if (res.data.status) {
          console.log("reszxc", res.data.bankAccounts_info)

          // let getdata = res?.data?.bankAccounts_info?.map(item => {
          //   return {
          //     account_name: item?.account_name,
          //     currency:item?.currency,
          //   }
          // })

          setbankerDetail(res.data.bankAccounts_info)

          // setBondHoldersdata(res.data);
        }
      });
  }, [bankid]);


  const onChangeItem = (e, fName) => {

    const temp = [...bankerDetail];
    let updateObj = temp[0];
    updateObj[fName] = e;

    setbankerDetail(temp)

    // console.log("123123123xcvxcvxcv", e)
  }


  console.log("123123123xcvxcvxcv", bankerDetail)
  const offeringDefaultCase = [
    {
      name: "Fixed Data",
      icon: editApp,
      value: null,
      enabled: true,
    },
    {
      name: "Dynamic Data",
      icon: userIcon,
      value: bondHoldersdata ? bondHoldersdata.usersCount : 0,
      enabled: false,
    },
    // {
    //   name: "Connected Bonds",
    //   icon: connectedBonds,
    //   value: bondHoldersdata1
    //     ? bondHoldersdata1.custom_bonds[0]?.connectedBondEnabled === true
    //       ? "Enabled"
    //       : "Disabled"
    //     : "",
    //   enabled: false,
    // },
    // {
    //   name: "Bond Contracts",
    //   icon: bondContracts,
    //   value: bondHoldersdata ? bondHoldersdata.total_bondTxnsCount : 0,
    //   enabled: false,
    // },
    // {
    //   name: "Bond Issued",
    //   icon: bondIssued,
    //   value: bondHoldersdata ? bondHoldersdata.total_bondsCount : 0,
    //   enabled: false,
    // },
    // {
    //   name: "Interest Payments",
    //   icon: interestPayments,
    //   value: bondHoldersdata2
    //     ? bondHoldersdata2.totalData.total_interest_payments_count
    //     : "",
    //   enabled: false,
    // },
    // {
    //   name: "Bond Settlements",
    //   icon: bondSettlements,
    //   value: bondHoldersdata2 ? bondHoldersdata2.totalData.redeemed_bonds : "",
    //   enabled: false,
    // },
    // {
    //   name: "Expired Bonds",
    //   icon: expiredBonds,
    //   value: bondHoldersdata2 ? bondHoldersdata2.totalData.expired_bonds : "",
    //   enabled: false,
    // },
  ];

  useEffect(() => {
    setStep("About");
    setPath(["About"]);
  }, [showSubDraw]);

  const handleBackStep = () => {
    if (path.length > 1) {
      path.splice(-1);
      // console.log(tempPath, "kqjdkjwed");
      setPath([...path]);
      if (path.length > 0) {
        setStep(path[path.length - 1]);
      }
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  useEffect(() => {
    setStep(path[path.length - 1]);
  }, [path]);
  console.log("czxcvbvfbvb", bankerDetail[0])
  const conditionalSteps = () => {
    switch (step) {
      case "Fixed Data":
        return (
          <>
            <div
              style={{ width: "100%", overflowY: "scroll", height: window.innerHeight - 280 }}
            >
              <div className="EditSection">
                <h2 className="Title-Detail">Account Name</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                    <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  {/* <input
                  value={bankerDetail[0]?.account_name}
                  onChange={(e) => onChangeItem(e.target.value, "account_name")} />
              </div> */}
                  <div className="item-change-section">{bankerDetail[0]?.account_name}</div>
                </div>
              </div>
              <div className="EditSection">
                <h2 className="Title-Detail">Account Currency</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                    <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-section">{bankerDetail[0]?.account_name}</div>
                  {/* <input
                  value={bankerDetail[0]?.currency}
                  onChange={(e) => onChangeItem(e.target.value, "currency")} /> */}
                </div>
              </div>
              <div className="EditSection">
                <h2 className="Title-Detail">Country</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                    <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-section">
                    <img className="banker-image" src={bankerDetail[0]?.countryData?.image} alt="" />
                    {bankerDetail[0]?.countryData?.name}</div>

                </div>
              </div>

              <div className="EditSection">
                <h2 className="Title-Detail">Phone Number</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                  <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-section">

                    {bankerDetail[0]?.phone_number}</div>

                </div>
              </div>


              <div className="EditSection">
                <h2 className="Title-Detail">Beneficiary Address</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                  <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-discription">

                    {bankerDetail[0]?.beneficiary_address}</div>

                </div>
              </div>

              <div className="EditSection">
                <h2 className="Title-Detail">Payment Method</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                    <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-section">
                    <img className="banker-image" src={bankerDetail[0]?.paymentMethod_data?.icon} alt="" />
                    {bankerDetail[0]?.paymentMethod_data?.name}</div>

                </div>
              </div>
              <div className="EditSection">
                <h2 className="Title-Detail">Bank</h2>
                <div className="input-section">
                  <div className="cricleDiv">
                    <img className="editIcon" src={editIcon} alt="" />
                  </div>
                  <div className="item-change-section">
                    <img className="banker-image" src={bankerDetail[0]?.instituteData?.profile_image} alt="" />
                    {bankerDetail[0]?.instituteData?.institute_name}</div>

                </div>
              </div>
            </div>
          </>
        )

      case "step1":
        return (
          <>
            <div className="sidebarTitle">This is the 1st step </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("step2");
                setPath([...path, "step2"]);
              }}
            >
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 1</div>
            </div>
          </>
        );

      case "step2":
        return (
          <>
            <div className="sidebarTitle">This is the 2nd step </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("step3");
                setPath([...path, "step3"]);
              }}
            >
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 2</div>
            </div>
          </>
        );

      case "step3":
        return (
          <>
            <div className="sidebarTitle">This is the 3rd step </div>
            <div className="sidebarCard">
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 3</div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="sidebarTitle">What Would You Like To See? </div>
            <div
              style={{ overflowY: "scroll", height: window.innerHeight - 380 }}
            >
              {offeringDefaultCase.map((item) => {
                return (
                  <div
                    // className={
                    //   item.enabled ? "sidebarCard" : "sidebarCardDisabled"
                    // }
                    className="sidebarCard"
                    onClick={(e) => {
                      if (item.enabled) {
                        setStep(item.name);
                        setPath([...path, item.name]);
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <img src={item.icon} alt="" style={{ width: "20px" }} /> */}
                      <div style={{ paddingLeft: "10px" }}>{item.name}</div>
                    </div>
                    {/* <div>{item.value}</div> */}
                  </div>
                );
              })}
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div
        style={{
          padding: "30px",
          height: "90px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <img
                src={currenciesData?.[bondSelected?.coinsData?.[0]?.coin]?.icon}
                alt=""
                style={{ width: "28px", height: "28px", borderRadius: "100%" }}
              /> */}
              <div >
                <div className="appcode-title" style={{ fontSize: "22px" }}>
                {selectedApp.appName}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {path.length > 1 ? (
                <div
                  className="backButton icon-imgs"
                  onClick={(e) => handleBackStep()}
                >
                  <img src={back} alt="" />
                </div>

              ) : (
                ""
              )}
              <div
                className="backButton icon-imgs"
                onClick={(e) => setShowSubDraw(false)}
                style={{
                  marginLeft: "20px",
                }}
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>

          <div
            className="breadcrumb"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {path?.map((item, index) => {
              return (
                <div>
                  {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                  <span
                    onClick={(e) => handleBreadcrumbClick(item)}
                    className={
                      index === path.length - 1 ? "crumbSelected" : "crumbs"
                    }
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {conditionalSteps()}
      </div>
    </>
  );
};

export default About;
