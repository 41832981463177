import React from "react";

const LendingStats = () => {
  return (
    <>
      <div
        style={{
          width: window.innerWidth - 365,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Coming Soon...
      </div>
    </>
  );
};

export default LendingStats;
