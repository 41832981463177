import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import AvalancheCoins from "./AvalancheCoins";

import AllCoins from "./AllCoins";
import PolygonCoins from "./PolygonCoins";
import MainnetCoins from "./MainnetCoins";
import Tiers from "./Tiers";

const DefiCoins = () => {
  // const tabs = ["Offerings", "Sold Offerings", "Tiers"];
  const tabs = ["CoinsData", "Mainnet", "Polygon", "Avalanche"];
  const {
    setShowSubDraw,
    tabSelected,
    setTabSelected,
    searchTerm,
    setSearchTerm,
  } = useContext(GlobalContex);

  useEffect(() => {
    setTabSelected("CoinsData");
  }, []);

  // const [tabSelected, setTabSelected] = useState("Offerings");

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "CoinsData":
        return <AllCoins />;
      case "Mainnet":
        return <MainnetCoins />;
      case "Polygon":
        return <PolygonCoins />;
      case "Avalanche":
        return <AvalancheCoins />;
      case "Tiers":
        return <Tiers />;
      default:
        return null;
    }
  }, [
    tabSelected,
    // openCoinFilter, refetchApi
  ]);
console.log(searchTerm)
  return (
    <div>
      <input
        type="text"
        id="search-bar"
        placeholder="Search a coin"
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
      />
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false]}
      />
      {tabComponent}

      {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
      {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >
        <Subdrawer />
      </div> */}
    </div>
  );
};

export default DefiCoins;
